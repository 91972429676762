import axios from 'axios';
import Constant from '../Constant';
import {
	GET_BANNERS_LIST,
	GET_TOP_PRODUCT_LIST,
	LOGOUT,
	GET_CATEGORY_LIST,
	GET_PRODUCTS_LIST,
	GET_PRODUCT_DETAILS,
	GET_CART,
	SET_LOADING,
	GET_ADDRESS,
	GET_ORDERLIST,
	GET_WISHLIST,
	GET_PROFILE,
	GET_CONTACT,
	GET_PINCODE_DETAILS,
	GET_SETTINGS,
	GET_CANCELLATION_LIST,
	GET_COUPON_DETAILS,
	GET_REDIRECT,
	GET_CITY_LIST,
	GET_BUSINESS_CATEGORY,
	GET_BUSINESS_TYPE,
	GET_DOC_TYPE,
	GET_BUSINESS_DETAILS,
	GET_MY_CATEGORY_LIST,
} from './types';
import Swal from 'sweetalert2';
import ReactPixel from 'react-facebook-pixel';

var config = {
	headers: {
		Authorization: `Bearer ${localStorage.getItem('btbauth')}`,
	},
};

var paramConfig = {
	Authorization: `Bearer ${localStorage.getItem('btbauth')}`,
};

// SET LOADING
export const setLoading = (data) => (dispatch) => {
	dispatch({
		type: SET_LOADING,
		payload: data,
	});
};

// GET USER LOGIN
export const guestUser = (data) => async (dispatch) => {
	const deviceId = `${Math.floor(1000 + Math.random() * 9000)}4vdsvsd46sdscs`;
	localStorage.setItem('btbdevice-id', deviceId);
	try {
		const res = await axios.post(Constant.getAPI() + '/guest/login', {
			deviceId: deviceId,
			devicePlatform: 'web',
		});
		if (res.data.success) {
			localStorage.setItem('btbauth', res.data.auth);
			localStorage.setItem('btbuserId', res.data.id);

			//   localStorage.setItem('auth',res.data.auth)
			window.location.reload();
		} else {
		}
	} catch (err) {
		console.log(err);
		Swal.fire({
			title: err.error,
			icon: '',
			imageHeight: 100,
			text: '',
			type: 'error',
			confirmButtonColor: '#e84200',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Ok',
		});
	}
};

// LOGIN
export const loginUser = (formData) => async (dispatch) => {
	try {
		const res = await axios.post(Constant.getAPI() + '/users/login', formData);
		if (res.data.status) {
			Swal.fire({
				title: 'Login Successfully',
				icon: '',
				imageUrl: './images/spinner.png',
				imageHeight: 100,
				text: '',
				type: 'success',
				confirmButtonColor: '#e84200',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
			});
			localStorage.setItem('wlo-auth', res.data.data.token);
			localStorage.setItem('wlo-userId', res.data.data.id);
			localStorage.setItem('wlo-firstName', res.data.data.firstName);
			localStorage.setItem('wlo-lastName', res.data.data.lastName);
			localStorage.setItem('wlo-email', res.data.data.email);
			localStorage.setItem('wlo-phone', res.data.data.phone);

			window.location.reload();
		} else {
			Swal.fire({
				title: res.data.error,
				icon: '',
				imageUrl: './images/spinner.png',
				imageHeight: 100,
				text: '',
				type: 'error',
				confirmButtonColor: '#e84200',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
			});
		}
	} catch (err) {
		console.log(err);
		Swal.fire({
			title: err.error,
			icon: '',
			imageUrl: './images/spinner.png',
			imageHeight: 100,
			text: '',
			type: 'error',
			confirmButtonColor: '#e84200',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Ok',
		});
	}
};

// LOGOUT
export const logout = () => (dispatch) => {
	dispatch({
		type: LOGOUT,
		payload: {},
	});
	localStorage.clear();
	window.location.href = '#/';
	window.location.reload();
};

// PRODUCTS LIST
export const getProductsList = (data) => (dispatch) => {
	dispatch(setLoading(true));
	axios
		.post(Constant.getAPI() + '/sellerproduct/list', data, config)
		.then((res) => {
			if (localStorage.getItem('btbauth')) {
				dispatch({
					type: GET_PRODUCTS_LIST,
					payload: res.data.data,
				});
			} else {
				dispatch({
					type: GET_PRODUCTS_LIST,
					payload: [],
				});
			}
			dispatch(setLoading(false));
		})
		.catch((err) => console.log(err));
};

// PRODUCTS LIST
export const getProductDetails = (id, data) => (dispatch) => {
	dispatch(setLoading(true));
	axios
		.post(Constant.getAPI() + '/sellerproduct/list', {}, config)
		.then((res) => {
			if (localStorage.getItem('btbauth')) {
				console.log();
				console.log(id);

				var productList = res.data.data;
				var payload = {};
				for (let i = 0; i < productList.length; i++) {
					if (id === productList[i].ProductId) {
						payload = productList[i];
					}
				}

				dispatch({
					type: GET_PRODUCT_DETAILS,
					payload: payload,
				});
			} else {
				dispatch({
					type: GET_PRODUCT_DETAILS,
					payload: [],
				});
			}

			var facebookData = {
				content_name: res.data.data.Product.name,
				content_category: res.data.data.Category.name,
				currency: 'INR',
			};

			ReactPixel.track('View Product', facebookData);
			ReactPixel.fbq('track', 'View Product', facebookData);
			dispatch(setLoading(false));
		})
		.catch((err) => console.log(err));
};

// ORDER
export const placeOrder = (data) => (dispatch) => {
	dispatch(setLoading(true));
	axios
		.post(Constant.getAPI() + `/order/add`, data, config)
		.then((res) => {
			if (res.data.success) {
				if (data.paymentType === 'cod') {
					Swal.fire({
						title: 'Order Placed',
						icon: '',
						imageUrl: './images/spinner.png',
						imageHeight: 100,
						text: '',
						type: 'success',
						confirmButtonColor: '#e84200',
						cancelButtonColor: '#d33',
						confirmButtonText: 'Ok',
					});

					ReactPixel.track('Purchase', res.data.data);
					ReactPixel.fbq('track', 'Purchase', res.data.data);

					window.location.href = '#/order';
				} else {
					var paytmDetails = res.data.data.paytmDetails;
					dispatch({
						type: GET_REDIRECT,
						payload: paytmDetails,
					});
					dispatch(setLoading(false));
				}

				ReactPixel.track('Initiate Checkout', data);
				ReactPixel.fbq('track', 'Initiate Checkout', data);
			} else {
				Swal.fire({
					title: 'Error',
					icon: '',
					imageUrl: './images/spinner.png',
					imageHeight: 100,
					text: 'Failed',
					type: 'success',
					confirmButtonColor: '#e84200',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Ok',
				});
			}
		})
		.catch((err) => console.log(err));
};

// ORDER LIST
export const orderList = (data) => (dispatch) => {
	dispatch(setLoading(true));
	axios
		.post(Constant.getAPI() + `/user/myorder`, data, config)
		.then((res) => {
			dispatch({
				type: GET_ORDERLIST,
				payload: res.data.data,
			});
			dispatch(setLoading(false));
		})
		.catch((err) => console.log(err));
};

// CANCEL ORDER
export const cancelOrder = (data) => (dispatch) => {
	dispatch(setLoading(true));
	axios
		.post(Constant.getAPI() + `/order/cancel`, data, config)
		.then((res) => {
			Swal.fire({
				title: 'Order cancelled successfully',
				icon: '',
				imageUrl: './images/spinner.png',
				imageHeight: 100,
				type: 'success',
				confirmButtonColor: '#e84200',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
			});

			dispatch(orderList());
			dispatch(setLoading(false));
		})
		.catch((err) => console.log(err));
};

// REORDER
export const reorder = (data) => (dispatch) => {
	dispatch(setLoading(true));
	axios
		.post(Constant.getAPI() + `/order/reorder`, data, config)
		.then((res) => {
			Swal.fire({
				title: 'Product reordered successfully',
				icon: '',
				imageUrl: './images/spinner.png',
				imageHeight: 100,
				type: 'success',
				confirmButtonColor: '#e84200',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
			});
			dispatch(orderList());
			dispatch(setLoading(false));
		})
		.catch((err) => console.log(err));
};

// WISHLIST
export const wishlist = (data) => (dispatch) => {
	var data = {
		pinCode: localStorage.getItem('btbpincode'),
	};
	dispatch(setLoading(true));
	axios
		.post(Constant.getAPI() + '/favorite/list', data, config)
		.then((res) => {
			dispatch({
				type: GET_WISHLIST,
				payload: res.data.data,
			});
			dispatch(setLoading(false));
		})
		.catch((err) => console.log(err));
};

// FAVOURITE
export const favourite = (data, productData) => (dispatch) => {
	if (!localStorage.getItem('btbloggedin')) {
		Swal.fire({
			title: 'Please do sign in/sign up to continue.',
			icon: '',
			imageUrl: './images/spinner.png',
			imageHeight: 100,
			type: 'success',
			confirmButtonColor: '#e84200',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Ok',
		});

		return false;
	}

	axios
		.post(Constant.getAPI() + `/favorite/add`, data, config)
		.then((res) => {
			dispatch({
				type: GET_PRODUCTS_LIST,
				payload: [],
			});

			ReactPixel.track('Add to wishlist', res.data.data);
			ReactPixel.fbq('track', 'Add to wishlist', res.data.data);

			dispatch(getTopProductsList());
			dispatch(getProductsList(productData));
			dispatch(wishlist());
		})
		.catch((err) => console.log(err));
};

// UNFAVOURITE
export const unFavourite = (data, productData) => (dispatch) => {
	axios
		.post(Constant.getAPI() + `/favorite/delete`, data, config)
		.then((res) => {
			dispatch({
				type: GET_PRODUCTS_LIST,
				payload: [],
			});
			dispatch(getTopProductsList());
			dispatch(getProductsList(productData));
			dispatch(wishlist());
		})
		.catch((err) => console.log(err));
};

// USER PROFILE
export const getProfile = (data) => (dispatch) => {
	axios
		.get(Constant.getAPI() + `/user/profile`, config)
		.then((res) => {
			dispatch({
				type: GET_PROFILE,
				payload: res.data.data,
			});
		})
		.catch((err) => console.log(err));
};

// UPDATE USER PROFILE
export const updateProfile = (data) => (dispatch) => {
	dispatch(setLoading(true));
	axios
		.patch(Constant.getAPI() + `/user/update`, data, config)
		.then((res) => {
			if (res.data.success) {
				Swal.fire({
					title: 'Profile Updated',
					icon: '',
					imageUrl: './images/spinner.png',
					imageHeight: 100,
					type: 'success',
					confirmButtonColor: '#e84200',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Ok',
				});

				window.location.reload();
			} else {
				Swal.fire({
					title: res.data.error,
					icon: '',
					imageUrl: './images/spinner.png',
					imageHeight: 100,
					text: '',
					type: 'error',
					confirmButtonColor: '#e84200',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Ok',
				});
			}
		})
		.catch((err) => console.log(err));
};

// USER PROFILE
export const getContact = (data) => (dispatch) => {
	axios
		.post(Constant.getAPI() + `/metadata/view/contactUs`, data, config)
		.then((res) => {
			dispatch({
				type: GET_CONTACT,
				payload: res.data.data,
			});
		})
		.catch((err) => console.log(err));
};

// BANNER LIST
export const getBannerList = (data) => async (dispatch) => {
	const bannerData = await axios.post(Constant.getAPI() + '/homepage/banner/get', { pincode: data });

	if (bannerData.status === 200) {
		dispatch({
			type: GET_BANNERS_LIST,
			payload: bannerData.data.data,
		});
	}
};

// CATEGORY LIST
export const getCategoryList = (data) => async (dispatch) => {
	let catData = await axios.post(Constant.getAPI() + '/category/list', {
		pincode: localStorage.getItem('btbpincode'),
	});

	if (catData.status === 200) {
		dispatch({
			type: GET_CATEGORY_LIST,
			payload: catData.data.data,
		});
	}
};

// CATEGORY LIST
export const myCategoryList = (data) => async (dispatch) => {
	let catData = await axios.post(
		Constant.getAPI() + '/user/myCategory',
		{
			pincode: localStorage.getItem('btbpincode'),
		},
		config
	);

	if (catData.status === 200) {
		dispatch({
			type: GET_MY_CATEGORY_LIST,
			payload: catData.data.data,
		});
	}
};

// ADD TO CART
export const addToCart = (data) => (dispatch) => {
	dispatch(setLoading(true));
	axios
		.post(Constant.getAPI() + `/cart/add`, data, config)
		.then((res) => {
			if (res.data.success) {
				Swal.fire({
					title: 'Added to Cart',
					icon: '',
					imageUrl: './images/spinner.png',
					imageHeight: 100,
					text: '',
					type: 'success',
					confirmButtonColor: '#e84200',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Ok',
				});
			} else {
				Swal.fire({
					title: res.data.error,
					icon: '',
					imageUrl: './images/spinner.png',
					imageHeight: 100,
					text: '',
					type: 'success',
					confirmButtonColor: '#e84200',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Ok',
				});
			}

			ReactPixel.track('Add to cart', data);
			ReactPixel.fbq('track', 'Add to cart', data);

			dispatch(getCart());
		})
		.catch((err) => {
			dispatch(setLoading(false));
			Swal.fire({
				title: err.response.data.error,
				icon: '',
				imageUrl: './images/spinner.png',
				imageHeight: 100,
				text: '',
				type: 'success',
				confirmButtonColor: '#e84200',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
			});
		});
};

// GET CART
export const getCart = (data) => (dispatch) => {
	dispatch(setLoading(true));
	axios
		.get(Constant.getAPI() + `/cart/list`, config)
		.then((res) => {
			localStorage.setItem('btbpaytmModeAvailable', res.data.paytmModeAvailable);

			dispatch({
				type: GET_CART,
				payload: res.data.data,
			});
			dispatch(setLoading(false));
		})
		.catch((err) => console.log(err));
};

// UPDATE CART
export const updateCart = (data) => (dispatch) => {
	axios
		.post(Constant.getAPI() + `/cart/update`, data, config)
		.then((res) => {
			dispatch(getCart());
		})
		.catch((err) => console.log(err));
};

// DECREMENT CART
export const decrementCart = (data) => (dispatch) => {
	axios
		.post(Constant.getAPI() + `/cart/decrement`, data, config)
		.then((res) => {
			dispatch(getCart());
		})
		.catch((err) => console.log(err));
};

// DELETE CART
export const deleteCart = (cartId) => (dispatch) => {
	axios
		.delete(Constant.getAPI() + `/cart/destroy/${cartId}`, config)
		.then((res) => {
			dispatch(getCart());
			Swal.fire({
				title: 'Product Deleted',
				icon: '',
				imageUrl: './images/spinner.png',
				imageHeight: 100,
				text: '',
				type: 'success',
				confirmButtonColor: '#e84200',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
			});
		})
		.catch((err) => console.log(err));
};

// GET ADDRESS
export const getAddress = (data) => (dispatch) => {
	axios
		.get(Constant.getAPI() + `/address/list`, config)
		.then((res) => {
			dispatch({
				type: GET_ADDRESS,
				payload: res.data.data,
			});
			dispatch(setLoading(false));
		})
		.catch((err) => console.log(err));
};

// ADD ADDRESS
export const addAddress = (data) => (dispatch) => {
	axios
		.post(Constant.getAPI() + `/address/add`, data, config)
		.then((res) => {
			console.log(res.data.data);
			dispatch(getAddress());
			Swal.fire({
				title: 'Address Added',
				icon: '',
				imageUrl: './images/spinner.png',
				imageHeight: 100,
				text: '',
				type: 'success',
				confirmButtonColor: '#e84200',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
			});
			window.location.reload();
		})
		.catch((err) => console.log(err));
};

// UPDATE ADDRESS
export const updateAddress = (data) => (dispatch) => {
	axios
		.patch(Constant.getAPI() + `/address/edit`, data, config)
		.then((res) => {
			dispatch(getAddress());
			Swal.fire({
				title: 'Address Updated',
				icon: '',
				imageUrl: './images/spinner.png',
				imageHeight: 100,
				text: '',
				type: 'success',
				confirmButtonColor: '#e84200',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
			});
			window.location.reload();
		})
		.catch((err) => console.log(err));
};

// DELELE ADDRESS
export const deleteAddress = (id, data) => (dispatch) => {
	axios
		.delete(Constant.getAPI() + `/address/delete/${id}`, config)
		.then((res) => {
			Swal.fire({
				title: 'Address Deleted',
				icon: '',
				imageUrl: './images/spinner.png',
				imageHeight: 100,
				text: '',
				type: 'success',
				confirmButtonColor: '#e84200',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
			});
			window.location.reload();
			dispatch(getAddress());
		})
		.catch((err) => console.log(err));
};

// SENT VERIFICATION CODE
export const sendOtp = (data) => (dispatch) => {
	axios
		.post(Constant.getAPI() + `/user/login`, data, config)
		.then((res) => {
			dispatch(getAddress());
		})
		.catch((err) => console.log(err));
};

// VERIFY VERIFICATION CODE
export const verifyOtp = (data) => (dispatch) => {
	axios
		.post(Constant.getAPI() + `/user/verifyOTP`, data, config)
		.then((res) => {
			if (res.data.success) {
				Swal.fire({
					title: 'Otp Verified Successfully',
					icon: '',
					imageUrl: './images/spinner.png',
					imageHeight: 100,
					text: '',
					type: 'success',
					confirmButtonColor: '#e84200',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Ok',
				});
				localStorage.setItem('btbauth', res.data.data.token);
				localStorage.setItem('btbuserId', res.data.data.id);
				localStorage.setItem('btbloggedin', true);
				// localStorage.setItem('btbverified', false);

				ReactPixel.track('Login Complete', res.data.data);
				ReactPixel.fbq('track', 'Login Complete', res.data.data);
				window.location.reload();
			} else {
				Swal.fire({
					title: 'Otp Incorrect',
					icon: '',
					imageUrl: './images/spinner.png',
					imageHeight: 100,
					text: '',
					type: 'error',
					confirmButtonColor: '#e84200',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Ok',
				});
			}
		})
		.catch((err) => {
			Swal.fire({
				title: err.response.data.error,
				icon: '',
				imageUrl: './images/spinner.png',
				imageHeight: 100,
				text: '',
				type: 'error',
				confirmButtonColor: '#e84200',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
			});
		});
};

// SEARCH PINCODE
export const searchPincode = (data) => (dispatch) => {
	axios
		.post(Constant.getAPI() + `/pincode/list`, data, config)
		.then((res) => {
			// console.log(res.data);
			if (res.data.success) {
				if (res.data.data.length > 0) {
					var pincode = res.data.data[0].code;
					var city = res.data.data[0].City.name;
					var id = res.data.data[0].id;

					localStorage.setItem('btbpincode', pincode);
					localStorage.setItem('btbcity', city);
					localStorage.setItem('btbpincodeId', id);

					window.location.reload();
				} else {
					Swal.fire({
						title: 'Delivery Area Unavailable',
						icon: '',
						imageUrl: './images/spinner.png',
						imageHeight: 100,
						text: 'Currently we are not providing service in your entered pincode.',
						type: 'error',
						confirmButtonColor: '#e84200',
						cancelButtonColor: '#d33',
						confirmButtonText: 'Ok',
					});
				}
			} else {
				Swal.fire({
					title: 'Pincode is not valid',
					icon: '',
					imageUrl: './images/spinner.png',
					imageHeight: 100,
					text: '',
					type: 'error',
					confirmButtonColor: '#e84200',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Ok',
				});
			}
		})
		.catch((err) => console.log(err));
};

// SEARCH PINCODE
export const enterPincode = (data) => (dispatch) => {
	axios
		.post(Constant.getAPI() + `/pincode/list`, data, config)
		.then((res) => {
			console.log(res.data);
			if (res.data.success) {
				if (res.data.data.length > 0) {
					dispatch({
						type: GET_PINCODE_DETAILS,
						payload: res.data.data[0],
					});
				} else {
					Swal.fire({
						title: 'Delivery Area Unavailable',
						icon: '',
						imageUrl: './images/spinner.png',
						imageHeight: 100,
						text: 'Currently we are not providing service in your entered pincode.',
						type: 'error',
						confirmButtonColor: '#e84200',
						cancelButtonColor: '#d33',
						confirmButtonText: 'Ok',
					});
				}
			} else {
				dispatch({
					type: GET_PINCODE_DETAILS,
					payload: {
						area: '',
						City: {
							name: '',
							State: {
								name: '',
							},
						},
					},
				});
			}
		})
		.catch((err) => {
			console.log(err);
		});
};

// TOP PRODUCTS LIST
export const getTopProductsList = () => (dispatch) => {
	var data = {
		pincode: localStorage.getItem('btbpincode'),
	};
	dispatch(setLoading(true));
	axios
		.post(Constant.getAPI() + '/homepage/top-product', data, config)
		.then((res) => {
			var products = res.data;

			dispatch({
				type: GET_TOP_PRODUCT_LIST,
				payload: products,
			});

			dispatch(setLoading(false));
		})
		.catch((err) => console.log(err));
};

// GET SETTINGS
export const getSettings = () => (dispatch) => {
	var data = {
		pinCode: localStorage.getItem('btbpincode'),
	};
	dispatch(setLoading(true));
	axios
		.post(Constant.getAPI() + '/pincodeseller/settings', data, config)
		.then((res) => {
			var data = res.data.data;

			dispatch({
				type: GET_SETTINGS,
				payload: data,
			});

			dispatch(setLoading(false));
		})
		.catch((err) => console.log(err));
};

// GET REASONS OF CANCELLATIONS LIST
export const getCancellationList = (data) => (dispatch) => {
	dispatch(setLoading(true));
	axios
		.post(Constant.getAPI() + '/setting/reasonofcancellation/list', data, config)
		.then((res) => {
			var cancellationList = res.data.data;

			dispatch({
				type: GET_CANCELLATION_LIST,
				payload: cancellationList,
			});

			dispatch(setLoading(false));
		})
		.catch((err) => console.log(err));
};

// GET REASONS OF CANCELLATIONS LIST
export const verifyCoupon = (data, grandTotalInCart) => (dispatch) => {
	dispatch(setLoading(true));
	axios
		.post(Constant.getAPI() + '/coupon/verify', data, config)
		.then((res) => {
			console.log(res.data.data);

			if (res.data.success) {
				if (res.data.data.minPurchaseAmount < grandTotalInCart) {
					Swal.fire({
						title: 'Coupon Applied',
						icon: '',
						imageUrl: './images/spinner.png',
						imageHeight: 100,
						text: res.data.name,
						type: 'error',
						confirmButtonColor: '#e84200',
						cancelButtonColor: '#d33',
						confirmButtonText: 'Ok',
					});

					dispatch({
						type: GET_COUPON_DETAILS,
						payload: res.data.data,
					});
				} else {
					Swal.fire({
						title: `Coupon not applicable, Minumim amount should be ${res.data.data.minPurchaseAmount}`,
						icon: '',
						imageUrl: './images/spinner.png',
						imageHeight: 100,
						text: res.data.name,
						type: 'error',
						confirmButtonColor: '#e84200',
						cancelButtonColor: '#d33',
						confirmButtonText: 'Ok',
					});

					dispatch({
						type: GET_COUPON_DETAILS,
						payload: [],
					});
				}
			} else {
				Swal.fire({
					title: res.data.message,
					icon: '',
					imageUrl: './images/spinner.png',
					imageHeight: 100,
					text: '',
					type: 'error',
					confirmButtonColor: '#e84200',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Ok',
				});
				dispatch({
					type: GET_COUPON_DETAILS,
					payload: [],
				});
			}

			dispatch(setLoading(false));
		})
		.catch((err) => {
			Swal.fire({
				title: err.response.data.error,
				icon: '',
				imageUrl: './images/spinner.png',
				imageHeight: 100,
				text: '',
				type: 'error',
				confirmButtonColor: '#e84200',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
			});
			dispatch({
				type: GET_COUPON_DETAILS,
				payload: [],
			});
		});
};

// CANCEL ORDER
export const feedbackOrder = (data) => (dispatch) => {
	dispatch(setLoading(true));
	axios
		.post(Constant.getAPI() + `/order/feedback`, data, config)
		.then((res) => {
			Swal.fire({
				title: 'Thank You, Your feedback sent successfully!',
				icon: '',
				imageUrl: './images/spinner.png',
				imageHeight: 100,
				type: 'success',
				confirmButtonColor: '#e84200',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
			});

			dispatch(setLoading(false));
			setTimeout(() => {
				window.location.reload();
			}, 1000);
		})
		.catch((err) => console.log(err));
};

// GET CITY LIST
export const getCityList = (data) => (dispatch) => {
	dispatch(setLoading(true));
	axios
		.get(Constant.getAPI() + '/city/list', data, config)
		.then((res) => {
			console.log(res.data.data);
			var cityList = res.data.data;

			dispatch({
				type: GET_CITY_LIST,
				payload: cityList,
			});

			dispatch(setLoading(false));
		})
		.catch((err) => console.log(err));
};

// GET BUSINESS TYPE
export const getBusinessType = (data) => (dispatch) => {
	dispatch(setLoading(true));
	axios
		.post(Constant.getAPI() + '/business/type/list', data, config)
		.then((res) => {
			dispatch({
				type: GET_BUSINESS_TYPE,
				payload: res.data.data,
			});

			dispatch(setLoading(false));
		})
		.catch((err) => console.log(err));
};

// GET BUSINESS DETAILS
export const getBusinessDetails = (data) => (dispatch) => {
	dispatch(setLoading(true));
	axios
		.post(Constant.getAPI() + '/business/list', data, config)
		.then((res) => {
			dispatch({
				type: GET_BUSINESS_DETAILS,
				payload: res.data.data,
			});
			dispatch(setLoading(false));
		})
		.catch((err) => console.log(err));
};

// ADD BUSINESS DETAILS
export const addBusinessDetails = (data) => (dispatch) => {
	dispatch(setLoading(true));
	axios
		.post(Constant.getAPI() + '/business/addDetails', data, config)
		.then((res) => {
			console.log(res);

			dispatch(setLoading(false));
		})
		.catch((err) => console.log(err));
};

// DOC TYPE LIST
export const getDocTypeList = (data) => (dispatch) => {
	dispatch(setLoading(true));
	axios
		.post(Constant.getAPI() + '/business/docType/list', data, config)
		.then((res) => {
			dispatch({
				type: GET_DOC_TYPE,
				payload: res.data.data,
			});

			dispatch(setLoading(false));
		})
		.catch((err) => console.log(err));
};

// DOC TYPE LIST
export const uploadDocument = (data) => (dispatch) => {
	dispatch(setLoading(true));
	axios
		.post(Constant.getAPI() + '/business/addDocs', data, config)
		.then((res) => {
			const data = {
				userId: localStorage.getItem('btbuserId'),
			};
			Swal.fire({
				title: 'Document Uploaded',
				icon: '',
				imageUrl: './images/spinner.png',
				imageHeight: 100,
				type: 'success',
				confirmButtonColor: '#e84200',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
			});

			dispatch(getDocTypeList());
			dispatch(getBusinessDetails(data));
			dispatch(setLoading(false));
		})
		.catch((err) => console.log(err));
};
