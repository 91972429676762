import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  addAddress,
  getAddress,
  updateAddress,
  deleteAddress,
} from "../../../actions/homeAction";

// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";
import AddressModal from "../modals/AddressModal";
import LoadingOverlay from "react-loading-overlay";
import Loading from "../common/Loading";

class Address extends Component {
  state = {
    pincode: localStorage.getItem("btbpincode"),
  };
  componentDidMount() {
    this.props.getAddress();
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      address: nextProps.home.address,
      loading: nextProps.home.loading,
    });
  }

  onOpenAddressModal = () => {
    this.setState({ isAddressModalOpen: true });
  };
  onCloseAddressModal = () => {
    this.setState({ isAddressModalOpen: false });
  };

  checkLogin = () => {
    this.clearState();
    if (!localStorage.getItem("btbloggedin")) {
      this.onOpenRegisterModal();
    } else {
      this.setState({
        verified: true,
        addingAddress: true,
        addressDetails: {},
      });
    }

    this.onOpenAddressModal();
  };

  setAddress = (id) => {
    const address = this.state.address;
    console.log(id);
    for (let i = 0; i < address.length; i++) {
      if (id === address[i].id) {
        var addressDetails = {
          addressId: address[i].id,
          mobile: address[i].mobile,
          name: address[i].name,
          road: address[i].road,
          landmark: address[i].landmark,
          PinCodeId: address[i].PinCodeId,
          lat: address[i].lat,
          long: address[i].long,
          label: address[i].label,
          address_line_1: address[i].address_line_1,
          address_line_2: address[i].address_line_2,
          alternate_mobile: address[i].alternate_mobile,
        };
        this.setState({
          addressDetails: addressDetails,
          addingAddress: false,
          isAddressModalOpen: true,
        });
      }
    }
  };

  clearState = () => {
    this.setState({
      mobile: "",
      name: "",
      road: "",
      landmark: "",
      PinCodeId: "",
      lat: "",
      long: "",
      label: "",
      address_line_1: "",
      address_line_2: "",
      alternate_mobile: "",
    });
  };

  onDeleteAddress = (id) => {
    this.props.deleteAddress(id);
  };
  render() {
    return (
      <div>
        <LoadingOverlay
          active={this.state.loading}
          text={<Loading></Loading>}
        ></LoadingOverlay>
        <h5 className="bg-primary text-white p-2">
          <Link to="/">
            <i className="fa fa-home"></i> Home{" "}
          </Link>{" "}
          <i className="fa fa-chevron-right mx-3"></i> Address Book
        </h5>
        <div className="py-5">
          <div className="container">
            <div class="row d-flex justify-content-between">
              <div class="col-md-3 dashboard-left mb-5">
                <div class="checkout-sub">
                  <div className="list-group shadow-sm">
                    <Link
                      to="/account"
                      className="list-group-item product-link"
                    >
                      My Profile
                    </Link>
                    <Link
                      to="/order"
                      className="list-group-item product-link"
                    >
                      My Orders
                    </Link>
                    <Link
                      to="/address"
                      className="list-group-item product-link text-white bg-primary"
                    >
                      Address Book
                    </Link>

                    <Link
                      to="/favourite"
                      className="list-group-item product-link"
                    >
                      Favourites
                    </Link>

                    <Link
                      className="list-group-item product-link cursor-pointer"
                      onClick={() => {
                        localStorage.clear();
                        window.location.href = "#/";
                        window.location.reload();
                      }}
                    >
                      Logout
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-md-8 checkout-right">
                <div>
                  <div className="d-flex justify-content-between align-items-center">
                    <h3 className="m-0">Select Address</h3>

                    <div className="btn btn-primary " onClick={this.checkLogin}>
                      ADD ADDRESS
                    </div>
                  </div>

                  {this.state.address !== undefined &&
                  this.state.address.length > 0 ? (
                    <div>
                      <hr />
                      <div>
                        <Accordion
                          allowMultipleExpanded={!this.state.addingAddress}
                          allowZeroExpanded={!this.state.addingAddress}
                          dangerouslySetExpanding={!this.state.addingAddress}
                        >
                          {this.state.address !== undefined
                            ? this.state.address.map((address) => (
                                <AccordionItem className="shadow-sm border-bottom">
                                  <AccordionItemHeading>
                                    <AccordionItemButton>
                                      {address.label}
                                    </AccordionItemButton>
                                  </AccordionItemHeading>
                                  <AccordionItemPanel>
                                    <div>
                                      <div className="d-flex align-items-center">
                                        <h3 className="float-left m-0">
                                          {address.name}
                                        </h3>{" "}
                                        <div className="badge badge-primary ml-3">
                                          <h5 className="m-0 text-white">
                                            {address.label}
                                          </h5>
                                        </div>
                                      </div>

                                      <br />
                                      <p>
                                        {address.address_line_1},{" "}
                                        {address.address_line_2},{" "}
                                        {address.landmark},{" "}
                                        {address.PinCode.area} -{" "}
                                        {address.PinCode.City.name},{" "}
                                        {address.PinCode.City.State.name} -{" "}
                                        {address.PinCode.code}
                                      </p>
                                      <h5>Mobile No: {address.mobile}</h5>
                                      <br />
                                      <div>
                                        <div class="change-div">
                                          <button
                                            class="btn btn-primary"
                                            onClick={this.setAddress.bind(
                                              this,
                                              address.id
                                            )}
                                          >
                                            EDIT
                                          </button>{" "}
                                          <a
                                            onClick={this.onDeleteAddress.bind(
                                              this,
                                              address.id
                                            )}
                                            class="btn btn-dark"
                                          >
                                            DELETE
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </AccordionItemPanel>
                                </AccordionItem>
                              ))
                            : ""}
                        </Accordion>
                      </div>
                    </div>
                  ) : (
                    <div className="p-5 text-center">No address available</div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <AddressModal
            open={this.state.isAddressModalOpen}
            close={this.onCloseAddressModal}
            addingAddress={this.state.addingAddress}
            addressDetails={this.state.addressDetails}
          ></AddressModal>
        </div>
      </div>
    );
  }
}

var mapStateToProps = (state) => ({
  home: state.home,
});

Address.propsTypes = {
  addAddress: PropTypes.func.isRequired,
  getAddress: PropTypes.func.isRequired,
  updateAddress: PropTypes.func.isRequired,
  deleteAddress: PropTypes.func.isRequired,
  getCart: PropTypes.func.isRequired,
  home: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, {
  addAddress,
  getAddress,
  updateAddress,
  deleteAddress,
})(Address);
