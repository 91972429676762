import React, { Component } from 'react'

export default class ContactUs extends Component {
    render() {
        return (
            <div>
                <iframe src="./staticPage/contactus.html" style={{width:"100%", height:"100vh"}} className="iframe-aboutus"></iframe>
            </div>
        )
    }
}
