import React, { Component } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import { uploadDocument } from '../../../actions/homeAction';
import PropTypes from 'prop-types';
import ImageUpload from './imageUpload/ImageUpload';

class DocumentUploadModal extends Component {
	state = {
		uploading: false,
		uploadDocumentDetails: [],
	};

	onCloseModal = () => {
		this.props.close();
	};
	componentWillReceiveProps(nextProps) {
		if (nextProps.documentDetails) {
			this.setState({ documentDetails: nextProps.documentDetails });
		}
		if (nextProps.uploadedDocuments) {
			this.setState({ details: nextProps.uploadedDocuments.details, note: nextProps.uploadedDocuments.note });
		}
	}
	uploadImage = (data) => {
		console.log(data);
		if (data) {
			var uploadDocumentDetails = this.state.uploadDocumentDetails;
			var exist = false;
			for (let i = 0; i < uploadDocumentDetails.length; i++) {
				if (data.id === uploadDocumentDetails[i].id) {
					exist = true;
					uploadDocumentDetails[i].file = data.file;
				}
			}

			if (exist === false) {
				uploadDocumentDetails.push(data);
			}

			this.setState({ uploadDocumentDetails });
		}
	};
	onUploadDocument = () => {
		this.setState({ uploading: true });
		var uploadDocumentDetails = this.state.uploadDocumentDetails;
		var documentDetails = this.state.documentDetails;
		const formData = new FormData();

		for (let i = 0; i < uploadDocumentDetails.length; i++) {
			if (uploadDocumentDetails[i].id === 'front') {
				if (uploadDocumentDetails[i].file) {
					var data = {
						type: documentDetails.name,
						position: 'front',
						details: this.state.details || '',
					};
					formData.append('files', uploadDocumentDetails[i].file);
					formData.append(`data[${i}]`, JSON.stringify(data));
				}
			} else if (uploadDocumentDetails[i].id === 'back') {
				if (uploadDocumentDetails[i].file) {
					var data = {
						type: documentDetails.name,
						position: 'back',
						details: this.state.details || '',
					};
					formData.append('files', uploadDocumentDetails[i].file);
					formData.append(`data[${i}]`, JSON.stringify(data));
				}
			}
		}

		this.props.uploadDocument(formData);
		this.props.close();
		this.setState({ uploading: false, uploadDocumentDetails: [] });
	};
	render() {
		const { open } = this.props;
		return (
			<div>
				<Modal
					classNames={{
						modal: 'p-0 w-25',
					}}
					open={open}
					onClose={this.onCloseModal}
					center
				>
					<h5 className="box-title border-bottom p-3 text-primary d-flex align-items-center">
						<p className="m-0 text-uppercase">
							{this.props.documentDetails && this.props.documentDetails.name}
						</p>
						{this.props.uploadedDocuments &&
						this.props.documentDetails &&
						this.props.uploadedDocuments.docType === this.props.documentDetails.name ? (
							this.props.uploadedDocuments.status === 'pending' ? (
								<div className="badge badge-info ml-3">In-review</div>
							) : this.props.uploadedDocuments.status === 'rejected' ? (
								<div className="badge badge-danger ml-3">Rejected</div>
							) : this.props.uploadedDocuments.status === 'accepted' ? (
								<div className="badge badge-success text-white ml-3">Approved</div>
							) : (
								''
							)
						) : (
							<div className="badge badge-danger ml-3">Pending</div>
						)}
					</h5>

					<div className="card-body">
						<div className="form-group">
							<input
								type="text"
								className="form-control"
								placeholder="Enter details"
								value={this.state.details}
								onChange={(e) => this.setState({ details: e.target.value })}
							/>
						</div>
						<div className="row">
							{this.props.uploadedDocuments && this.props.uploadedDocuments.media ? (
								this.props.uploadedDocuments.media.map((document, key) => (
									<div className="col">
										<div className="card p-2 shadow-sm border">
											<div className="card-body border-dashed cursor-pointer">
												{document.position === 'front' ? (
													<ImageUpload
														id={'front'}
														image={document.mediaUrl}
														uploadImage={this.uploadImage}
													></ImageUpload>
												) : (
													<ImageUpload
														id={'back'}
														image={document.mediaUrl}
														uploadImage={this.uploadImage}
													></ImageUpload>
												)}
											</div>
										</div>
									</div>
								))
							) : (
								<>
									<div className="col">
										<div className="card p-2 shadow-sm border">
											<div className="card-body border-dashed cursor-pointer">
												<ImageUpload id="front" uploadImage={this.uploadImage}></ImageUpload>
											</div>
										</div>
									</div>
									{this.props.documentDetails && this.props.documentDetails.name === 'aadhar' && (
										<div className="col">
											<div className="card p-2 shadow-sm border">
												<div className="card-body border-dashed cursor-pointer">
													<ImageUpload id="back" uploadImage={this.uploadImage}></ImageUpload>
												</div>
											</div>
										</div>
									)}
								</>
							)}
							<div className="col-md-12 mt-3">
								{this.state.note &&
									(this.props.uploadedDocuments.status === 'accepted' ? (
										<div className="alert alert-success">
											<b>Note</b>: {this.state.note}
										</div>
									) : (
										<div className="alert alert-danger">
											<b>Note</b>: {this.state.note}
										</div>
									))}
							</div>
							<div className="col-md-12 mt-3">
								{this.state.uploadDocumentDetails.length > 0 ? (
									this.state.uploading ? (
										<div className="btn btn-primary btn-block">
											<i className="fa fa-spinner fa-spin"></i> UPLOADING
										</div>
									) : (
										<div className="btn btn-primary btn-block" onClick={this.onUploadDocument}>
											<i className="fa fa-upload"></i> UPLOAD
										</div>
									)
								) : (
									<div className="btn btn-light btn-block">UPLOAD</div>
								)}
							</div>
						</div>
					</div>
				</Modal>
			</div>
		);
	}
}

DocumentUploadModal.propsTypes = {
	verifyUser: PropTypes.func.is,
	// home: PropTypes.object.is
};

var mapStateToProps = (state) => ({
	user: state.user,
	home: state.home,
});

export default connect(mapStateToProps, { uploadDocument })(DocumentUploadModal);
