import React, { Component } from "react";

export default class TermsConditions extends Component {
  render() {
    return (
      <div className="container py-5">
        <div class="header heading">TERMS OF USE</div>
        <div class="bodytext">
          These terms of use are an electronic record in the form of an
          electronic contract formed under Information Technology Act, 2000 and
          rules made thereunder and the amended provisions pertaining to
          electronic documents / records in various statutes. These terms of use
          do not require any physical, electronic or digital signature.
          <br />
          <br />
          These terms of use are published in accordance with the provisions of
          Rule 3 (1) of the Information Technology (Intermediaries Guidelines)
          Rules, 2011 that require publishing the rules and regulations, privacy
          policy and terms of use for access or usage of the Platform by any
          person.
          <br />
          <br />
          The domain name{" "}
          <a href="http://www.fortuneonline.in/" target="_blank">
            www.fortuneonline.in
          </a>{" "}
          on the Platform and mobile application (hereinafter referred to as "
          <b>Platform</b>") is owned by Adani Wilmar Limited, a company
          incorporated under the Companies Act, 2013 with its registered office
          at Fortune House, Near Navrangpura railway crossing, Navrangpura,
          Ahmedabad 380009, Gujarat, India (hereinafter referred to as "
          <b>AWL</b>").
          <br />
          <br />
          Your use of the Platform, services and tools are governed by the
          following terms and conditions ("<b>Terms of Use</b>") as applicable
          to the Platform including the applicable policies which are
          incorporated herein by way of reference. If You transact with any
          third parties registered on the Platform, you shall be subject to the
          policies that are applicable to the Platform for such transactions
          with such third parties. By mere use of the Platform, you shall be
          contracting with AWL and these terms and conditions including the
          policies constitute Your binding obligations, with AWL.
          <br />
          <br />
          For the purpose of these Terms of Use, wherever the context so
          requires "You", "Your", "Yourself" or "User" shall mean any natural or
          legal person who has either registered on the Platform as a registered
          user or who visits the Platform as a guest. The term "We", "Us", "Our"
          shall mean AWL.
          <br />
          <br />
          When You use any of the services provided by Us through the Platform,
          including but not limited to product reviews, feedback, etc., You will
          be subject to the rules, guidelines, policies, terms, and conditions
          applicable to such service, and they shall be deemed to be
          incorporated into these Terms of Use and shall be considered as part
          and parcel of these Terms of Use. We reserve the right, at Our sole
          discretion, to change, modify, alter, add or remove portions of these
          Terms of Use, at any time without any prior written notice to You. It
          is Your responsibility to review these Terms of Use periodically for
          any updates / changes. Your continued use of the Platform following
          the posting of changes will mean that You accept and agree to the
          revisions. As long as, You comply with these Terms of Use, we grant
          You a personal, non-exclusive, non-transferable, revocable, limited
          privilege to enter and use the Platform.
          <br />
          <br />
          ACCESSING, BROWSING OR OTHERWISE USING THE PLATFORM INDICATES YOUR
          AGREEMENT TO ALL THE TERMS AND CONDITIONS UNDER THESE TERMS OF USE, SO
          PLEASE READ THE TERMS OF USE CAREFULLY BEFORE PROCEEDING. By impliedly
          or expressly accepting these Terms of Use, You also accept and agree
          to be bound by AWL Privacy Policy as amended from time to time at{" "}
          <a href="http://www.fortuneonline.in/" target="_blank">
            www.fortuneonline.in
          </a>{" "}
          and subject Yourself to other conditions for accessing the Platform,
          including user agreement terms with registered sellers on our
          Platform.
          <br />
          <br />
          Please note that the availability of, and your ability to access the
          Platform or some part of the Platform, is subject to our sole
          discretion and may be dependent upon your geographical location. On
          account of the nature of the internet, this Platform may also be
          accessed in various geographical locations; and You hereby agree and
          acknowledge that You are accessing this Platform and availing of the
          services, at your own risk, choice and initiative and You agree and
          undertake to ensure that your use of the Platform complies with all
          applicable laws including the local laws in your jurisdiction.
          Further, such services may vary from place to place, time to time and
          device to device and would be subject to various parameters such as
          specifications, device, internet availability and speed, bandwidth,
          etc. You agree and acknowledge that We may monitor or use certain
          technologies and monitoring of activities including logging of Your IP
          address to ascertain and verify your geographical location. Subject to
          compatibility, You may be able to access the Platforms through mobile
          phones, tablets and other IP based connected devices.
          <br />
          <br />
          These terms of use ("Terms of Use") mandate the terms on which the
          users ("You" or "Your" or "Yourself" or "User") access and register on
          the Platform www.fortuneonline.in operated and managed by Adani Wilmar
          Limited) ("AWL"), collectively referred to as, "the Platform". Please
          read the Terms of Use and Privacy Policy carefully before using or
          registering on the Platform or accessing any material, information
          through the Platform.
          <br />
          <br />
          By clicking on the "I accept" button provided below, you accept this
          Terms of Use and Privacy Policy and agree to be legally bound by the
          same. The Platform acts as a mere facilitator between the sellers
          registered on the Platform ("Seller") and buyers purchasing or
          interested in purchasing the products sold under the brand
          name/trademarks of Adani Wilmar Limited ("AWL") available for sale on
          the Platform ("Products").
          <br />
          <br />
          <dl>
            <dt>1. Eligibility to Use Services</dt>
            <dd>
              <ol type="a">
                <li>
                  This Platform are not available to minors i.e., persons under
                  the age of 18 (eighteen) years or to any Users suspended or
                  removed by AWL for any reason whatsoever. You represent that
                  you are of legal age to form a binding contract and are not a
                  person barred from receiving services from the Platform under
                  the applicable laws. AWL reserves the right to refuse access
                  to the Platform to new Users or to terminate access granted to
                  existing Users at any time without according any reasons for
                  doing so. You shall not have more than one active Account (as
                  defined hereinafter) on the Platform. Additionally, you are
                  prohibited from selling, trading, or otherwise transferring
                  Your Account to another party or impersonating any other
                  person for the purposing of creating an account with the
                  Platform.
                </li>
                <li>
                  User Account, Password, and Security In order to purchase the
                  Products on the Platform, You will have to register on the
                  Platform by providing details about Yourself, including Your
                  name, address, contact details and such other details as may
                  be required on the Platform and create an account ("Account").
                  You shall ensure and confirm that the Account information
                  provided by you is complete, accurate and up to date. If there
                  is any change in the Account information, you shall promptly
                  update Your Account information on the Platform. If You
                  provide any information that is untrue, inaccurate, not
                  current or incomplete (or becomes untrue, inaccurate, not
                  current or incomplete), or if AWL has reasonable grounds to
                  suspect that such information is untrue, inaccurate, not
                  current or incomplete, AWL has the right to suspend or
                  terminate Your Account and refuse any and all current or
                  future use of the Platform (or any portion thereof) at its
                  discretion, in addition to any right that AWL may have against
                  You at law or in equity, for any misrepresentation of
                  information provided by You. You will be responsible for
                  maintaining the confidentiality of the Account information and
                  are fully responsible for all activities that occur under Your
                  Account. You agree to,
                  <ul>
                    <li>
                      Immediately notify AWL of any unauthorized use of Your
                      Account information or any other breach of security, and
                    </li>
                    <li>
                      Ensure that you exit from Your Account at the end of each
                      session.
                    </li>
                  </ul>
                </li>
                <li>
                  AWL cannot and will not be liable for any loss or damage
                  arising from your failure to comply with this provision. You
                  may be held liable for losses incurred by AWL or any other
                  user of or visitor to the Platform due to authorized or
                  unauthorized use of Your Account as a result of Your failure
                  in keeping Your Account information secure and confidential.
                  Use of another User’s Account information for shopping on the
                  Platform is expressly prohibited.
                </li>
              </ol>
            </dd>
            <dt>2. Payment Services</dt>
            <dd>
              <ol type="a">
                <li>
                  All payments in respect of the Products shall be made to
                  designated Seller through the Platform. To the extent
                  permitted by applicable law and subject to AWL’s Privacy
                  Policy, you acknowledge and agree that AWL may use certain
                  third-party vendors and service providers, including payment
                  gateways, to process payments and manage payment card
                  information. In order to make payments online, you undertake
                  to use a valid payment card or bank details or third party
                  wallets or details required for any other mode of payment
                  ("Payment Details") with the authority to use the same, to
                  have sufficient funds or credit available to complete the
                  payment on the Platform in good standing. By providing the
                  Payment Details, you represent, warrant, and covenant that:
                  <ul>
                    <li>
                      You are legally authorized to provide such Payment
                      Details;
                    </li>
                    <li>
                      You are legally authorized to perform payments using such
                      Payment Details; and
                    </li>
                    <li>
                      Such action does not violate the terms and conditions
                      applicable to your use of such Payment Details or
                      applicable law.
                    </li>
                  </ul>
                </li>
                <li>
                  You agree that you are responsible for any fees charged by
                  your mobile carrier in connection with your use of the payment
                  services through your mobile. AWL shall use the Payment
                  Details as described in AWL’s Privacy Policy. You may add,
                  delete, and edit the Payment Details you have provided from
                  time to time through the Platform.
                </li>
                <li>
                  The payment receipt for completion of a transaction shall be
                  made available to Buyer on the Platform. Except to the extent
                  otherwise required by applicable law, AWL is not liable for
                  any payments authorized through the Platform using Your
                  Payment Details. Particularly, AWL is not liable for any
                  payments that do not complete because:
                  <ul>
                    <li>
                      Your payment card or bank account or third-party wallet
                      does not contain sufficient funds to complete the
                      transaction or the transaction exceeds the credit limit in
                      respect of the Payment Details provided;
                    </li>
                    <li>
                      You have not provided Seller with correct Payment Details;
                    </li>
                    <li>Your payment card has expired; or</li>
                    <li>
                      Circumstances beyond control (such as, but not limited to,
                      power outages, interruptions of cellular service, or any
                      other interferences from an outside force) prevent the
                      execution of the transaction.
                    </li>
                  </ul>
                </li>
                <li>
                  The Platform shall not be responsible for any unauthorized
                  transactions conducted on our Platform using your payment card
                  or internet banking. The Platform shall not be obligated to
                  refund any money to you in such instances. All information
                  that is collected at the time of payment, like card details,
                  names; passwords etc. are encrypted using Secure Socket Layer
                  (SSL) coding. The terms mentioned in this section are also
                  mentioned under "<b>Billing Terms and Conditions</b>".
                </li>
              </ol>
            </dd>
            <dt>3. Pricing</dt>
            <dd>
              The price of the Products displayed on the Platform shall be
              either equal to or less than the Maximum Retail Price (MRP)
              printed on such Products. If no such Maximum Retail Price (MRP) is
              printed on such Products, then such Products shall be sold at such
              price as may be determined by the Seller. Any order/booking made
              for a Product through the Platform may be subject to additional
              terms and conditions (including offers, discount and sales
              schemes/ campaigns offered from time to time, delivery charges)
              mentioned therein, which you are presumed to have read and
              accepted at the time of placing the order/booking.
            </dd>
            <dt>4. Shipping of Products</dt>
            <dd>
              <ol type="a">
                <li>
                  Orders placed by you on the Platform through Seller, will be
                  delivered as per notification received by buyer post placement
                  of the order. At times when Seller are experiencing high
                  volume of orders or any reasons beyond control of Seller,
                  shipments may get delayed by a few days, in which case, You
                  will be intimated (on the details shared by you at the time of
                  placing Your order/registering on the Platform). Once your
                  order is dispatched by Seller, you will be communicated, with
                  the details of the time and progress of the delivery. On the
                  date of delivery, the person delivering the Product will reach
                  out to you (on the number shared by you at the time of placing
                  your order/registering on the Platform) to confirm your
                  presence to receive the Order. You are requested to be
                  available on the date of delivery since the Products will
                  either be hand delivered to you or to someone appointed by you
                  to collect the order on your behalf. In case of appointment of
                  someone to collect the Order, please select the option for the
                  same while placing your order on the Platform.
                </li>
                <li>
                  All deliveries sent to residential or commercial spaces
                  require a recipient signature for authentication or any
                  automated proof of receipt of goods. We take no responsibility
                  for shipment receipt signed by an alternative person other
                  than the person ordering the Product or someone appointed by
                  you to receive the order at the delivery address. Maximum of 3
                  attempts will be made on the date of delivery to deliver the
                  Product at the address indicated as the delivery point, at the
                  time of placing the order post which the order will be
                  cancelled, and no refund will be given. We do not take
                  responsibility for an incorrect address that was provided at
                  the time of placing the order. In case of an incorrect
                  address, the product will be returned by the rider and no
                  refund will be given to the customer.
                </li>
                <li>
                  All claims for damages must be reported to customer service
                  through an email at:{" "}
                  <a href="mailto:care@adaniwilmar.in" target="_blank">
                    care@adaniwilmar.in
                  </a>{" "}
                  within 24 hours of order delivery. For each order, a shipment
                  charge will have to be paid by the customer if applicable. The
                  exact shipment charges for the order will be shown to the
                  customer at the order checkout page. As soon as the customer
                  receives the product, the customer should store it under the
                  storage conditions mentioned on the back of the pack. No
                  replacement or refunds will be done for any quality loss or
                  damages if the product is not stored in the storage/usage
                  conditions mentioned at the back of the pack.
                </li>
              </ol>
            </dd>
            <dt>5. Cancellation, Returns and Exchange</dt>
            <dd>
              An order placed and confirmed cannot be cancelled you are entitled
              to a 100% replacement of order in case you received something else
              than what you ordered or your order reached you in a distorted
              manner. If purchase of the Product is made under a special
              promotional offer or any special scheme, special rules shall apply
              for replacement. Replacement process must be initiated within 24
              hours from the time of receipt of order by the User. The Product
              must be in an unused state before seeking a for a replacement and
              should be accompanied by a copy of the original receipt or invoice
              issued at the time of sale of the Product. Any request for
              replacement will be accepted only after inspection of the Product.
              Once the replacement request is placed with the customer service,
              the logistics partner will arrange for a pickup of the Product
              from the address provided at the time of placing the order. Once
              the Product is received, it takes about 1 working day for
              inspection team to confirm on the replacement. On confirmation of
              the genuine need for replacement of the Product, we shall replace
              the desired Product with the User in 3 working days. At every step
              of the replacement process, we shall keep you updated through
              emails and/or SMS. However, if you have any queries before, during
              or after the replacement is completed, feel free to contact us at:{" "}
              <a href="mailto:care@adaniwilmar.in" target="_blank">
                care@adaniwilmar.in
              </a>
              . The terms mentioned in this section are also mentioned under "
              <b>Cancellation, Returns and Exchange Policy</b>".
            </dd>
            <dt>6. Use of Platform</dt>
            <dd>
              <ol type="a">
                <li>
                  All commercial/contractual terms are offered by and agreed to
                  between You and the registered sellers alone and detailed in
                  the <b>Seller T&C</b>. The commercial/contractual terms
                  include without limitation price, shipping costs, payment
                  methods, payment terms, date, period and mode of delivery,
                  warranties related to products and after sales services
                  related to the products. AWL does not have any control or does
                  not determine or advise or in any way involve itself in the
                  offering or acceptance of such commercial/contractual terms
                  between You and the registered sellers. All discounts offers
                  (including exchange offers) are by the registered sellers and
                  not by AWL. However, AWL reserves rights to support services
                  to sellers in respect of warehousing, logistics, order
                  fulfilment, call centre, payment collection and other services
                  as requested by Sellers.
                </li>
                <li>
                  Terms with regard to placement of any order of the products
                  with a registered seller, acceptance of such order, terms of
                  delivery etc. shall governed by the Seller T&C. Any enquiry
                  placed by you, or selection of products for an intended
                  purchase with a registered seller on our Website does not
                  constitute a contract between us, and AWL shall in no way be
                  connected with the booking or acceptance of the orders, which
                  shall solely be between You and the registered seller.
                </li>
                <li>
                  All enquiries raised by You on the Website or any product
                  selection by You on the Website along with the information
                  provided by You on the Website will be communicated to the
                  registered sellers. On placing of an enquiry or an order from
                  You will be contacted by the registered seller either by email
                  or by telephone and You can proceed to place Your order with
                  the registered seller on such terms and conditions as agreed
                  between You and the registered Seller. On making production
                  selection on the Website for an intended purchase, You will be
                  directed to the Seller’s payment gateway, where You can
                  transact and complete your order with the registered seller on
                  terms as agreed with the registered seller.
                </li>
                <li>
                  On placing an order selection on the Website and subsequent
                  payment on the Seller’s payment gateway, You will receive
                  communication confirming your order. This will only be an
                  acknowledgement and will not constitute acceptance of your
                  order by the registered seller. A contract between You and the
                  registered seller for the purchase of the goods will not be
                  formed until You receive a shipment confirmation from the
                  registered seller. The registered seller is not obliged to
                  supply the product to You until it has accepted your order.
                  Until the time that your order is so accepted, the registered
                  seller reserves the right to cancel your order and You have
                  the right to cancel your order. Any transaction price paid by
                  You in case of such cancellation by the registered seller,
                  shall be refunded to You by the registered seller. Further,
                  the registered seller may cancel an order wherein the
                  quantities exceed the typical consumption. This applies both
                  to the number of products ordered within a single order and
                  the placing of several orders for the same product where the
                  individual orders comprise a quantity that exceeds the typical
                  consumption. What comprises a typical consumption quantity
                  limit shall be based on various factors and at the sole
                  discretion of the registered seller and may vary from customer
                  to customer.
                </li>
                <li>
                  AWL does not make any representation or warranty as to
                  specifics (such as quality, value, saleability, etc.) of the
                  products or services displayed on the Website and proposed to
                  be sold or offered by the registered seller. AWL does not
                  implicitly or explicitly support or endorse the sale or
                  purchase of any products or services that are displayed on the
                  Website. AWL accepts no liability for any errors or omissions,
                  whether on behalf of itself or third parties.
                </li>
                <li>
                  AWL is not responsible for any non-performance or breach of
                  any contract entered into between You and the registered
                  seller. AWL cannot and does not guarantee that You or the
                  registered seller will perform any transaction concluded
                  between you. AWL shall not and is not required to mediate or
                  resolve any dispute or disagreement between You and a
                  registered seller.
                </li>
                <li>
                  AWL does not make any representation or warranty as to the
                  item-specifics (such as legal title, creditworthiness,
                  identity, etc.) of any of its registered seller. You are
                  advised to independently verify the bona fides of any
                  particular registered seller that You choose to deal with and
                  use Your best judgment in that behalf.
                </li>
                <li>
                  AWL does not at any point of time during any transaction that
                  may ensue between You and a registered seller come into or
                  take possession of any of the products or services offered by
                  the registered seller nor does it at any point gain title to
                  or have any rights or claims over the products or services
                  offered by the registered seller to You.
                </li>
                <li>
                  At no time shall AWL hold any right, title or interest or over
                  the products nor shall AWL have any obligations or liabilities
                  in respect of such contract entered into between You and the
                  registered seller. AWL is not responsible for unsatisfactory
                  or delayed performance of services or damaged products or
                  delivery delays for any reason whatsoever.
                </li>
                <li>
                  The Website is only a promotional venture being undertaken by
                  AWL to market to You the range of products, available with
                  AWL. Any potential sale or transaction with a registered
                  seller by You shall be a strictly bipartite contract between
                  the registered seller and You and shall in no manner concern
                  AWL.
                </li>
                <li>
                  You shall independently agree upon the manner and terms and
                  conditions of delivery, payment, insurance etc. with the
                  registered seller(s) that You transact with.
                </li>
                <li>
                  You agree, understand and acknowledge that the Platform is an
                  online platform that enables you to purchase products listed
                  on the Platform at the price indicated therein at any time
                  from any location. You further agree and acknowledge that AWL
                  is only a facilitator and is not and cannot be a party to or
                  control in any manner any transactions on the Platform.
                  Accordingly, the contract of sale of products on the Platform
                  shall be a strictly bipartite contract between you and the
                  sellers on{" "}
                  <a href="http://www.fortuneonline.in/" target="_blank">
                    www.fortuneonline.in
                  </a>
                </li>
                <li>
                  Subject to compliance with the Terms of Use, AWL grants you a
                  non-exclusive, limited privilege to access and use this
                  Platform. You agree to use the Platform only:
                  <ol type="i">
                    <li>
                      For purposes that are permitted by the Terms of Use; and
                    </li>
                    <li>
                      In accordance with any applicable law, regulation or
                      generally accepted practices or guidelines. You agree not
                      to engage in activities that may adversely affect the use
                      of the Platform by AWL/other Users.
                    </li>
                  </ol>
                </li>
                <li>
                  You agree not to access (or attempt to access) the Platform by
                  any means other than through the interface that is provided by
                  AWL. You shall not use any deep-link, robot, spider or other
                  automatic device, program, algorithm or methodology, or any
                  similar or equivalent manual process, to access, acquire, copy
                  or monitor any portion of the Platform or Content (as defined
                  below), or in any way reproduce or circumvent the navigational
                  structure or presentation of the Platform, materials or any
                  Content, to obtain or attempt to obtain any materials,
                  documents or information through any means not specifically
                  made available through the Platform. You acknowledge and agree
                  that by accessing or using the Platform, You may be exposed to
                  content from others that you may consider offensive, indecent
                  or otherwise objectionable.
                </li>
                <li>
                  AWL disclaims all liabilities arising in relation to such
                  offensive content on the Platform. Further, You undertake not
                  to: defame, abuse, harass, threaten or otherwise violate the
                  legal rights of others; publish, post, upload, distribute or
                  disseminate any inappropriate, profane, defamatory,
                  infringing, obscene, indecent or unlawful topic, name,
                  material or information; copy, republish, post, display,
                  translate, transmit, reproduce or distribute any Content
                  through any medium without obtaining the necessary
                  authorization from AWL; conduct or forward surveys, contests,
                  pyramid schemes or chain letters; upload or distribute files
                  that contain software or other material protected by
                  applicable intellectual property laws unless You own or
                  control the rights thereto or have received all necessary
                  consents; upload or distribute files that contain viruses,
                  corrupted files, or any other similar software or programs
                  that may damage the operation of the Platform or another's
                  computer; engage in any activity that interferes with or
                  disrupts access to the Platform (or the servers and networks
                  which are connected to the Platform); attempt to gain
                  unauthorized access to any portion or feature of the Platform,
                  any other systems or networks connected to the Platform, to
                  any Company server, or through the Platform, by hacking,
                  password mining or any other illegitimate means; probe, scan
                  or test the vulnerability of the Platform or any network
                  connected to the Platform, nor breach the security or
                  authentication measures on the Platform or any network
                  connected to the Platform.
                </li>
                <li>
                  You may not reverse look-up, trace or seek to trace any
                  information on any other User, of or visitor to, the Platform,
                  to its source, or exploit the Platform or information made
                  available or offered by or through the Platform, in any way
                  whether or not the purpose is to reveal any information,
                  including but not limited to personal identification
                  information, other than Your own information, as provided on
                  the Platform; disrupt or interfere with the security of, or
                  otherwise cause harm to, the Platform, systems resources,
                  accounts, passwords, servers or networks connected to or
                  accessible through the Platform or any affiliated or linked
                  sites; collect or store data about other Users in connection
                  with the prohibited conduct and activities set forth in this
                  Section; use any device or software to interfere or attempt to
                  interfere with the proper working of the Platform or any
                  transaction being conducted on the Platform, or with any other
                  person’s use of the Platform; use the Platform or any material
                  or Content for any purpose that is unlawful or prohibited by
                  these Terms of Use, or to solicit the performance of any
                  illegal activity or other activity which infringes the rights
                  of AWL or other third parties; falsify or delete any author
                  attributions, legal or other proper notices or proprietary
                  designations or labels of the origin or source of software or
                  other material contained in a file that is uploaded; violate
                  any applicable laws or regulations for the time being in force
                  within or outside India or anyone’s right to privacy or
                  personality; violate the Terms of Use contained herein or
                  elsewhere; and reverse engineer, modify, copy, distribute,
                  transmit, display, perform, reproduce, publish, license,
                  create derivative works from, transfer, or sell any
                  information or software obtained from the Platform.
                </li>
                <li>
                  Except as expressly indicated herein, AWL hereby grants You a
                  non-exclusive, freely revocable (upon notice from AWL),
                  non-transferable access to view any content available on the
                  Platform, subject to the following conditions: You may access
                  the content solely for personal, informational, and internal
                  purposes, in accordance with the Terms of Use; You may not
                  modify or alter the content available on the Platform; You may
                  not distribute or sell, rent, lease, license or otherwise make
                  any content on the Platform available to others; and You may
                  not remove any text, copyright or other proprietary notices
                  contained in the content downloaded from the Platform.
                </li>
              </ol>
            </dd>
            <dt>7. Intellectual Property Rights</dt>
            <dd>
              The Platform and the processes, and their selection and
              arrangement, including but not limited to, all text, videos,
              graphics, user interfaces, visual interfaces, sounds and music (if
              any), artwork and computer code (and any combinations thereof)
              (collectively, the "Content") on the Platform is owned and
              controlled by AWL and the design, structure, selection,
              coordination, expression, look and feel and arrangement of such
              Content is protected by copyright, patent and trademark laws, and
              various other intellectual property rights. The trademarks, logos
              and service marks displayed on the Platform ("Marks") are the
              property of AWL or Sellers or other respective third parties, as
              the case may be. You are not permitted to use the Marks without
              the prior consent of AWL, or the Sellers, or the third party that
              may own the Marks.
            </dd>
            <dt>8. Disclaimer of Warranties & Liability</dt>
            <dd>
              <ol type="a">
                <li>
                  You expressly understand and agree that, to the maximum extent
                  permitted by applicable law: the Platform, Products and other
                  Content are provided by AWL on an "as is" basis without
                  warranty of any kind, express, implied, statutory or
                  otherwise, including the implied warranties of title,
                  non-infringement, merchantability or fitness for a particular
                  purpose. Without limiting the foregoing, AWL makes no warranty
                  that
                  <ol>
                    <li>
                      The Platform or Products will meet Your requirement, or
                      Your use of the Platform will be uninterrupted, timely,
                      secure or error-free;
                    </li>
                    <li>
                      The results that may be obtained from the use of the
                      Platform will be effective, accurate or reliable;
                    </li>
                    <li>
                      The quality of the Platform and the Products sold therein
                      will meet your expectations; or
                    </li>
                    <li>
                      Any errors or defects in the Platform will be corrected.
                    </li>
                  </ol>
                </li>
                <li>
                  No advice or information, whether oral or written, obtained by
                  you from AWL shall create any warranty not expressly stated in
                  the Terms of Use. AWL will have no liability related to any
                  user content arising under intellectual property rights,
                  libel, privacy, publicity, obscenity or other laws. AWL also
                  disclaims all liability with respect to the misuse, loss,
                  modification or unavailability of any user Content.
                </li>
                <li>
                  AWL will not be liable for any loss that you may incur as a
                  consequence of unauthorized use of Your Account or Account
                  information in connection with the Platform either with or
                  without your knowledge. AWL has endeavored to ensure that all
                  the information on the Platform is correct, but AWL neither
                  warrants nor makes any representations regarding the quality,
                  accuracy or completeness of any data, information regarding
                  the Products or otherwise.
                </li>
                <li>
                  AWL shall not be responsible for the delay or inability to use
                  the Platform or related functionalities, the provision of or
                  failure to provide functionalities, or for any information,
                  software, functionalities and related graphics obtained
                  through the Platform, or otherwise arising out of the use of
                  the Platform, whether based on contract, tort, negligence,
                  strict liability or otherwise. Further, AWL shall not be held
                  responsible for non-availability of the Platform during
                  periodic maintenance operations or any unplanned suspension of
                  access to the Platform that may occur due to technical reasons
                  or for any reason beyond AWL's control.
                </li>
                <li>
                  You understand and agree that any material or data downloaded
                  or otherwise obtained through the Platform is done entirely at
                  Your own discretion and risk, and that You will be solely
                  responsible for any damage to Your computer systems or loss of
                  data that results from the download of such material or data.
                </li>
              </ol>
            </dd>
            <dt>9. Indemnification and Limitation of Liability</dt>
            <dd>
              You agree to indemnify, defend and hold harmless AWL and its
              affiliates including but not limited to its (and its affiliates’)
              officers, directors, consultants, agents and employees
              ("Indemnities") from and against any and all losses, liabilities,
              claims, damages, demands, costs and expenses (including legal fees
              and disbursements in connection therewith and interest chargeable
              thereon) asserted against or incurred by the Indemnities that
              arise out of, result from, or may be payable by virtue of, any
              breach or non-performance of any representation, warranty,
              covenant or agreement made or obligation to be performed by You
              pursuant to these Terms of Use. Further, You agree to hold the
              Indemnities harmless against any claims made by any third party
              due to, or arising out of, or in connection with, Your use of the
              Platform, any misrepresentation with respect to the data or
              information provided by You, Your violation of the Terms of Use,
              or Your violation of any rights of another, including any
              intellectual property rights. In no event shall AWL and its
              officers, partners, consultants, agents and employees, be liable
              to You or any third party for any special, incidental, indirect,
              consequential or punitive damages whatsoever, arising out of or in
              connection with Your use of or access to the Platform or Content
              on the Platform. The limitations and exclusions in this Section
              apply to the maximum extent permitted by applicable laws.
            </dd>
            <dt>10. Violation of the Terms of Use</dt>
            <dd>
              You agree that any violation by You of these Terms of Use will
              constitute an unlawful and unfair business practice, and will
              cause irreparable harm to AWL, as the case may be, for which
              monetary damages would be inadequate, and You consent to AWL
              obtaining any injunctive or equitable relief that they deem
              necessary or appropriate in such circumstances. These remedies are
              in addition to any other remedies that AWL obtaining may have at
              law or in equity. If AWL take any legal action against you as a
              result of your violation of these Terms of Use, they will be
              entitled to recover from you, and you agree to pay, all reasonable
              attorneys’ fees and costs of such action, in addition to any other
              relief that may be granted.
            </dd>
            <dt>11. Suspension and Termination</dt>
            <dd>
              The Terms of Use will continue to apply until terminated by either
              you or AWL as set forth below. If you object to the Terms of Use
              or are dissatisfied with the Platform, your only recourse is to
              <ul>
                <li>Close Your Account on the Platform; and/or</li>
                <li>
                  Stop accessing the Platform. AWL may delist you or block your
                  future access to the Platform or suspend or terminate your
                  Account if it believes, in its sole and absolute discretion
                  that you have infringed, breached, violated, abused, or
                  unethically manipulated or exploited any term of these Terms
                  of Use or anyway otherwise acted unethically.
                </li>
              </ul>
              Notwithstanding anything in this clause, these Terms of Use will
              survive indefinitely unless and until AWL chooses to terminate
              them. If you or AWL terminate your use of the Platform, AWL may
              delete any Content or other materials relating to your and AWL
              shall have no liability to you or any third party for doing so.
              However, your transactions details may be preserved by AWL for
              purposes of tax or regulatory compliance. You shall be liable to
              pay for that you have already ordered till the time of termination
              by either party whatsoever. AWL shall have the right to
              cease/terminate the relationship by giving you a prior twenty-four
              (24) hours’ written notice.
            </dd>
            <dt>12. Governing Law</dt>
            <dd>
              These terms shall be governed by and constructed in accordance
              with the laws of India without reference to conflict of laws
              principles and disputes arising in relation hereto shall be
              subject to the exclusive jurisdiction of courts, tribunals, for a,
              applicable authorities at Ahmedabad.
            </dd>
            <dt>13. Report Abuse</dt>
            <dd>
              In the event you come across any abuse or violation of these Terms
              of Use or if you become aware of any objectionable content on the
              Platform, please report the same to the following e-mail id:{" "}
              <a href="mailto:care@adaniwilmar.in" target="_blank">
                care@adaniwilmar.in
              </a>
            </dd>
            <dt>14. Communications</dt>
            <dd>
              You hereby expressly agree to receive communications by way of SMS
              and/or e-mails from AWL and other third parties. You can
              unsubscribe/ opt-out from receiving communications through e-mail
              anytime by writing to us at visiting{" "}
              <a href="mailto:care@adaniwilmar.in" target="_blank">
                care@adaniwilmar.in
              </a>
              . When You use the Website or send emails or other data,
              information or communication to us, You agree and understand that
              You are communicating with Us through electronic records and You
              consent to receive communications via electronic records from Us
              periodically and as and when required. We may communicate with You
              by email or by such other mode of communication, electronic or
              otherwise.
            </dd>
            <dt>15. General Provisions Notice</dt>
            <dd>
              All notices from AWL will be served by email to your registered
              email address or by general notification on the Platform. Any
              notice provided to AWL pursuant to the Terms of Use should be sent
              to{" "}
              <a href="mailto:care@adaniwilmar.in" target="_blank">
                care@adaniwilmar.in
              </a>{" "}
              with subject line - Attention:
            </dd>
            <dt>16. Assignment</dt>
            <dd>
              You cannot assign or otherwise transfer the Terms of Use, or any
              rights granted hereunder to any third party. AWL’s rights under
              the Terms of Use are freely transferable by AWL to any third party
              without the requirement of seeking your consent. Severability: If,
              for any reason, a court of competent jurisdiction finds any
              provision of the Terms of Use, or any portion thereof, to be
              unenforceable, that provision shall be enforced to the maximum
              extent permissible so as to give effect to the intent of the
              parties as reflected by that provision, and the remainder of the
              Terms of Use shall continue in full force and effect. Waiver: Any
              failure by AWL to enforce or exercise any provision of the Terms
              of Use, or any related right, shall not constitute a waiver by AWL
              of that provision or right.
            </dd>
            <dt>17. IP Infringement</dt>
            <dd>
              If you believe the Platform violates your intellectual property,
              you must promptly notify AWL in writing at{" "}
              <a href="mailto:care@adaniwilmar.in" target="_blank">
                care@adaniwilmar.in
              </a>
              . These notifications should only be submitted by the owner of the
              intellectual property or an agent authorized to act on his/her
              behalf. However, any false claim by you may result in the
              termination of your access to the Platform. You are required to
              provide the following details in Your notice: the intellectual
              property that You believe is being infringed; the item that You
              think is infringing and include sufficient information about where
              the material is located on the Platform; a statement that You
              believe in good faith that the item You have identified as
              infringing is not authorized by the intellectual property owner,
              its agent, or the law to be used in connection with the Platform;
              Your contact details, such as Your address, telephone number,
              and/or email; a statement that the information You provided in
              Your notice is accurate, and that You are the intellectual
              property owner or an agent authorized to act on behalf of the
              intellectual property owner whose intellectual property is being
              infringed; and Your physical or electronic signature.
            </dd>
            <dt>18. Promotional Offers</dt>
            <dd>
              Any dispute(s) in connection with the any ongoing promotional
              offer shall be subject to the exclusive jurisdiction of the courts
              at Ahmedabad, Gujarat. AWL will not be liable in any manner for
              any cost or expense incurred for the purpose of redemption of any
              Gift Card or/and Promotional Offer. AWL reserves the right to
              amend the Terms & Conditions at its discretion without prior
              notice. ‘Fortune’ is an exclusive trademark/brand of AWL. No
              liability or responsibility is assumed by the AWL Limited
              resulting from redemption or attempt to redeem the Promotional
              Offer or ability or inability to upload or download any
              information in connection with the Offer. No responsibility or
              liability is assumed by AWL for technical problems or technical
              malfunction arising in connection with any of the following
              occurrences which may affect the operation of the Promotional
              Offer; Hardware or software errors; faulty computer, telephone,
              cable, satellite, network, electronic or Internet connectivity or
              other online or network communication problems; errors or
              limitations of any Internet providers, servers, hosts or other
              providers; garbled, jumbled or faulty data transmissions; failure
              of any online transmissions to be sent or received; lost, late,
              delayed or intercepted transmissions; inaccessibility of the{" "}
              <a href="http://www.fortuneonline.in/" target="_blank">
                www.fortuneonline.in
              </a>{" "}
              Platform in whole or in part for any reason; traffic congestion on
              the Internet or{" "}
              <a href="http://www.fortuneonline.in/" target="_blank">
                www.fortuneonline.in
              </a>{" "}
              web site; unauthorized human or non-human intervention of the
              operation of the Offer, including without limitation, unauthorized
              tampering, hacking, theft, virus, bugs, or worms; or destruction
              of any aspect of the Offer, or loss, miscount, misdirection,
              inaccessibility or unavailability of an email account used in
              connection with the Offer.
              <br />
              <br />
              Use of{" "}
              <a href="http://www.fortuneonline.in/" target="_blank">
                www.fortuneonline.in
              </a>{" "}
              web site is at consumer’s own risk. AWL is not responsible for any
              personal injury or property damage or losses of any kind which may
              be sustained to users or any other person's computer equipment
              resulting from participation in the Offer. AWL reserves the right
              to extend, cancel, discontinue, withdraw, change, alter or modify
              any promotional Offer or any part thereof or any of the terms
              thereof with or without notification and without assigning any
              reason. If for any reason the Offer is not capable of running as
              planned, including infection by computer virus, bugs, tampering,
              unauthorized intervention, fraud, technical failures, or any other
              causes which corrupt or affect the administration, security,
              fairness, integrity or proper conduct of the Offer, AWL reserves
              the right, at its sole discretion, to disqualify any individual
              who is responsible or who tampers with the process, and to cancel,
              modify or terminate the Offer.
            </dd>
          </dl>
        </div>
      </div>
    );
  }
}
