import {
	GET_TOP_PRODUCT_LIST,
	GET_BANNERS_LIST,
	GET_CATEGORY_LIST,
	GET_MY_CATEGORY_LIST,
	GET_PRODUCTS_LIST,
	GET_PRODUCT_DETAILS,
	GET_CART,
	SET_LOADING,
	GET_ADDRESS,
	GET_ORDERLIST,
	GET_WISHLIST,
	GET_PINCODE_DETAILS,
	GET_SETTINGS,
	GET_CANCELLATION_LIST,
	GET_COUPON_DETAILS,
	GET_REDIRECT,
	GET_PROFILE,
	GET_CITY_LIST,
	GET_BUSINESS_CATEGORY,
	GET_BUSINESS_TYPE,
	GET_DOC_TYPE,
	GET_BUSINESS_DETAILS,
} from '../actions/types';

var initialState = {};

export default function (state = initialState, action) {
	switch (action.type) {
		case SET_LOADING:
			return {
				...state,
				loading: action.payload,
			};
		case GET_BANNERS_LIST:
			return {
				...state,
				bannersList: action.payload,
			};
		case GET_TOP_PRODUCT_LIST:
			return {
				...state,
				topProduct: action.payload,
			};
		case GET_CATEGORY_LIST:
			return {
				...state,
				categoryList: action.payload,
			};
		case GET_MY_CATEGORY_LIST:
			return {
				...state,
				myCategoryList: action.payload,
			};
		case GET_PRODUCTS_LIST:
			return {
				...state,
				productsList: action.payload,
			};
		case GET_PRODUCT_DETAILS:
			return {
				...state,
				productDetails: action.payload,
			};
		case GET_CART:
			return {
				...state,
				cart: action.payload,
			};
		case GET_ADDRESS:
			return {
				...state,
				address: action.payload,
			};
		case GET_ORDERLIST:
			return {
				...state,
				orderList: action.payload,
			};
		case GET_WISHLIST:
			return {
				...state,
				wishlist: action.payload,
			};
		case GET_PINCODE_DETAILS:
			return {
				...state,
				pincodeDetails: action.payload,
			};
		case GET_SETTINGS:
			return {
				...state,
				settings: action.payload,
			};
		case GET_CANCELLATION_LIST:
			return {
				...state,
				cancellationList: action.payload,
			};
		case GET_COUPON_DETAILS:
			return {
				...state,
				couponDetails: action.payload,
			};
		case GET_REDIRECT:
			return {
				...state,
				redirect: action.payload,
			};
		case GET_PROFILE:
			return {
				...state,
				profile: action.payload,
			};
		case GET_CITY_LIST:
			return {
				...state,
				cityList: action.payload,
			};

		case GET_BUSINESS_TYPE:
			return {
				...state,
				businessType: action.payload,
			};
		case GET_DOC_TYPE:
			return {
				...state,
				docType: action.payload,
			};
		case GET_BUSINESS_DETAILS:
			return {
				...state,
				businessDetails: action.payload,
			};

		default:
			return state;
	}
}
