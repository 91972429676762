import React, { Component } from "react";

export default class SellerTermConditions extends Component {
  render() {
    return (
      <div className="container py-5">
        <div class="header heading heading">SELLER TERMS AND CONDITIONS</div>
        <div class="bodytext">
          Conditions of Sale (between Sellers and the Buyer)
          <br />
          <br />
          <br />
          Please read these conditions carefully before placing an order for any
          products with the Sellers ("We" or "Our" or "Us", wherever applicable)
          on the{" "}
          <a href="http://www.fortuneonline.in/" target="_blank">
            www.fortuneonline.in
          </a>{" "}
          (the Platform). For the purpose of these Terms of Use, wherever the
          context so requires "You", “Your”, “Yourself” or "User" shall mean any
          natural or legal person (Buyer) who has either registered on the
          Platform as a registered user or who visits the Platform as a guest.
          These conditions signify your agreement to be bound by these
          conditions.
          <br />
          <br />
          <dl>
            <dt>1. Conditions Relating to the Sale of Products to You</dt>
            <dd>
              This section deals with conditions relating to the sale of
              products on the Platform by us to you.
            </dd>
            <dt>2. Our Contract</dt>
            <dd>
              Your order is an offer to us to buy the product(s) in your order.
              When you place an order to purchase a product from us, you will
              receive a communication of your order and details of your order.
              The Order Confirmation is acknowledgement that we have received
              your order and does not confirm acceptance of your offer to buy
              the product(s) ordered. We only accept your offer, and conclude
              the contract of sale for a product ordered by you, when the
              product is dispatched to you and an confirmation is sent to you
              that the product has been dispatched to you. If your order is
              dispatched in more than one package, you may receive a separate
              Confirmation for each package, and each Dispatch Confirmation and
              corresponding dispatch will conclude a separate contract of sale
              between you and us for the product(s) specified in that Dispatch.
              <br />
              <br />
              You can cancel your order for a product at no cost any time before
              we send the Dispatch Confirmation relating to that product.
              <br />
              <br />
              Price prevailing on the date of placement of orders on the
              web-site shall be applicable for generation of purchase order/
              delivery order/ contract/ advance payment for the supplies that
              have been accepted/ issued. The Seller reserves the right to
              revise the price on the web-site without any advance, further
              notice to the Buyer or without assigning any reason therefore. The
              prices listed for all the products are final and all-inclusive
              charges, other than with respect to delivery charges. Delivery
              charges may be added on orders below a particular amount.
              <br />
              <br />
              Please note that we sell products only in quantities which
              correspond to the typical needs of an average household. This
              applies both to the number of products ordered within a single
              order and the placing of several orders for the same product where
              the individual orders comprise a quantity typical for a normal
              household.
            </dd>
            <dt>3. Returns</dt>
            <dd>
              Please review our Cancellation, Return, Exchange Policy, which
              applies to products sold by us.
            </dd>
            <dt>4. Pricing and availability</dt>
            <dd>
              We list availability information for products sold by us on the
              Platform, including on each product information page. Beyond what
              we say on that page or otherwise on the Platform, we cannot be
              more specific about availability. Please note that dispatch
              estimates are just that. They are not guaranteed dispatch times
              and should not be relied upon as such. As we process your order,
              you will be informed by if any products you order turn out to be
              unavailable.
              <br />
              <br />
              All prices are inclusive of VAT/CST, service tax, Goods and
              Services Tax ("GST"), duties and cesses as applicable - unless
              stated otherwise.
            </dd>
            <dt>5. Delivery</dt>
            <dd>
              The delivery of the goods shall be made by the Seller at the
              address as notified by the Buyer. In event of the change of
              address, the Buyer shall intimate the same to the Seller. The
              Buyer shall make all arrangements to take delivery of the goods on
              the dates informed by the Seller.
            </dd>
            <dt>6. Payment</dt>
            <dd>
              Payments on the web-site can be made through our registered
              payment gateway. In no event any cash payment is to be made
              against this invoice. In case any cash payment is to be made
              against this Invoice by the Buyer or any person/party on its
              behalf to the Company or any of its employee/person the same will
              not be treated as valid payment in discharge of this Invoice and
              the Buyer / person would be doing so at its own risk and
              consequence. The Buyer shall not for any reason suspend payment or
              make any deduction by the way of set-off. In case any amount is
              withheld or sought to be set-off/adjusted unless specifically
              agreed in writing by Authorized Officer of the Company such
              withholding/ set-off will not be accepted as valid discharge of
              the payment.
            </dd>
            <dt>7. Warranty of Goods</dt>
            <dd>
              Seller hereby agrees that all products are guaranteed to be up to
              standard. Any warranties of condition, statutory or otherwise, as
              to quality of fitness for any purpose is excluded. No claims will
              be recognized unless notified in writing within 48 hours after
              receipt of goods. The Seller reserves the right to deal with such
              goods in the best possible manner. The decision of the Seller will
              be final.
            </dd>
            <dt>8. Taxes</dt>
            <dd>
              You shall be responsible for payment of all fees/costs/charges
              associated with the purchase of products from us and you agree to
              bear any and all applicable taxes including but not limited to
              VAT/CST, service tax, GST, duties and cesses etc.
            </dd>
            <dt>9. Communications</dt>
            <dd>
              When you visit{" "}
              <a href="http://www.fortuneonline.in/" target="_blank">
                www.fortuneonline.in
              </a>
              , you are communicating with us electronically. You will be
              required to provide a valid phone number while placing an order
              with us. We may communicate with you by e-mail, SMS, phone call or
              by posting notices on the Platform or by any other mode of
              communication. For contractual purposes, you consent to receive
              communications including SMS, e-mails or phone calls from us with
              respect to your order.
            </dd>
            <dt>10. Losses</dt>
            <dd>
              We will not be responsible for any business loss (including loss
              of profits, revenue, contracts, anticipated savings, data,
              goodwill or wasted expenditure) or any other indirect or
              consequential loss that is not reasonably foreseeable to both you
              and us when a contract for the sale of goods by us to you was
              formed.
            </dd>
            <dt>11. Alteration or Amendments to the Conditions</dt>
            <dd>
              We reserve the right to make changes to our policies, and these
              Conditions of Sale at any time. You will be subject to the
              policies and Conditions of Sale in force at the time you order
              goods from us, unless any change to those policies or these
              conditions is required to be made by law or government authority
              (in which case it will apply to orders previously placed by you).
              If any of these conditions is deemed invalid, void, or for any
              reason unenforceable, that condition will be deemed severable and
              will not affect the validity and enforceability of any remaining
              condition.
            </dd>
            <dt>12. Events beyond our reasonable control</dt>
            <dd>
              We will not be held responsible for any delay or failure to comply
              with our obligations under these conditions if the delay or
              failure arises from any cause which is beyond our reasonable
              control. This condition does not affect your statutory rights.
            </dd>
            <dt>13. Waiver</dt>
            <dd>
              If you breach these conditions and we take no action, we will
              still be entitled to use our rights and remedies in any other
              situation where you breach these conditions.
            </dd>
            <dt>14. Governing Law and Jurisdiction</dt>
            <dd>
              Indian Law shall govern the Contract. Any dispute arising out of
              or in connection with the Contract shall be subject to the
              non-exclusive jurisdiction of the Indian Courts.
            </dd>
          </dl>
        </div>
      </div>
    );
  }
}
