import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import LocationModal from '../modals/LocationModal';
import OtpModal from '../modals/OtpModal';
import RegisterModal from '../modals/RegisterModal';
import SidebarCart from './SidebarCart';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { guestUser } from '../../../actions/homeAction';
import { getCart, searchPincode, getBusinessDetails } from '../../../actions/homeAction';
import SidebarLocation from './SidebarLocation';

class Header extends Component {
	state = {
		isLocationModalOpen: false,
	};
	componentDidMount() {
		const data = {
			userId: localStorage.getItem('btbuserId'),
		};
		if (localStorage.getItem('btbloggedin')) {
			this.props.getBusinessDetails(data);
		}

		window.addEventListener('resize', this.resize.bind(this));
		this.resize();

		this.setLocation();
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.home.businessDetails && nextProps.home.businessDetails.length > 0) {
			this.setState({
				businessDetails: nextProps.home.businessDetails[0],
			});

			if (nextProps.home.businessDetails[0].kycVerified === true) {
				localStorage.setItem('btb-kyc', true);
			} else {
				localStorage.setItem('btb-kyc', false);
			}
		}

		this.setState({ cart: nextProps.home.cart });
	}

	goToLogin = () => {
		window.location.href = '#/admin';
		window.location.reload();
	};
	goToSolution = () => {
		if (document.getElementById('myelementid')) {
			$('html, body').animate(
				{
					scrollTop: $('#myelementid').offset().top,
				},
				500
			);
		}
	};
	onOpenLocationModal = () => {
		this.setState({ isLocationModalOpen: true });
	};
	onCloseLocationModal = () => {
		this.setState({ isLocationModalOpen: false });
	};
	onOpenOtpModal = () => {
		this.setState({ isOtpModalOpen: true });
	};
	onCloseOtpModal = () => {
		this.setState({ isOtpModalOpen: false });
	};
	onOpenRegisterModal = () => {
		this.setState({ isRegisterModalOpen: true });
	};
	onCloseRegisterModal = () => {
		this.setState({ isRegisterModalOpen: false });
	};
	onOpenCart = () => {
		this.setState({ isCartOpen: true });
	};
	onCloseCart = () => {
		this.setState({ isCartOpen: false });
	};
	onOpenLocation = () => {
		this.setState({ isLocationOpen: true });
	};
	onCloseLocation = () => {
		this.setState({ isLocationOpen: false });
	};

	resize() {
		this.setState({ hideNav: window.innerWidth <= 760 });
	}
	componentWillUnmount() {
		window.removeEventListener('resize', this.resize.bind(this));
	}
	guestuserlogin = () => {
		console.log(navigator.platform);
		this.props.guestUser(navigator.platform);
	};
	setLocation = () => {
		if (!localStorage.getItem('btbpincode')) {
			var data = {
				code: 380009,
			};

			this.props.searchPincode(data);
		}
	};
	onSearchInput = (e) => {
		this.setState({ search: e.target.value });
	};
	onSearch = (e) => {
		e.preventDefault();
		window.location.href = `#/product-list/${this.state.search}`;
		// window.location.reload();
	};
	render() {
		return (
			<div className="site-header">
				<div class="header1 position-relative">
					<div>
						<nav className="navbar navbar-expand-lg h1-nav navbar-light border-top">
							<div className="container">
								<Link class="navbar-brand d-flex align-items-center flex-column" to="/">
									<img className="site-logo" src="./images/logo.png" alt="" />
								</Link>

								{this.state.hideNav ? (
									<div className="d-flex">
										<div className="ml-4">
											<i className="fa fa-phone"></i>
										</div>

										<div className="ml-4">
											<a
												className="nav-item cursor-pointer m-0"
												onClick={this.onOpenLocationModal}
											>
												<i className="fa fa-map-marker"></i>
											</a>
										</div>

										<div className="ml-4 text-primary" onClick={this.onOpenCart}>
											<i className="fa fa-shopping-cart"></i>
											<div className="badge badge-success text-white p-1 ml-1">
												{this.state.cart !== undefined ? this.state.cart.length : '0'}{' '}
											</div>{' '}
										</div>
									</div>
								) : (
									''
								)}
								<button
									class="navbar-toggler"
									type="button"
									data-toggle="collapse"
									data-target="#header1"
									aria-expanded="false"
									aria-label="Toggle navigation"
								>
									<span class="navbar-toggler-icon text-primary"></span>
								</button>
								<div class="collapse navbar-collapse" id="header1">
									{this.state.hideNav ? (
										<ul class="navbar-nav ml-auto mt-2 mt-lg-0">
											{/* <CategoryPopover></CategoryPopover> */}
											<li class="nav-item">
												<Link to="/product-list" class="nav-link">
													Offers
												</Link>
											</li>

											<li class="nav-item">
												<Link to="/account" className="nav-link cursor-pointer">
													My Account
												</Link>
											</li>

											<li class="nav-item">
												<Link
													to="/product-list"
													class="nav-link"
													onClick={this.onOpenRegisterModal}
												>
													Login
												</Link>
											</li>
										</ul>
									) : (
										<>
											<ul class="navbar-nav mr-auto mt-2 mt-lg-0">
												<li
													class="nav-item d-flex align-items-center ml-4"
													onClick={this.onOpenLocation}
												>
													<i className="fa fa-map-marker mr-2"></i>{' '}
													{localStorage.getItem('btbcity')} -{' '}
													{localStorage.getItem('btbpincode')}
												</li>
											</ul>
											<ul class="navbar-nav ml-auto mt-2 mt-lg-0">
												<li class="nav-item d-flex align-items-center ml-4">
													<Link
														to="/"
														className="nav-link text-primary text-uppercase font-weigth-bold"
													>
														HOME
													</Link>
												</li>
												<li class="nav-item d-flex align-items-center ml-4">
													<Link
														to="/offers"
														className="nav-link text-primary text-uppercase font-weigth-bold"
													>
														OFFERS
													</Link>
												</li>

												<li class="nav-item d-flex align-items-center ml-4">
													<div>
														{localStorage.getItem('btbloggedin') ? (
															<Link
																to="/account"
																className="nav-item cursor-pointer mx-1"
															>
																MY ACCOUNT
															</Link>
														) : (
															<a
																className="nav-item cursor-pointer mx-1"
																onClick={this.onOpenRegisterModal}
															>
																LOGIN
															</a>
														)}
													</div>
												</li>
												<li
													class="nav-item d-flex align-items-center ml-4"
													onClick={this.onOpenCart}
												>
													<a className="nav-link text-primary text-uppercase font-weigth-bold">
														CART
													</a>
												</li>
											</ul>
										</>
									)}
								</div>
							</div>
						</nav>
					</div>

					<SidebarCart open={this.state.isCartOpen} onClose={this.onCloseCart}></SidebarCart>
					<SidebarLocation open={this.state.isLocationOpen} onClose={this.onCloseLocation}></SidebarLocation>
				</div>
				<LocationModal open={this.state.isLocationModalOpen} close={this.onCloseLocationModal}></LocationModal>
				<OtpModal open={this.state.isOtpModalOpen} close={this.onCloseOtpModal}></OtpModal>
				<RegisterModal open={this.state.isRegisterModalOpen} close={this.onCloseRegisterModal}></RegisterModal>
			</div>
		);
	}
}

Header.propsTypes = {
	verifyUser: PropTypes.func.isRequired,
	// home: PropTypes.object.isRequired,
};

var mapStateToProps = (state) => ({
	user: state.user,
	home: state.home,
});

export default connect(mapStateToProps, { guestUser, getCart, searchPincode, getBusinessDetails })(Header);
