import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getCategoryList, myCategoryList } from '../../../actions/homeAction';
import BTBCart from './BTBCart';
import BTBCategory from './BTBCategory';
import BTBProducts from './BTBProducts';

class BTBOrdering extends Component {
	state = { selectedCategory: {}, type: 'CAR' };
	onSelectCategory = (category) => {
		this.setState({ selectedCategory: category });

		this.props.getCategoryList()
	};
	onSelectType = (type) => {
		this.setState({ type: type });
	};
	render() {
		return (
			<div className="bg-light btb-wrapper">
				<div className="row m-0">
					<div className="col-md-2">
						<BTBCategory onSelectCategory={this.onSelectCategory}></BTBCategory>
					</div>
					<div className="col-md-5">
						<div className="px-2">
							<BTBProducts
								onSelectType={this.onSelectType}
								category={this.state.selectedCategory}
							></BTBProducts>
						</div>
					</div>
					<div className="col-md-5">
						<div className="px-2">
							<BTBCart onSelectType={this.onSelectType} type={this.state.type}></BTBCart>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

var mapStateToProps = (state) => ({
	home: state.home,
});

BTBOrdering.propsTypes = {
	getCategoryList: PropTypes.func.isRequired,
	home: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, {
	getCategoryList,
	myCategoryList,
})(BTBOrdering);
