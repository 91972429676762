import React, { Component } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
	addToCart,
	favourite,
	unFavourite,
	getTopProductsList,
	getCart,
	updateCart,
	deleteCart,
	getSettings,
} from '../../../actions/homeAction';

import Swal from 'sweetalert2';
import LoadingOverlay from 'react-loading-overlay';
import Loading from '../common/Loading';

class NewArrivals extends Component {
	state = {
		quantity: 1,
	};

	componentDidMount() {
		this.props.getCart();
		this.props.getTopProductsList();
	}
	componentWillReceiveProps(nextProps) {
		const topProducts = nextProps.product.topProducts;
		if (topProducts) {
			if (topProducts.new_arrival) {
				const new_arrival = topProducts.new_arrival;

				for (let i = 0; i < new_arrival.length; i++) {
					new_arrival[i].qty = 1;
				}
			}
		}

		this.setState({ topProducts, loading: nextProps.home.loading, cart: nextProps.home.cart });
	}

	onOpenRegisterModal = () => {
		if (localStorage.getItem('auth')) {
			this.setState({ isRegisterModalOpen: true });
		}
	};
	onCloseRegisterModal = () => {
		this.setState({ isRegisterModalOpen: false });
	};
	addQuantity = (id) => {
		const topProducts = this.state.topProducts;
		const new_arrival = topProducts.new_arrival;
		for (let i = 0; i < new_arrival.length; i++) {
			if (id === new_arrival[i].ProductId) {
				if (new_arrival[i].qty < 10) {
					if (new_arrival[i].inventory_stock > parseInt(new_arrival[i].qty)) {
						if (new_arrival[i].qty) {
							new_arrival[i].qty = new_arrival[i].qty + 1;
						} else {
							new_arrival[i].qty = 1;
						}
					} else {
						Swal.fire({
							title: `Only ${new_arrival[i].inventory_stock} quantity available in stock!`,
							icon: '',
							imageUrl: './images/spinner.png',
							imageHeight: 100,
							text: '',
							type: 'error',
							confirmButtonColor: '#e84200',
							cancelButtonColor: '#d33',
							confirmButtonText: 'Ok',
						});
					}
				} else {
					Swal.fire({
						title: `You can only order this product with max qty 10`,
						icon: '',
						imageUrl: './images/spinner.png',
						imageHeight: 100,
						text: '',
						type: 'error',
						confirmButtonColor: '#e84200',
						cancelButtonColor: '#d33',
						confirmButtonText: 'Ok',
					});
				}
			}
		}

		this.setState({ topProducts });
	};
	lessQuantity = (id) => {
		const topProducts = this.state.topProducts;
		const new_arrival = topProducts.new_arrival;
		for (let i = 0; i < new_arrival.length; i++) {
			if (id === new_arrival[i].ProductId) {
				if (new_arrival[i].qty > 1) {
					if (new_arrival[i].qty) {
						new_arrival[i].qty = new_arrival[i].qty - 1;
					} else {
						new_arrival[i].qty = 1;
					}
				}
			}
		}

		this.setState({ topProducts });
	};
	addToCart = (product) => {
		var data = {
			userId: localStorage.getItem('btbuserId'),
			pinCodeId: product.PinCode.id,
			productId: product.Product.id,
			sellerId: product.Seller.id,
			qty: product.qty,
			price_when_added: product.SP_customer,
			after_offer_price: product.SP_customer,
		};
		this.props.addToCart(data);
	};

	onFavourite = (id) => {
		var data = {
			productId: id,
		};
		this.props.favourite(data);
	};

	onUnFavourite = (id) => {
		var data = {
			productId: id,
		};
		this.props.unFavourite(data);
	};

	next = () => {
		this.slider.slickNext();
	};
	previous = () => {
		this.slider.slickPrev();
	};
	render() {
		const responsive = {
			superLargeDesktop: {
				// the naming can be any, depends on you.
				breakpoint: { max: 4000, min: 3000 },
				items: 5,
			},
			desktop: {
				breakpoint: { max: 3000, min: 1024 },
				items: 5,
			},
			tablet: {
				breakpoint: { max: 1024, min: 464 },
				items: 2,
			},
			mobile: {
				breakpoint: { max: 464, min: 0 },
				items: 1,
			},
		};
		return (
			<div>
				<LoadingOverlay active={this.state.loading} text={<Loading></Loading>}></LoadingOverlay>

				<div className="py-5 bg-light new-arrivals">
					<div className="container">
						<div className="d-flex justify-content-between px-3">
							<h2 className="text-uppercase pb-5">New Arrival Products</h2>
							<Link to="/product-list" className="text-dark ml-3 mt-2 font-weight-bold">
								View all <i className="fa fa-angle-double-right"></i>
							</Link>
						</div>

						<div className="row slider-row">
							<div className="col-12 mx-auto">
								<Carousel responsive={responsive}>
									{this.state.topProducts !== undefined && this.state.topProducts.new_arrival
										? this.state.topProducts.new_arrival.map((product) => (
												<div class="px-2 product-link my-1">
													<div className="product-card card border h-100 shadow-sm d-flex flex-column">
														<Link to={`/product-details/${product.Product.id}`}>
															<img
																className="mx-5"
																src={`http://s3-bucket-asia-2020.s3.amazonaws.com/productimages/${product.Product.sku}/${product.Product.sku}.main.png`}
																alt={product.Product.name}
																style={{ width: '50%' }}
																onError={(ev) => (ev.target.src = './images/logo1.png')}
															/>
															<div className="mt-2">
																<h5 className="product-title">
																	{product.Product.name}
																</h5>
																<div className="row m-0">
																	<div className="col-md-12">
																		<p className="m-0 text-left display-flex">
																			<h5 className="text-dark float-left font-weight-bold">
																				&#8377; {product.SP_customer.toFixed(2)}
																			</h5>
																			<h5 className="">
																				<strike class="text-secondary  ml-1">
																					&#8377; {product.MRP.toFixed(2)}
																				</strike>
																			</h5>

																			<span className="text-left text-primary text-success">
																				Save &#8377;
																				{product.Product_flat_discount.toFixed(
																					2
																				)}
																			</span>
																		</p>
																	</div>
																</div>
															</div>
														</Link>
														<div className="d-flex justify-content-between mt-3">
															<div class="form-group m-0">
																<div class="input-group mb-0">
																	<div class="input-group-prepend">
																		<span
																			class="input-group-text cursor-pointer"
																			onClick={this.lessQuantity.bind(
																				this,
																				product.ProductId
																			)}
																		>
																			-
																		</span>
																	</div>
																	<input
																		class="form-control quantity-text"
																		value={product.qty}
																	/>
																	<div class="input-group-append">
																		<span
																			class="input-group-text cursor-pointer"
																			onClick={this.addQuantity.bind(
																				this,
																				product.ProductId
																			)}
																		>
																			+
																		</span>
																	</div>
																</div>
															</div>
															<button
																onClick={this.addToCart.bind(this, product)}
																className="btn btn-outline-primary text-uppercase ml-2"
															>
																Add
															</button>
														</div>
														<div className="fav-icon">
															{product.Product.isFavorite ? (
																<i
																	className="fa fa-heart text-danger"
																	onClick={this.onUnFavourite.bind(
																		this,
																		product.Product.id
																	)}
																></i>
															) : (
																<i
																	className="fa fa-heart-o text-danger"
																	onClick={this.onFavourite.bind(
																		this,
																		product.Product.id
																	)}
																></i>
															)}
														</div>
														<div className="discount-icon font-weight-bold">
															<i className="fa fa-certificate text-primary"></i>
															<p className="text-white">
																{Math.round(product.Product_percentage_discount)}%
															</p>
														</div>
													</div>
												</div>
										  ))
										: ''}
								</Carousel>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

NewArrivals.propsTypes = {
	loginUser: PropTypes.func.isRequired,
	guestUser: PropTypes.func.isRequired,
	home: PropTypes.object.isRequired,
};

var mapStateToProps = (state) => ({
	home: state.home,
	product: state.product,
	guest: state.guest,
});

export default connect(mapStateToProps, {
	getTopProductsList,
	addToCart,
	favourite,
	unFavourite,
	getCart,
	updateCart,
	deleteCart,
	getSettings,
})(NewArrivals);
