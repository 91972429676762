import React, { Component } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { feedbackOrder } from "../../../actions/homeAction";
import Swal from "sweetalert2";
import ReactStars from "react-rating-stars-component";

class FeedbackModal extends Component {
  state = {
    open: false,
    isOtpModalOpen: false,
  };

  onCloseModal = () => {
    this.setState({ open: false });
    this.props.close();
  };
  onInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onFeedbackOrder = () => {
    var data = {
      orderId: this.props.feedbackOrderId,
      comment: this.state.comment,
      reviewDeliveryOverallService: this.state.reviewDeliveryOverallService,
      reviewProductHandellingByDeliveryAgent: this.state
        .reviewProductHandellingByDeliveryAgent,
      reviewDeliveryTimings: this.state.reviewDeliveryTimings,
      reviewDeliveryAgent: this.state.reviewDeliveryAgent,
    };

    this.props.feedbackOrder(data);
    this.onCloseModal();
  };

  onRatingChange = (name, value) => {
    if (name === "reviewDeliveryOverallService") {
      this.setState({ reviewDeliveryOverallService: value });
    }
    if (name === "reviewProductHandellingByDeliveryAgent") {
      this.setState({ reviewProductHandellingByDeliveryAgent: value });
    }
    if (name === "reviewDeliveryTimings") {
      this.setState({ reviewDeliveryTimings: value });
    }
    if (name === "reviewDeliveryAgent") {
      this.setState({ reviewDeliveryAgent: value });
    }
  };

  render() {
    const { open } = this.props;
    return (
      <div>
        <Modal
          classNames={{
            modal: "p-0",
          }}
          open={open}
          onClose={this.onCloseModal}
          center
        >
          <div className="row m-0">
            <div className="col-md-12 ">
              <h4 className="box-title border-bottom p-3 text-primary text-uppercase">
                Order Feedback
              </h4>
              {this.props.feedbackGiven ? (
                <div className="card-body text-center">
                  <h5 className="m-0 p-5">
                    Thank You, we have already received feedback <br /> for this
                    order from you.
                  </h5>
                </div>
              ) : (
                <div className="card-body">
                  <h4 className="text-primary">Delivery Feedback</h4>
                  <p className="font-weight-bold">
                    (1). How to you like our overall delivery service?
                    <ReactStars
                      count={5}
                      size={24}
                      activeColor="#ffd700"
                      onChange={this.onRatingChange.bind(
                        this,
                        "reviewDeliveryOverallService"
                      )}
                    />
                  </p>

                  <p className="font-weight-bold">
                    (2). How do you like product handling by delivery agent?
                    <ReactStars
                      count={5}
                      size={24}
                      activeColor="#ffd700"
                      onChange={this.onRatingChange.bind(
                        this,
                        "reviewProductHandellingByDeliveryAgent"
                      )}
                    />
                  </p>
                  <p className="font-weight-bold">
                    (3). How do you like our delivery timings?
                    <ReactStars
                      count={5}
                      size={24}
                      activeColor="#ffd700"
                      onChange={this.onRatingChange.bind(
                        this,
                        "reviewDeliveryTimings"
                      )}
                    />
                  </p>

                  <textarea
                    rows="3"
                    className="form-control"
                    placeholder="Comments"
                    name="comment"
                    value={this.state.comment}
                    onChange={this.onInputChange}
                  ></textarea>

                  <h4 className="text-primary mt-3">Delivery Agent Feedback</h4>
                  <p className="font-weight-bold">
                    Give your review to delivery agent
                    <ReactStars
                      count={5}
                      size={24}
                      activeColor="#ffd700"
                      onChange={this.onRatingChange.bind(
                        this,
                        "reviewDeliveryAgent"
                      )}
                    />
                  </p>

                  <div
                    className="btn btn-primary text-uppercase w-100 mt-3"
                    onClick={this.onFeedbackOrder}
                  >
                    Submit
                  </div>
                </div>
              )}
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

FeedbackModal.propsTypes = {
  feedbackOrder: PropTypes.func.isRequired,
  home: PropTypes.object.isRequired,
};

var mapStateToProps = (state) => ({
  home: state.home,
});

export default connect(mapStateToProps, { feedbackOrder })(FeedbackModal);
