import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getCart, deleteCart, updateCart } from '../../../actions/homeAction';
import LoadingOverlay from 'react-loading-overlay';
import Loading from '../common/Loading';
import { slide as Menu } from 'react-burger-menu';
import Swal from 'sweetalert2';

class SidebarCart extends Component {
	state = {};
	componentDidMount() {
		this.props.getCart();
	}
	componentWillReceiveProps(nextProps) {
		this.setState({
			cart: nextProps.home.cart,
			loading: nextProps.home.loading,
		});
	}

	onDeleteCart = (id) => {
		this.props.deleteCart(id);
	};

	addQuantity = (cartProduct) => {
		// console.log(cartProduct);
		// var SellerProducts = this.state.SellerProducts;
		// console.log(SellerProducts);
		// // if (cartProduct.Product.qty < 10) {
		// for (let i = 0; i < SellerProducts.length; i++) {
		// 	if (cartProduct.CartId === SellerProducts[i].CartId) {
		// 		// if()
		// 		if (SellerProducts[i].inventory_stock > parseInt(cartProduct.Product.qty)) {
		var data = {
			cartId: cartProduct.CartId,
			qty: parseInt(cartProduct.Product.qty) + 1,
		};
		// 		} else {
		// 			Swal.fire({
		// 				title: `Only ${SellerProducts[i].inventory_stock} quantity available in stock!`,
		// 				icon: '',
		// 				imageUrl: './images/spinner.png',
		// 				imageHeight: 100,
		// 				text: '',
		// 				type: 'error',
		// 				confirmButtonColor: '#e84200',
		// 				cancelButtonColor: '#d33',
		// 				confirmButtonText: 'Ok',
		// 			});
		// 		}
		// 	}
		// }
		// } else {
		// 	Swal.fire({
		// 		title: `You can only order this product with max qty 10`,
		// 		icon: '',
		// 		imageUrl: './images/spinner.png',
		// 		imageHeight: 100,
		// 		text: '',
		// 		type: 'error',
		// 		confirmButtonColor: '#e84200',
		// 		cancelButtonColor: '#d33',
		// 		confirmButtonText: 'Ok',
		// 	});
		// }

		if (data) {
			this.props.updateCart(data);
		}
	};
	lessQuantity = (cartProduct) => {
		var data = {
			cartId: cartProduct.CartId,
			qty: parseInt(cartProduct.Product.qty) - 1,
		};

		if (data) {
			this.props.updateCart(data);
		}
	};

	onCloseCart = () => {
		this.props.onClose();
	};

	goToCart = () => {
		window.location.href = '#/cart';
		this.props.onClose();
	};

	goToCheckout = () => {
		window.location.href = '#/checkout';
		this.props.onClose();
	};

	goToShopping = () => {
		window.location.href = '#/';
		this.props.onClose();
	};

	render() {
		return (
			<Menu isOpen={this.props.open} right onClose={this.onCloseCart}>
				<div>
					<LoadingOverlay active={this.state.loading} text={<Loading></Loading>}></LoadingOverlay>
					<h3>Shopping Cart</h3>

					<div className="list-group mt-4 sidebar-cart">
						{this.state.cart !== undefined && this.state.cart.length > 0 ? (
							this.state.cart.map((seller) => (
								<div className="product-card card border shadow-sm mb-3">
									<div className="store-wrapper">
										<div className="store-logo border shadow-sm">
											<img src="images/store.png" alt="" />
										</div>
										{seller.name}
									</div>

									<div className="col-md-12 p-3">
										{seller.SellerProducts.map((cart) => (
											<div className="row border-top pt-3 pb-3">
												<div className="col-md-12 d-flex align-items-center">
													<Link
														to={`/product-details/${cart.Product.id}`}
														className="product-link"
													>
														<h5 className="w-100 mb-2">{cart.Product.name}</h5>
													</Link>
												</div>
												<div className="col d-flex align-items-center p-0">
													<div class="form-group m-0">
														<div class="input-group mb-0">
															<div class="input-group-prepend">
																<span
																	class="input-group-text cursor-pointer"
																	onClick={this.lessQuantity.bind(this, cart)}
																>
																	-
																</span>
															</div>
															<input
																class="form-control quantity-text"
																value={cart.Product.qty}
																style={{ width: '40px' }}
															/>
															<div class="input-group-append">
																<span
																	class="input-group-text cursor-pointer"
																	onClick={this.addQuantity.bind(this, cart)}
																>
																	+
																</span>
															</div>
														</div>
													</div>
												</div>

												<div className="col p-0 d-flex align-items-center justify-content-end">
													<h5 className="m-0 text-primary">
														{' '}
														&#8377;{' '}
														{(cart.UOM.PTD.toFixed(2) * cart.Product.qty).toFixed(2)}
													</h5>
												</div>

												{/* s */}
											</div>
										))}
									</div>
								</div>
							))
						) : (
							<div className="d-flex justify-content-center align-items-center flex-column mx-3 my-5">
								<img
									className="my-3"
									src="https://www.flaticon.com/svg/static/icons/svg/308/308833.svg"
									alt=""
									style={{ width: '100px' }}
								/>
								<h3>Your cart is empty</h3>
								<p className="text-secondary text-center">
									There is nothing in your cart. Let's add some products in the cart.
								</p>
								<div onClick={this.goToShopping} className="btn btn-primary shadow-sm">
									CONTINUE SHOPPING
								</div>
							</div>
						)}
					</div>

					{this.state.cart !== undefined && this.state.cart.length > 0 ? (
						<div className="row m-0 pt-4">
							<div className="col-md-6">
								<div onClick={this.goToCart} className="btn my-1 btn-primary d-block text-uppercase">
									View Cart
								</div>
							</div>
							<div className="col-md-6">
								<div
									onClick={this.goToCheckout}
									className="btn my-1 btn-outline-primary d-block text-uppercase"
								>
									Checkout
								</div>
							</div>
						</div>
					) : (
						''
					)}
				</div>
			</Menu>
		);
	}
}

var mapStateToProps = (state) => ({
	home: state.home,
});

SidebarCart.propsTypes = {
	getCart: PropTypes.func.isRequired,
	home: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, {
	getCart,
	deleteCart,
	updateCart,
})(SidebarCart);
