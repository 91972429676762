import React, { Component } from "react";
import { HashRouter as Router, Route, Redirect } from "react-router-dom";
import "./App.css";
import "./css/custom.scss";
import SiteRoutes from "./routes/SiteRoutes";
import { Provider } from "react-redux";
import store from "./store";
import Loading from "./components/site/common/Loading";
import ScrollToTop from "./ScrollToTop";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
ReactGA.initialize("G-446XGW8XX8");
ReactGA.pageview(window.location.pathname + window.location.search);

const advancedMatching = { em: "react1infoware@gmail.com" }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
const options = {
  autoConfig: true, // set pixel's autoConfig
  debug: true, // enable logs
};

ReactPixel.init("175486827412453", advancedMatching, options);
ReactPixel.pageView();
ReactPixel.fbq("track", "PageView");

export default class App extends Component {
  state = {};

  render() {
    return (
      <div className="App">
        <div className="admin">
          <Provider store={store}>
            <Router baseName={"/"}>
              <ScrollToTop>
                <SiteRoutes></SiteRoutes>
              </ScrollToTop>
            </Router>
          </Provider>
        </div>
      </div>
    );
  }
}
