import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getCart, updateCart, deleteCart, getSettings } from '../../../actions/homeAction';
import LoadingOverlay from 'react-loading-overlay';
import Loading from '../common/Loading';
import Swal from 'sweetalert2';

class BTBCart extends Component {
	state = {
		type: 'CAR',
	};

	componentDidMount() {
		this.props.getCart();
		this.props.getSettings();
		window.addEventListener('resize', this.resize.bind(this));
		this.resize();
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			SellerProducts: nextProps.home.cart && nextProps.home.cart.SellerProducts,
			cart: nextProps.home.cart,
			loading: nextProps.home.loading,
			settings: nextProps.home.settings,
		});

		if (nextProps.home.cart && nextProps.home.cart.statusAvailable === false) {
			Swal.fire({
				title: 'Delivery Area Unavailable',
				icon: '',
				imageUrl: './images/spinner.png',
				imageHeight: 100,
				text: 'Currently we are not providing service in your entered pincode.',
				type: 'error',
				confirmButtonColor: '#e84200',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
			});
		}

		if (nextProps.home.settings) {
			var deliveryFree = nextProps.home.settings.deliveryFree;
			var deliveryCharge = nextProps.home.settings.deliveryCharge;
			this.setState({ deliveryCharge, deliveryFree });
		}
	}

	resize() {
		this.setState({ hideNav: window.innerWidth <= 760 });
	}
	componentWillUnmount() {
		window.removeEventListener('resize', this.resize.bind(this));
	}

	addQuantity = (cartProduct) => {
		// console.log(cartProduct);
		// var SellerProducts = this.state.SellerProducts;
		// console.log(SellerProducts);
		// // if (cartProduct.Product.qty < 10) {
		// for (let i = 0; i < SellerProducts.length; i++) {
		// 	if (cartProduct.CartId === SellerProducts[i].CartId) {
		// 		// if()
		// 		if (SellerProducts[i].inventory_stock > parseInt(cartProduct.Product.qty)) {
		var data = {
			cartId: cartProduct.CartId,
			qty: parseInt(cartProduct.Product.qty) + 1,
		};
		// 		} else {
		// 			Swal.fire({
		// 				title: `Only ${SellerProducts[i].inventory_stock} quantity available in stock!`,
		// 				icon: '',
		// 				imageUrl: './images/spinner.png',
		// 				imageHeight: 100,
		// 				text: '',
		// 				type: 'error',
		// 				confirmButtonColor: '#e84200',
		// 				cancelButtonColor: '#d33',
		// 				confirmButtonText: 'Ok',
		// 			});
		// 		}
		// 	}
		// }
		// } else {
		// 	Swal.fire({
		// 		title: `You can only order this product with max qty 10`,
		// 		icon: '',
		// 		imageUrl: './images/spinner.png',
		// 		imageHeight: 100,
		// 		text: '',
		// 		type: 'error',
		// 		confirmButtonColor: '#e84200',
		// 		cancelButtonColor: '#d33',
		// 		confirmButtonText: 'Ok',
		// 	});
		// }

		if (data) {
			this.props.updateCart(data);
		}
	};
	lessQuantity = (cartProduct) => {
		var data = {
			cartId: cartProduct.CartId,
			qty: parseInt(cartProduct.Product.qty) - 1,
		};

		if (data) {
			this.props.updateCart(data);
		}
	};

	removeFromCart = (CartId) => {
		this.props.deleteCart(CartId);
	};

	calculateSubtotal = () => {
		var cart = this.state.cart;
		console.log(cart);
		var subtotal = 0;
		if (cart) {
			for (let i = 0; i < cart.length; i++) {
				var SellerProducts = cart[i].SellerProducts;
				for (let j = 0; j < SellerProducts.length; j++) {
					if (SellerProducts[j].available) {
						var total = SellerProducts[j].UOM.MRP * SellerProducts[j].Product.qty;
						subtotal = subtotal + total;
					}
				}
			}
		}

		return subtotal.toFixed(2);
	};

	calculateAmountAfterDiscount = () => {
		var cart = this.state.cart;
		var subtotal = 0;
		if (cart) {
			for (let i = 0; i < cart.length; i++) {
				if (cart[i].available) {
					var total = cart[i].SP_customer * cart[i].Product.qty;
					subtotal = subtotal + total;
				}
			}
		}

		return subtotal.toFixed(2);
	};

	calculateDiscount = () => {
		var cart = this.state.cart;
		var subtotal = 0;
		if (cart) {
			for (let i = 0; i < cart.length; i++) {
				var SellerProducts = cart[i].SellerProducts;
				for (let j = 0; j < SellerProducts.length; j++) {
					if (SellerProducts[j].available) {
						var total = SellerProducts[j].UOM.flat_discount * SellerProducts[j].Product.qty;
						subtotal = subtotal + total;
					}
				}
			}
		}

		return subtotal.toFixed(2);
	};

	calculateGrandtotal = () => {
		var cart = this.state.cart;
		var subtotal = 0;
		if (cart) {
			for (let i = 0; i < cart.length; i++) {
				var SellerProducts = cart[i].SellerProducts;
				for (let j = 0; j < SellerProducts.length; j++) {
					if (SellerProducts[j].available) {
						var total = SellerProducts[j].UOM.PTR * SellerProducts[j].Product.qty;
						subtotal = subtotal + total;
					}
				}
			}
		}
		if (subtotal < this.state.deliveryFree) {
			subtotal = subtotal + this.state.deliveryCharge;
		}
		return subtotal.toFixed(2);
	};

	render() {
		var deliveryCharge = this.state.deliveryCharge;
		var deliveryFree = this.state.deliveryFree;
		return (
			<>
				<h3 className="text-primary m-0">Cart</h3>
				<p className="text-lead"> {this.state.cart && this.state.cart.length} Items</p>
				<div className="btn-cart-wrapper">
					<LoadingOverlay active={this.state.loading} text={<Loading></Loading>}></LoadingOverlay>

					{this.state.cart !== undefined && this.state.cart.length > 0 ? (
						<div>
							<div>
								{!this.state.hideNav ? (
									this.state.cart !== undefined && this.state.cart.length > 0 ? (
										this.state.cart.map(
											(seller) => (
												<div className="product-card card border shadow-sm mb-3">
													<div className="store-wrapper">
														<div className="store-logo border shadow-sm">
															<img src="images/store.png" alt="" />
														</div>
														{seller.name}
													</div>

													<div className="col-md-12 p-3">
														{seller.SellerProducts.map((cart) => (
															<div className="row border-top pt-3 pb-3">
																<div className="col-md-5 d-flex align-items-center">
																	<Link
																		to={`/product-details/${cart.Product.id}`}
																		className="product-link"
																	>
																		<h5 className="w-100 m-0">
																			{cart.Product.name}
																		</h5>
																	</Link>
																</div>
																<div className="col-md-4 d-flex align-items-center justify-content-center p-1">
																	<div class="form-group m-0">
																		<div class="input-group mb-0">
																			<div class="input-group-prepend">
																				<span
																					class="input-group-text cursor-pointer"
																					onClick={this.lessQuantity.bind(
																						this,
																						cart
																					)}
																				>
																					-
																				</span>
																			</div>
																			<input
																				class="form-control quantity-text"
																				value={cart.Product.qty}
																				style={{ width: '40px' }}
																			/>
																			<div class="input-group-append">
																				<span
																					class="input-group-text cursor-pointer"
																					onClick={this.addQuantity.bind(
																						this,
																						cart
																					)}
																				>
																					+
																				</span>
																			</div>
																		</div>
																	</div>
																</div>

																<div className="col-md-2 p-0 d-flex align-items-center justify-content-end">
																	<h5 className="m-0 text-primary">
																		&#8377;
																		{(
																			cart.UOM.PTD.toFixed(2) * cart.Product.qty
																		).toFixed(2)}
																	</h5>
																</div>

																<div className="col-1  d-flex align-items-center justify-content-end p-1">
																	<i
																		className="fa fa-times cursor-pointer"
																		onClick={this.removeFromCart.bind(
																			this,
																			cart.CartId
																		)}
																		style={{ fontSize: '16px' }}
																	></i>
																</div>
															</div>
														))}
													</div>
												</div>
											)
											// cart.available ? (
											// ) : (
											// 	<div className="list-group mb-3 ">
											// 		<div className="list-group-item shadow-sm bg-light">
											// 			<div className="row shopping-cart-item">
											// 				<div className="col-md-2 text-center d-flex align-items-center justify-content-center">
											// 					{/* <img
											// 						src={`http://s3-bucket-asia-2020.s3.amazonaws.com/productimages/${cart.Product.sku}/${cart.Product.sku}.main.png`}
											// 						alt={cart.Product.name}
											// 						style={{ width: '60%' }}
											// 						onError={(ev) =>
											// 							(ev.target.src =
											// 								'./images/logo1.png')
											// 						}
											// 					/> */}
											// 				</div>
											// 				<div className="col-md-4 d-flex pr-0 justify-content-center flex-column">
											// 					{/* <Link
											// 						to={`/product-details/${cart.Product.id}`}
											// 						className="product-link"
											// 					>
											// 						<h5 className="text-primary">
											// 							{cart.Product.name}
											// 						</h5>
											// 					</Link> */}
											// 					{/* <p className="m-0 text-left display-flex">
											// 						<h5 className="text-dark float-left ">
											// 							&#8377; {cart.Product.product_mrp.toFixed(2)}
											// 						</h5>
											// 						<h5>
											// 							<strike class="text-secondary ml-3">
											// 								&#8377;{' '}
											// 								{cart.Product.product_mrp.toFixed(2)}
											// 							</strike>
											// 						</h5>
											// 					</p> */}
											// 				</div>
											// 				<div className="col-md-3 d-flex align-items-center justify-content-center disabled"></div>

											// 				<div className="col-md-2 p-0 d-flex align-items-center justify-content-end">
											// 					{/* <h3 className="m-0 text-primary">
											// 						{' '}
											// 						&#8377;{' '}
											// 						{(cart.Product.product_mrp.toFixed(2) * 1).toFixed(
											// 							2
											// 						)}
											// 					</h3> */}
											// 				</div>

											// 				<div className="col  d-flex align-items-center justify-content-end">
											// 					{/* <i
											// 						className="fa fa-times cursor-pointer"
											// 						onClick={this.removeFromCart.bind(
											// 							this,
											// 							cart.CartId
											// 						)}
											// 						style={{ fontSize: '16px' }}
											// 					></i> */}
											// 				</div>
											// 			</div>{' '}
											// 			<p className="text-danger font-12 mt-3 mb-0">
											// 				* This product is currently not available in selected
											// 				pincode
											// 			</p>
											// 		</div>
											// 	</div>
											// )
										)
									) : (
										''
									)
								) : (
									<div className="list-group">
										{this.state.cart !== undefined && this.state.cart.length > 0
											? this.state.cart.map((cart) =>
													cart.available ? (
														<div className="list-group-item shadow-sm">
															<div className="row ">
																<div className="col-3 d-flex align-items-center justify-content-center">
																	<img
																		src={`http://s3-bucket-asia-2020.s3.amazonaws.com/productimages/${cart.Product.sku}/${cart.Product.sku}.main.png`}
																		alt={cart.Product.name}
																		style={{ width: '100%' }}
																		onError={(ev) =>
																			(ev.target.src =
																				'./images/logo1.png')
																		}
																	/>
																</div>
																<div className="col-7 d-flex justify-content-center flex-column">
																	<Link
																		to={`/product-details/${cart.Product.id}`}
																		className="product-link"
																	>
																		<h5 className="text-primary">
																			{cart.Product.name}
																		</h5>
																	</Link>
																	<p className="m-0 text-left display-flex">
																		<h5 className="text-dark float-left ">
																			&#8377; {cart.SP_customer.toFixed(2)}
																		</h5>
																		<h5>
																			<strike class="text-secondary ml-3">
																				&#8377; {cart.MRP.toFixed(2)}
																			</strike>
																		</h5>

																		<span className="text-primary text-success">
																			Save &#8377;{' '}
																			{cart.Product_flat_discount.toFixed(2)}
																		</span>
																	</p>
																</div>
																<div className="col  d-flex align-items-center justify-content-end">
																	<i
																		className="fa fa-times cursor-pointer"
																		onClick={this.removeFromCart.bind(
																			this,
																			cart.CartId
																		)}
																		style={{ fontSize: '16px' }}
																	></i>
																</div>
																<div className="col-6 mt-3">
																	<div class="form-group m-0">
																		<div class="input-group mb-0">
																			<div class="input-group-prepend">
																				<span
																					class="input-group-text cursor-pointer"
																					onClick={this.lessQuantity.bind(
																						this,
																						cart
																					)}
																				>
																					-
																				</span>
																			</div>
																			<input
																				class="form-control quantity-text"
																				value={cart.Product.qty}
																			/>
																			<div class="input-group-append">
																				<span
																					class="input-group-text cursor-pointer"
																					onClick={this.addQuantity.bind(
																						this,
																						cart
																					)}
																				>
																					+
																				</span>
																			</div>
																		</div>
																	</div>
																</div>

																<div className="col-6 d-flex align-items-center justify-content-end  mt-3">
																	<h3 className="m-0 text-primary">
																		{' '}
																		&#8377;{' '}
																		{(
																			cart.SP_customer.toFixed(2) *
																			cart.Product.qty
																		).toFixed(2)}
																	</h3>
																</div>
															</div>
														</div>
													) : null
											  )
											: ''}
									</div>
								)}
							</div>

							<div className="product-card card border h-100 shadow-sm mt-3">
								<h3 className="mb-3">Order Summary</h3>

								<p class="d-flex justify-content-between align-items-center mb-2">
									<span class="text-muted">Sub Amount</span>
									<span class="">&#8377; {this.calculateSubtotal()}</span>
								</p>
								<p class="d-flex justify-content-between align-items-center mb-2">
									<span class="text-muted">Savings on MRP</span>
									<span class="text-success">&#8377; {this.calculateDiscount()}</span>
								</p>

								{/* <p class="d-flex justify-content-between align-items-center mb-2">
										<span class="text-muted">Delivery Charges</span>
										<span class="">
											{this.calculateAmountAfterDiscount() < deliveryFree ? (
												<span>&#8377; {deliveryCharge.toFixed(2)}</span>
											) : (
												'FREE'
											)}
										</span>
									</p> */}
								<h5 class="d-flex text-primary justify-content-between align-items-center mb-4">
									<span class="">Payable Amount</span>
									<span class="">&#8377; {this.calculateGrandtotal()}</span>
								</h5>

								{/* <hr />
                      <h5>Apply Coupon</h5>

                      <div class="form-group">
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Enter Coupon Code"
                          />
                          <div class="input-group-append">
                            <p class="input-group-text px-3 cursor-pointer text-uppercase btn btn-primary">
                              Apply
                            </p>
                          </div>
                        </div>
                      </div> */}
								<hr />
								<Link to="/checkout" class="btn btn-primary d-block" role="button">
									PLACE ORDER
								</Link>
							</div>
						</div>
					) : (
						<div className="row d-flex justify-content-between">
							<div className="col-md-12 mx-auto text-center">
								<div className="d-flex justify-content-center align-items-center flex-column my-3">
									<img
										className="my-3"
										src="https://www.flaticon.com/svg/static/icons/svg/308/308833.svg"
										alt=""
										style={{ width: '100px' }}
									/>
									<h3>Your cart is empty</h3>
									{/* <p className="text-secondary">
										There is nothing in your cart. Let's add some products in the cart.
									</p>
									<Link to={'/product-list'} className="btn btn-primary shadow-sm">
										CONTINUE SHOPPING
									</Link> */}
								</div>
							</div>
						</div>
					)}
				</div>
			</>
		);
	}
}

var mapStateToProps = (state) => ({
	home: state.home,
});

BTBCart.propsTypes = {
	getCart: PropTypes.func.isRequired,
	home: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, {
	getCart,
	updateCart,
	deleteCart,
	getSettings,
})(BTBCart);
