import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getCategoryList, myCategoryList } from '../../../actions/homeAction';

class BTBCategory extends Component {
	state = {
		categoryList: [],
	};
	componentDidMount() {
		if (localStorage.getItem('btbloggedin') === true) {
			this.props.myCategoryList();
		}
		this.props.getCategoryList();
	}
	componentWillReceiveProps(nextProps) {
		this.setState({ categoryList: nextProps.home.categoryList });
		this.setState({ myCategoryList: nextProps.home.myCategoryList });

		if (
			nextProps.home.businessDetails &&
			nextProps.home.businessDetails.length > 0 &&
			nextProps.home.businessDetails[0].kycVerified
		) {
			this.setState({ show: true });
		} else {
			this.setState({ show: false });
		}
	}

	onSelectCategory = (id) => {
		var data = {};
		var categoryList = this.state.categoryList;
		for (let i = 0; i < categoryList.length; i++) {
			if (id === categoryList[i].id) {
				data = categoryList[i];
				this.setState({ selectedCategoryId: id });
			}
		}
		if (id === null) {
			this.setState({ selectedCategoryId: undefined });
		}

		this.props.onSelectCategory(data);
	};
	myCategory = () => {
		this.props.myCategoryList();
	};

	render() {
		const { categoryList } = this.state;
		const { myCategoryList } = this.state;
		return (
			<div className="category-list h-100">
				{this.state.selectedCategoryId ? (
					<div className="category" onClick={this.onSelectCategory.bind(this, null)}>
						All Products
					</div>
				) : (
					<div className="category selected" onClick={this.onSelectCategory.bind(this, null)}>
						All Products
					</div>
				)}

				{categoryList &&
					categoryList.length > 0 &&
					categoryList.map((category) =>
						category.id === this.state.selectedCategoryId ? (
							<div className="category selected" onClick={this.onSelectCategory.bind(this, category.id)}>
								{category.name}
							</div>
						) : (
							<div className="category" onClick={this.onSelectCategory.bind(this, category.id)}>
								{category.name}
							</div>
						)
					)}

				<hr />
				{this.state.show && (
					<div className="category text-primary" onClick={this.myCategory}>
						My Category
					</div>
				)}

				{myCategoryList &&
					myCategoryList.length > 0 &&
					myCategoryList.map((category) =>
						category.id === this.state.selectedCategoryId ? (
							<div className="category selected" onClick={this.onSelectCategory.bind(this, category.id)}>
								{category.name}
							</div>
						) : (
							<div className="category" onClick={this.onSelectCategory.bind(this, category.id)}>
								{category.name}
							</div>
						)
					)}
			</div>
		);
	}
}

BTBCategory.propsTypes = {
	home: PropTypes.object.isRequired,
};

var mapStateToProps = (state) => ({
	home: state.home,
});

export default connect(mapStateToProps, { getCategoryList, myCategoryList })(BTBCategory);
