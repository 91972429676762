import React, { Component } from "react";

export default class BillingTermConditions extends Component {
  render() {
    return (
      <div className="container py-5">
        <div class="header heading">BILLING TERMS AND CONDITIONS</div>
        <div class="bodytext">
          <dl>
            <dt>Payment Services</dt>
            <dd>
              <ol>
                <li>
                  All payments in respect of the Products shall be made to
                  designated Seller through the Platform. To the extent
                  permitted by applicable law and subject to AWL’s Privacy
                  Policy, you acknowledge and agree that AWL may use certain
                  third-party vendors and service providers, including payment
                  gateways, to process payments and manage payment card
                  information.
                </li>
                <li>
                  In order to make payments online, you undertake to use a valid
                  payment card or bank details or third party wallets or details
                  required for any other mode of payment (“Payment Details”)
                  with the authority to use the same, to have sufficient funds
                  or credit available to complete the payment on the Platform in
                  good standing. By providing the Payment Details, you
                  represent, warrant, and covenant that:
                  <ul>
                    <li>
                      You are legally authorized to provide such Payment
                      Details;
                    </li>
                    <li>
                      You are legally authorized to perform payments using such
                      Payment Details; and
                    </li>
                    <li>
                      Such action does not violate the terms and conditions
                      applicable to your use of such Payment Details or
                      applicable law.
                    </li>
                  </ul>
                  You agree that you are responsible for any fees charged by
                  your mobile carrier in connection with your use of the payment
                  services through your mobile. AWL shall use the Payment
                  Details as described in AWL’s Privacy Policy. You may add,
                  delete, and edit the Payment Details you have provided from
                  time to time through the Platform.
                </li>
                <li>
                  The payment receipt for completion of a transaction shall be
                  made available to Buyer on the Platform.{" "}
                </li>
                <li>
                  Except to the extent otherwise required by applicable law, AWL
                  is not liable for any payments authorized through the Platform
                  using Your Payment Details. Particularly, AWL is not liable
                  for any payments that do not complete because:
                  <ul>
                    <li>
                      Your payment card or bank account or third-party wallet
                      does not contain sufficient funds to complete the
                      transaction or the transaction exceeds the credit limit in
                      respect of the Payment Details provided;
                    </li>
                    <li>
                      You have not provided Seller with correct Payment Details;
                    </li>
                    <li>Your payment card has expired; or</li>
                    <li>
                      Circumstances beyond control (such as, but not limited to,
                      power outages, interruptions of cellular service, or any
                      other interferences from an outside force) prevent the
                      execution of the transaction.
                    </li>
                  </ul>
                </li>
                <li>
                  The Platform shall not be responsible for any unauthorized
                  transactions conducted on our Platform using your payment card
                  or internet banking. The Platform shall not be obligated to
                  refund any money to you in such instances.{" "}
                </li>
                <li>
                  All information that is collected at the time of payment, like
                  card details, names; passwords etc. are encrypted using Secure
                  Socket Layer (SSL) coding. The terms mentioned in this section
                  are also mentioned under "Billing Terms and Conditions".
                </li>
              </ol>
            </dd>
          </dl>
        </div>
      </div>
    );
  }
}
