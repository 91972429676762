import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class ChangePassword extends Component {
  render() {
    return (
      <div>
        <h5 className="bg-primary text-white p-2">
          <Link to="/"><i className="fa fa-home"></i> Home </Link> <i className="fa fa-chevron-right mx-3"></i> Change Password
        </h5>
        <div className="py-5">
          <div className="container">
            <div class="row d-flex justify-content-between">
              <div class="col-md-3 dashboard-left mb-5">
                <div class="checkout-sub">
                  <div className="list-group shadow-sm">
                    <Link to="/account" className="list-group-item product-link">
                      My Profile
                    </Link>
                    <Link to="/address" className="list-group-item product-link">
                      Address Book
                    </Link>
                    <Link to="/order" className="list-group-item product-link">
                      My Orders
                    </Link>
                    <Link to="/favourite" className="list-group-item product-link">
                      Favourites
                    </Link>
                    {/* <Link
                      to="/change-password"
                      className="list-group-item product-link text-white bg-primary"
                    >
                      Change Password
                    </Link> */}
                    <Link
                      className="list-group-item product-link cursor-pointer"
                      onClick={() => {
                        localStorage.clear();
                        window.location.href = "#/";
                        window.location.reload();
                      }}
                    >
                      Logout
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-md-8">
                <div class="checkout-sub">
                  <div class="form-group">
                    <div class="inputbox">
                      <input
                        type="password"
                        placeholder="Old Password"
                        class="form-control"
                        value=""
                      />
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="inputbox">
                      <input
                        type="password"
                        placeholder="New Password"
                        class="form-control"
                        value=""
                      />
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="inputbox">
                      <input
                        type="password"
                        placeholder="Confirm Password"
                        class="form-control"
                        value=""
                      />
                    </div>
                  </div>
                  <div class="change-div">
                    <button class="btn btn-primary">Change Password</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
