import React, { Component } from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getProductsList, getProductDetails, favourite, unFavourite } from '../../../actions/homeAction';
import { addToCart } from '../../../actions/homeAction';
import Slider from 'react-slick';
import LoadingOverlay from 'react-loading-overlay';
import Loading from '../common/Loading';
import PeopleAlsoView from './PeopleAlsoView';
import Swal from 'sweetalert2';

class ProductDetails extends Component {
	state = {
		addWish: false,
		d: 1,
		gallery: [],
		isLoading: true,
		type: 'CAR',
	};
	componentDidMount() {
		this.props.getProductsList();

		var data = {
			pinCode: localStorage.getItem('btbpincode'),
		};

		this.props.getProductDetails(this.props.match.params.productId, data);
	}
	componentWillReceiveProps(nextProps) {
		var productDetails = nextProps.home.productDetails;
		if (productDetails) {
			productDetails.qty = 1;
		}

		this.setState({
			productDetails: productDetails,
			products: nextProps.home.products,
		});

		if (nextProps.home.productDetails) {
			this.setState({ isLoading: false });
		}

		// if (nextProps.home.productDetails) {
		// 	var productDetails = nextProps.home.productDetails;

		// 	var productGallery = productDetails.Product;
		// 	var gallery = [];

		var firstImage = {
			original: `images/logo1.png`,
			thumbnail: `images/logo1.png`,
		};

		var gallery = [
			{
				original: `images/logo1.png`,
				thumbnail: `images/logo1.png`,
			},
			// {
			// 	original: `http://s3-bucket-asia-2020.s3.amazonaws.com/productimages/${productDetails.Product.sku}/${productDetails.Product.sku}.1.png`,
			// 	thumbnail: `http://s3-bucket-asia-2020.s3.amazonaws.com/productimages/${productDetails.Product.sku}/${productDetails.Product.sku}.1.png`,
			// },
			// {
			// 	original: `http://s3-bucket-asia-2020.s3.amazonaws.com/productimages/${productDetails.Product.sku}/${productDetails.Product.sku}.2.png`,
			// 	thumbnail: `http://s3-bucket-asia-2020.s3.amazonaws.com/productimages/${productDetails.Product.sku}/${productDetails.Product.sku}.2.png`,
			// },
			// {
			// 	original: `http://s3-bucket-asia-2020.s3.amazonaws.com/productimages/${productDetails.Product.sku}/${productDetails.Product.sku}.3.png`,
			// 	thumbnail: `http://s3-bucket-asia-2020.s3.amazonaws.com/productimages/${productDetails.Product.sku}/${productDetails.Product.sku}.3.png`,
			// },
			// {
			// 	original: `http://s3-bucket-asia-2020.s3.amazonaws.com/productimages/${productDetails.Product.sku}/${productDetails.Product.sku}.4.png`,
			// 	thumbnail: `http://s3-bucket-asia-2020.s3.amazonaws.com/productimages/${productDetails.Product.sku}/${productDetails.Product.sku}.4.png`,
			// },
		];

		this.setState({ gallery });
		// }

		if (
			nextProps.home.businessDetails &&
			nextProps.home.businessDetails.length > 0 &&
			nextProps.home.businessDetails[0].kycVerified
		) {
			this.setState({ show: true });
		} else {
			this.setState({ show: false });
		}
	}

	addQuantity = () => {
		const productDetails = this.state.productDetails;
		if (productDetails.qty < 10) {
			if (productDetails.inventory_stock > parseInt(productDetails.qty)) {
				if (productDetails.qty) {
					productDetails.qty = productDetails.qty + 1;
				} else {
					productDetails.qty = 1;
				}
			} else {
				Swal.fire({
					title: `Only ${productDetails.inventory_stock} quantity available in stock!`,
					icon: '',
					imageUrl: './images/spinner.png',
					imageHeight: 100,
					text: '',
					type: 'error',
					confirmButtonColor: '#e84200',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Ok',
				});
			}
		} else {
			Swal.fire({
				title: `You can only order this product with max qty 10`,
				icon: '',
				imageUrl: './images/spinner.png',
				imageHeight: 100,
				text: '',
				type: 'error',
				confirmButtonColor: '#e84200',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
			});
		}

		this.setState({ productDetails });
	};
	lessQuantity = () => {
		const productDetails = this.state.productDetails;

		if (productDetails.qty > 1) {
			productDetails.qty = productDetails.qty - 1;
		}

		this.setState({ productDetails });
	};
	addToCart = () => {
		if (this.state.show === false) {
			Swal.fire({
				title: `Complete KYC Process`,
				icon: '',
				imageUrl: './images/spinner.png',
				imageHeight: 100,
				text: 'Complete kyc approval process to get access to product pricess, ordering and schemes',
				type: 'error',
				confirmButtonColor: '#e84200',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
			});
			return false;
		}

		var productDetails = this.state.productDetails;
		var data = {
			userId: localStorage.getItem('btbuserId'),
			pinCodeId: localStorage.getItem('btbpincodeId'),
			productId: productDetails.ProductId,
			sellerId: productDetails.Seller.id,
			qty: productDetails.qty,
			price_when_added: productDetails.SP_customer,
			after_offer_price: productDetails.SP_customer,
		};
		this.props.addToCart(data);
	};

	onFavourite = (id) => {
		var data = {
			productId: id,
		};
		this.props.favourite(data);
		setTimeout(() => {
			window.location.reload();
		}, 1000);
	};

	onUnFavourite = (id) => {
		var data = {
			productId: id,
		};
		this.props.unFavourite(data);
		setTimeout(() => {
			window.location.reload();
		}, 1000);
	};

	onSelectType = (e) => {
		this.setState({ type: e.target.value });
	};

	render() {
		var { productDetails } = this.state;
		const settings = {
			infinite: false,
			slidesToShow: 5,
			slidesPerRow: 1,
			speed: 500,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3,
						infinite: true,
						dots: true,
					},
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2,
						initialSlide: 2,
					},
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
					},
				},
			],
		};

		return (
			<div>
				<LoadingOverlay active={this.state.isLoading} text={<Loading></Loading>}></LoadingOverlay>
				<div>
					{productDetails === null ? (
						<div className="container d-flex align-items-center justify-content-center">
							<img src="./images/coming-soon.png" style={{ width: '50%' }}></img>
						</div>
					) : (
						<>
							<h5 className="bg-primary text-white p-2">
								<Link to="/">
									<i className="fa fa-home"></i> Home{' '}
								</Link>{' '}
								<i className="fa fa-chevron-right mx-3"></i>
								<Link to="/product-list">
									Products
								</Link> <i className="fa fa-chevron-right mx-3"></i>{' '}
								{productDetails !== undefined ? productDetails.Product.name : ''}
							</h5>
							<div className="py-5">
								<div className="container">
									<div className="row d-flex justify-content-between">
										<div className="col-md-5 mb-5">
											<ImageGallery items={this.state.gallery} />

											{productDetails !== undefined ? (
												<div className="discount-icon">
													<i className="fa fa-certificate text-primary"></i>
													<p className="text-white">
														{productDetails.SellerProduct_UOM.map((type) =>
															type.name === this.state.type
																? Math.round(type.percentage_discount)
																: null
														)}
														%
													</p>
												</div>
											) : (
												''
											)}

											<div className="fav-icon cursor-pointer">
												{productDetails !== undefined ? (
													productDetails.Product.isFavorite ? (
														<i
															className="fa fa-heart text-danger"
															style={{ fontSize: '30px' }}
															onClick={this.onUnFavourite.bind(
																this,
																productDetails.Product.id
															)}
														></i>
													) : (
														<i
															className="fa fa-heart-o text-danger"
															style={{ fontSize: '30px' }}
															onClick={this.onFavourite.bind(
																this,
																productDetails.Product.id
															)}
														></i>
													)
												) : (
													''
												)}
											</div>
										</div>
										<div className="col-md-6">
											<h3 className="text-primary text-uppercase">
												{productDetails !== undefined ? productDetails.Product.name : ''}
											</h3>
											<hr />
											<h4>Product Details </h4>
											<b>Description</b> :{' '}
											{productDetails !== undefined ? productDetails.Product.description : ''}
											<br />
											<b>Brand</b> :{' '}
											{productDetails !== undefined ? productDetails.Product.Brand.name : ''}
											<br />
											<b>Category</b> :{' '}
											{productDetails !== undefined && productDetails.Product.Category
												? productDetails.Product.Category.name
												: ''}
											<br />
											<hr />
											<h4>Seller Details </h4>
											<b>Name</b> :{' '}
											{productDetails !== undefined ? productDetails.Seller.name : ''} <br />
											<b>Location</b> :{' '}
											{productDetails !== undefined ? productDetails.Seller.address : ''}
											<hr />
											<div className="row m-0">
												<div className="col-md-8 my-1 d-flex align-items-center">
													{productDetails &&
														productDetails.SellerProduct_UOM.map((type) =>
															type.name === this.state.type ? (
																<p
																	className={`m-0 text-right d-flex align-items-center product-price ${
																		this.state.show == false ? 'blur' : ''
																	}`}
																>
																	<h4 className="primary float-left m-0">
																		&#8377; {type.PTR.toFixed(2)}
																	</h4>{' '}
																	<strike class="secondary mx-3">
																		&#8377; {type.MRP.toFixed(2)}
																	</strike>{' '}
																	<span className="success">
																		Save &#8377; {type.flat_discount.toFixed(2)}
																	</span>
																</p>
															) : null
														)}
												</div>
											</div>
											<hr />
											{productDetails !== undefined ? (
												<div className="row m-0">
													<div className="col-md-12 my-1">
														<div className="d-flex justify-content-between">
															<select
																className="form-control"
																style={{ width: '100px' }}
																onChange={this.onSelectType}
															>
																{this.state.type === 'CAR' ? (
																	<>
																		<option value="CAR" selected>
																			Cartoon
																		</option>
																		<option value="EA">Single</option>
																	</>
																) : (
																	<>
																		<option value="CAR">Cartoon</option>
																		<option value="EA" selected>
																			Single
																		</option>
																	</>
																)}
															</select>
															<div class="form-group m-0">
																<div class="input-group mb-0">
																	<div class="input-group-prepend">
																		<span
																			class="input-group-text cursor-pointer"
																			onClick={this.lessQuantity.bind(
																				this,
																				productDetails.id
																			)}
																		>
																			-
																		</span>
																	</div>
																	<input
																		class="form-control quantity-text"
																		value={productDetails.qty}
																	/>
																	<div class="input-group-append">
																		<span
																			class="input-group-text cursor-pointer"
																			onClick={this.addQuantity.bind(
																				this,
																				productDetails.id
																			)}
																		>
																			+
																		</span>
																	</div>
																</div>
															</div>
															<button
																onClick={this.addToCart.bind(this, productDetails.id)}
																className="btn btn-outline-primary text-uppercase ml-2"
															>
																Add to Cart
															</button>
														</div>
													</div>
												</div>
											) : (
												''
											)}
											<hr />
										</div>
									</div>
								</div>
							</div>{' '}
						</>
					)}
				</div>
			</div>
		);
	}
}

var mapStateToProps = (state) => ({
	home: state.home,
	product: state.product,
});

ProductDetails.propsTypes = {
	getProductsList: PropTypes.func.isRequired,
	getProductDetails: PropTypes.func.isRequired,
	home: PropTypes.object.isRequired,
	product: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, {
	getProductsList,
	getProductDetails,
	addToCart,
	favourite,
	unFavourite,
})(ProductDetails);
