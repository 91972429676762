import React, { Component } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { addToCart } from "../../../actions/homeAction";
import { getTopProductsList } from "../../../actions/productAction";
import LoadingOverlay from "react-loading-overlay";
import Loading from "../common/Loading";

class PeopleAlsoView extends Component {
  state = {
    quantity: 1,
  };

  componentDidMount() {
    this.props.getTopProductsList();
  }
  componentWillReceiveProps(nextProps) {
    const topProducts = nextProps.product.topProducts;
    if (topProducts) {
      if (topProducts.top_product) {
        const top_product = topProducts.top_product;

        for (let i = 0; i < top_product.length; i++) {
          top_product[i].qty = 1;
        }
      }
    }

    this.setState({ topProducts, loading: nextProps.home.loading });
  }

  onOpenRegisterModal = () => {
    if (localStorage.getItem("auth")) {
      this.setState({ isRegisterModalOpen: true });
    }
  };
  onCloseRegisterModal = () => {
    this.setState({ isRegisterModalOpen: false });
  };
  addQuantity = (id) => {
    const topProducts = this.state.topProducts;
    const top_product = topProducts.top_product;
    for (let i = 0; i < top_product.length; i++) {
      if (id === top_product[i].ProductId) {
        if (top_product[i].qty) {
          top_product[i].qty = top_product[i].qty + 1;
        } else {
          top_product[i].qty = 1;
        }
      }
    }

    this.setState({ topProducts });
  };
  lessQuantity = (id) => {
    const topProducts = this.state.topProducts;
    const top_product = topProducts.top_product;
    for (let i = 0; i < top_product.length; i++) {
      if (id === top_product[i].ProductId) {
        if (top_product[i].qty > 1) {
          if (top_product[i].qty) {
            top_product[i].qty = top_product[i].qty - 1;
          } else {
            top_product[i].qty = 1;
          }
        }
      }
    }

    this.setState({ topProducts });
  };
  addToCart = (product) => {
    var data = {
      userId: localStorage.getItem("btbuserId"),
      pinCodeId: product.PinCode.id,
      productId: product.Product.id,
      sellerId: product.Seller.id,
      qty: product.qty,
      price_when_added: product.SP_customer,
      after_offer_price: product.SP_customer,
    };
    this.props.addToCart(data);
  };

  next = () => {
    this.slider.slickNext();
  };
  previous = () => {
    this.slider.slickPrev();
  };
  render() {
    const settings = {
      infinite: false,
      slidesToShow: 5,
      slidesPerRow: 1,
      speed: 500,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div>
        <LoadingOverlay
          active={this.state.loading}
          text={<Loading></Loading>}
        ></LoadingOverlay>

        <div className="py-5">
          <div className="container">
            <div className="d-flex justify-content-between px-3">
              <h2 className="text-uppercase pb-5">People Also View</h2>
              <Link
                to="/product-list"
                className="text-dark ml-3 mt-2 font-weight-bold"
              >
                View all <i className="fa fa-angle-double-right"></i>
              </Link>
            </div>

            <div className="row slider-row">
              <div className="col-12 mx-auto">
                <Slider {...settings} ref={(c) => (this.slider = c)}>
                  {this.state.topProducts !== undefined &&
                  this.state.topProducts.top_product
                    ? this.state.topProducts.top_product.map((product) => (
                        <div class="px-2 product-link my-1">
                          <div className="product-card card border h-100 shadow-sm d-flex flex-column">
                            <Link to={`/product-details/${product.Product.id}`}>
                              <img
                                className="mx-5"
                                src={`http://s3-bucket-asia-2020.s3.amazonaws.com/productimages/${product.Product.sku}/${product.Product.sku}.main.png`}
                                alt={product.Product.name}
                                style={{ width: "50%" }}
                                onError={(ev) =>
                                  (ev.target.src = "./images/logo1.png")
                                }
                              />
                              <div className="mt-2">
                                <h5 className="product-title">
                                  {product.Product.name}
                                </h5>
                                <div className="row m-0">
                                  <div className="col-md-12">
                                    <p className="m-0 text-left display-flex">
                                      <h5 className="text-dark float-left font-weight-bold">
                                        &#8377; {product.SP_customer.toFixed(2)}
                                      </h5>
                                      <h5 className="">
                                        <strike class="text-secondary ml-3">
                                          &#8377; {product.MRP.toFixed(2)}
                                        </strike>
                                      </h5>

                                      <span className="text-left text-primary text-success">
                                        Save &#8377;
                                        {product.Product_flat_discount.toFixed(
                                          2
                                        )}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </Link>
                            <div className="d-flex justify-content-between mt-3">
                              <div class="form-group m-0">
                                <div class="input-group mb-0">
                                  <div class="input-group-prepend">
                                    <span
                                      class="input-group-text cursor-pointer"
                                      onClick={this.lessQuantity.bind(
                                        this,
                                        product.ProductId
                                      )}
                                    >
                                      -
                                    </span>
                                  </div>
                                  <input
                                    class="form-control quantity-text"
                                    value={product.qty}
                                  />
                                  <div class="input-group-append">
                                    <span
                                      class="input-group-text cursor-pointer"
                                      onClick={this.addQuantity.bind(
                                        this,
                                        product.ProductId
                                      )}
                                    >
                                      +
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <button
                                onClick={this.addToCart.bind(this, product)}
                                className="btn btn-outline-primary text-uppercase ml-2"
                              >
                                Add
                              </button>
                            </div>
                            <div className="fav-icon">
                              <i className="fa fa-heart-o text-danger"></i>
                            </div>
                            <div className="discount-icon font-weight-bold">
                              <i className="fa fa-certificate text-primary"></i>
                              <p className="text-white">
                                {Math.round(
                                  product.Product_percentage_discount
                                )}
                                %
                              </p>
                            </div>
                          </div>
                        </div>
                      ))
                    : ""}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PeopleAlsoView.propsTypes = {
  loginUser: PropTypes.func.isRequired,
  guestUser: PropTypes.func.isRequired,
  home: PropTypes.object.isRequired,
};

var mapStateToProps = (state) => ({
  home: state.home,
  product: state.product,
  guest: state.guest,
});

export default connect(mapStateToProps, { getTopProductsList, addToCart })(
  PeopleAlsoView
);
