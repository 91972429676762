import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getProfile, updateProfile } from '../../../actions/homeAction';
import LoadingOverlay from 'react-loading-overlay';
import Loading from '../common/Loading';

class AccountSettings extends Component {
	state = {};
	componentDidMount() {
		this.props.getProfile();
	}
	componentWillReceiveProps(nextProps) {
		this.setState({
			profile: nextProps.home.profile,
			loading: nextProps.home.loading,
		});

		if (nextProps.home.profile) {
			this.setState({
				email: nextProps.home.profile.email,
				firstName: nextProps.home.profile.firstName,
				lastName: nextProps.home.profile.lastName,
				phone: nextProps.home.profile.phone,
			});
		}
	}

	onInputChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	onUpdateProfle = () => {
		var data = {
			email: this.state.email,
			firstName: this.state.firstName,
			lastName: this.state.lastName,
			phone: this.state.phone,
		};
		this.props.updateProfile(data);
	};
	render() {
		return (
			<div>
				<LoadingOverlay active={this.state.loading} text={<Loading></Loading>}></LoadingOverlay>
				<h5 className="bg-primary text-white p-2">
					<Link to="/">
						<i className="fa fa-home"></i> Home{' '}
					</Link>{' '}
					<i className="fa fa-chevron-right mx-3"></i> My Profile
				</h5>
				<div className="py-5">
					<div className="container">
						<div class="row d-flex justify-content-between">
							<div class="col-md-3 dashboard-left mb-5">
								<div class="checkout-sub">
									<div className="list-group shadow-sm">
										<Link to="/account" className="list-group-item product-link ">
											Your Profile
										</Link>
										<Link to="/business-profile" className="list-group-item product-link">
											Your Business Profile
										</Link>
										<Link
											to="/account-settings"
											className="list-group-item product-link text-white bg-primary"
										>
											Account Settings
										</Link>

										<Link to="/order" className="list-group-item product-link">
											Orders
										</Link>
										<Link to="/languages" className="list-group-item product-link">
											Languages
										</Link>
										<Link to="/address" className="list-group-item product-link">
											Address Book
										</Link>

										<Link to="/favourite" className="list-group-item product-link">
											Favourites
										</Link>

										<Link
											className="list-group-item product-link cursor-pointer"
											onClick={() => {
												localStorage.clear();
												window.location.href = '#/';
												window.location.reload();
											}}
										>
											Logout
										</Link>
									</div>
								</div>
							</div>
							<div class="col-md-8 checkout-right">
								<div class="checkout-sub">
									<div class="myprofile-main">
										<div class="profileform">
											<div class="custom-control custom-switch">
												<input
													type="checkbox"
													class="custom-control-input"
													id="customSwitch1"
												/>
												<label class="custom-control-label" for="customSwitch1">
													Toggle this switch element
												</label>
											</div>
											<div class="form-group">
												<input
													type="text"
													id=""
													name="firstName"
													placeholder="First Name"
													class="form-control"
													value={this.state.firstName}
													onChange={this.onInputChange}
												/>
											</div>
											<div class="form-group">
												<input
													type="text"
													id=""
													name="lastName"
													placeholder="Last Name"
													class="form-control"
													value={this.state.lastName}
													onChange={this.onInputChange}
												/>
											</div>
											<div class="form-group">
												<input
													type="text"
													id=""
													name="email"
													placeholder="Email Address"
													class="form-control"
													value={this.state.email}
													onChange={this.onInputChange}
												/>
											</div>
											<div class="form-group">
												<input
													type="text"
													id=""
													name="phone"
													placeholder="Mobile"
													class="form-control"
													value={this.state.phone}
													onChange={this.onInputChange}
												/>
											</div>
											<div class="change-div">
												<div class="btn btn-primary" onClick={this.onUpdateProfle}>
													UPDATE
												</div>{' '}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

AccountSettings.propsTypes = {
	getProfile: PropTypes.func.isRequired,
	home: PropTypes.object.isRequired,
};

var mapStateToProps = (state) => ({
	home: state.home,
	banners: state.home.bannersList,
});

export default connect(mapStateToProps, { getProfile, updateProfile })(AccountSettings);
