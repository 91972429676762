import React, { Component } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getCancellationList, cancelOrder } from "../../../actions/homeAction";
import Swal from "sweetalert2";

class CancelOrderModal extends Component {
  state = {
    open: false,
    isOtpModalOpen: false,
  };

  componentDidMount() {
    this.props.getCancellationList();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ cancellationList: nextProps.home.cancellationList });
  }

  onCloseModal = () => {
    this.setState({ open: false });
    this.props.close();
  };
  onInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onCancelOrder = (id) => {
    var data = {
      orderId: this.props.cancelOrderId,
      reasonOfCancellation: this.state.reason,
    };

    this.props.cancelOrder(data);
    this.onCloseModal();
  };

  render() {
    const { open } = this.props;
    return (
      <div>
        <Modal
          classNames={{
            modal: "p-0",
          }}
          open={open}
          onClose={this.onCloseModal}
          center
        >
          <div className="row m-0">
            <div className="col-md-12 ">
              <h4 className="box-title border-bottom p-3 text-primary text-uppercase">
                Cancel order
              </h4>
              <div className="card-body">
                <p>
                  Are you sure, want to cancel your order? If yes then select{" "}
                  <br />
                  your cancellation reason or enter the reason
                </p>

                {/* <select name="" id="" className="form-control my-3">
                  <option value=""> Select Reason</option>
                  {this.state.cancellationList !== undefined &&
                  this.state.cancellationList.length > 0
                    ? this.state.cancellationList.map((cancellation) => (
                        <option value={cancellation.id}>
                          {cancellation.reason}
                        </option>
                      ))
                    : ""}
                </select>
                <div className="list-group"></div> */}

                <textarea
                  rows="3"
                  className="form-control"
                  placeholder="Type your reason"
                  name="reason"
                  value={this.state.reason}
                  onChange={this.onInputChange}
                ></textarea>

                <div
                  className="btn btn-primary text-uppercase w-100 mt-3"
                  onClick={this.onCancelOrder}
                >
                  Submit
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

CancelOrderModal.propsTypes = {
  getCancellationList: PropTypes.func.isRequired,
  cancelOrder: PropTypes.func.isRequired,
  home: PropTypes.object.isRequired,
};

var mapStateToProps = (state) => ({
  home: state.home,
});

export default connect(mapStateToProps, { getCancellationList, cancelOrder })(
  CancelOrderModal
);
