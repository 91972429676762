import React, { Component } from "react";
import { CheckoutProvider, Checkout } from "paytm-blink-checkout-react";
import axios from "axios";
import Constant from "../../../../Constant";
import Swal from "sweetalert2";
import ReactPixel from "react-facebook-pixel";

const SSR_SIMULATE = "SSR Simulate : ";

class Paytm extends Component {
  textAreaRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      config: this.appendHandler(props.config),
      showCheckout: false,
      openInPopup: true,
      checkoutJsInstance: null,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.config) {
      this.setState({ config: this.appendHandler(nextProps.config) });
      this.loadCheckoutScript(nextProps.config);
    }
  }
  appendHandler(config) {
    const newConfig = { ...config };

    newConfig.handler = {
      notifyMerchant: this.notifyMerchantHandler,
      transactionStatus: function (data) {
        console.log("payment status ", data);
        if (data.STATUS === "TXN_SUCCESS") {
          axios
            .post(Constant.getAPI() + "/paytm/response", data, config)
            .then((res) => {
              Swal.fire({
                title: "Order Placed",
                icon: "",
                imageUrl: "./images/spinner.png",
                imageHeight: 100,
                html: "Your order has been successfully placed!",
                showCloseButton: true,
                showCancelButton: false,
                focusConfirm: false,
                confirmButtonText:
                  '<a class="text-white"  href="https://www.fortuneonline.in/#/order">Go to my orders</a>',
                // confirmButtonAriaLabel: "Thumbs up, great!",
                confirmButtonColor: "#e84200",
              });
            });

          ReactPixel.track("Purchase", data);
          ReactPixel.fbq("track", "Purchase", data);
        } else {
          Swal.fire({
            title: "Payment Failed",
            icon: "",
            imageUrl: "./images/spinner.png",
            imageHeight: 100,
            text: data.RESPMSG,
            type: "error",
            confirmButtonColor: "#e84200",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
          });

          axios
            .post(Constant.getAPI() + "/paytm/response", data, config)
            .then((res) => {
              console.log(res);
            });
        }

        window.Paytm.CheckoutJS.close();
        var result = "<h2>Response: </h2><table>";
        for (var key in data) {
          if (data.hasOwnProperty(key)) {
            result += "<tr><td>" + key + "</td><td>" + data[key] + "</td></tr>";
          }
        }
        result += "</table>";
        document.getElementById("blink-response").innerHTML = result;
      },
    };

    return newConfig;
  }

  notifyMerchantHandler = (eventType, data) => {
    console.log("MERCHANT NOTIFY LOG", eventType, data);
  };

  renderUpdateConfig = () => {
    this.setState({
      config: this.getUpdatedConfig(),
    });
  };

  getUpdatedConfig() {
    const config = this.parse("");

    return this.appendHandler(config);
  }

  parse(value) {
    try {
      return JSON.parse(value);
    } catch (err) {
      console.error("Invalid config JSON");
      return {};
    }
  }

  toggleOpenInPopup = () => {
    this.setState((prevState, _) => ({
      openInPopup: !prevState.openInPopup,
    }));
  };

  toggleCheckout = () => {
    this.setState((prevState, _) => ({
      showCheckout: !prevState.showCheckout,
    }));
  };

  loadCheckoutScript = (config) => {
    const url = "https://securegw.paytm.in/merchantpgpui/checkoutjs/merchants/";
    const scriptElement = document.createElement("script");
    scriptElement.async = true;
    scriptElement.src = url.concat(config.merchant.mid);
    scriptElement.type = "application/javascript";
    scriptElement.onload = () => {
      const checkoutJsInstance = this.getCheckoutJsObj();

      if (checkoutJsInstance && checkoutJsInstance.onLoad) {
        checkoutJsInstance.onLoad(() => {
          this.setState({
            config: this.getUpdatedConfig(),
            checkoutJsInstance,
          });
        });
      } else {
        console.error(SSR_SIMULATE + "onload not available!");
      }
    };
    scriptElement.onerror = (error) => {
      console.error("SSR Simulate: script load fail!");
    };
    document.body.appendChild(scriptElement);
    this.setState({ isScriptLoading: true });
  };

  getCheckoutJsObj() {
    if (window && window.Paytm && window.Paytm.CheckoutJS) {
      return window.Paytm.CheckoutJS;
    } else {
      console.error(SSR_SIMULATE + "Checkout instance not found!");
    }

    return null;
  }

  render() {
    const { showCheckout, openInPopup, config } = this.state;
    const textAreaVal = JSON.stringify(config, null, 4);

    return (
      <div>
        <textarea
          cols="50"
          rows="25"
          defaultValue={textAreaVal}
          ref={this.textAreaRef}
          className="d-none"
        />

        <CheckoutProvider config={this.state.config} openInPopup={openInPopup}>
          <Checkout />
        </CheckoutProvider>
      </div>
    );
  }
}

export default Paytm;
