import React, { Component } from 'react';
import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
} from 'react-accessible-accordion';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
	addAddress,
	getAddress,
	updateAddress,
	deleteAddress,
	getCart,
	placeOrder,
	getSettings,
	verifyCoupon,
} from '../../../actions/homeAction';
import LoadingOverlay from 'react-loading-overlay';
import Loading from '../common/Loading';
import { Link } from 'react-router-dom';
import RegisterModal from '../modals/RegisterModal';
import Swal from 'sweetalert2';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import AddressModal from '../modals/AddressModal';
import Paytm from './paytm/Paytm';

class Checkout extends Component {
	state = {
		label: 'Home',
		pincode: localStorage.getItem('btbpincode'),
		selectedPaymentType: 'online',
	};
	componentDidMount() {
		this.props.getAddress();
		this.props.getCart();
		this.props.getSettings();

		this.onRemoveCoupon();
	}
	componentWillReceiveProps(nextProps) {
		this.setState({
			address: nextProps.home.address,
			cart: nextProps.home.cart,
			loading: nextProps.home.loading,
		});

		if (nextProps.home.cart && nextProps.home.cart.statusAvailable === false) {
			Swal.fire({
				title: 'Delivery Area Unavailable',
				icon: '',
				imageUrl: './images/spinner.png',
				imageHeight: 100,
				text: 'Currently we are not providing service in your entered pincode.',
				type: 'error',
				confirmButtonColor: '#e84200',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
			});
		}

		if (nextProps.home.settings) {
			var deliveryFree = nextProps.home.settings.deliveryFree;
			var deliveryCharge = nextProps.home.settings.deliveryCharge;
			var minimumAmount = nextProps.home.settings.minimumAmount;
			this.setState({ deliveryCharge, deliveryFree, minimumAmount });
		}

		if (nextProps.home.couponDetails && nextProps.home.couponDetails.status) {
			this.setState({
				couponDetails: nextProps.home.couponDetails,
				couponApplied: true,
				couponCode: nextProps.home.couponDetails.couponCode,
			});
		}

		if (nextProps.home.redirect) {
			var paytmDetails = nextProps.home.redirect;
			const config = {
				style: {
					bodyBackgroundColor: '#fafafb',
					bodyColor: '',
					themeBackgroundColor: '#dfa231',
					themeColor: '#ffffff',
					headerBackgroundColor: '#284055',
					headerColor: '#ffffff',
					errorColor: '',
					successColor: '',
					card: {
						padding: '',
						backgroundColor: '',
					},
				},
				jsFile: '',
				data: {
					MID: paytmDetails.MID,
					custId: paytmDetails.custId,
					orderId: paytmDetails.invoiceId,
					amount: paytmDetails.txnAmount,
					token: paytmDetails.txnToken,
					tokenType: 'TXN_TOKEN',
				},
				merchant: {
					mid: paytmDetails.MID,
					name: '',
					logo: 'https://www.fortuneonline.in/images/logo.png',
					redirect: false,
				},
				mapClientMessage: {},
				labels: {},
				payMode: {
					labels: {},
					filter: {
						exclude: [],
					},
					order: ['NB', 'CARD', 'LOGIN'],
				},
				flow: 'DEFAULT',
			};

			this.setState({
				redirect: true,
				config: config,
			});
		}
	}
	onOpenRegisterModal = () => {
		this.setState({ isRegisterModalOpen: true });
	};
	onCloseRegisterModal = () => {
		this.setState({ isRegisterModalOpen: false });
	};
	onOpenAddressModal = () => {
		this.setState({ isAddressModalOpen: true });
	};
	onCloseAddressModal = () => {
		this.setState({ isAddressModalOpen: false });
	};

	checkLogin = () => {
		this.clearState();
		if (!localStorage.getItem("btbloggedin")) {
			this.onOpenRegisterModal();
		} else {
			this.setState({
				verified: true,
				addingAddress: true,
				addressDetails: {},
			});
			this.onOpenAddressModal();
		}
	};

	setAddress = (id) => {
		const address = this.state.address;
		console.log(id);
		for (let i = 0; i < address.length; i++) {
			if (id === address[i].id) {
				var addressDetails = {
					addressId: address[i].id,
					mobile: address[i].mobile,
					name: address[i].name,
					road: address[i].road,
					landmark: address[i].landmark,
					PinCodeId: address[i].PinCodeId,
					lat: address[i].lat,
					long: address[i].long,
					label: address[i].label,
					address_line_1: address[i].address_line_1,
					address_line_2: address[i].address_line_2,
					alternate_mobile: address[i].alternate_mobile,
					pincode: address[i].PinCode.code,
					city: `${address[i].PinCode.area} - ${address[i].PinCode.City.name}`,
					state: address[i].PinCode.City.State.name,
				};
				this.setState({
					addressDetails: addressDetails,
					addingAddress: false,
					isAddressModalOpen: true,
				});
			}
		}
	};

	clearState = () => {
		this.setState({
			mobile: '',
			name: '',
			road: '',
			landmark: '',
			PinCodeId: '',
			lat: '',
			long: '',
			label: '',
			address_line_1: '',
			address_line_2: '',
			alternate_mobile: '',
		});
	};

	onDeleteAddress = (id) => {
		this.props.deleteAddress(id);
	};

	calculateAfterOfferPrice = () => {
		var cart = this.state.cart;
		var subtotal = 0;
		if (cart) {
			for (let i = 0; i < cart.length; i++) {
				if (cart[i].available) {
					var total = cart[i].after_offer_price * cart[i].Product.qty;
					subtotal = subtotal + total;
				}
			}
		}

		return subtotal;
	};

	calculateSubtotal = () => {
		var cart = this.state.cart;
		console.log(cart);
		var subtotal = 0;
		if (cart) {
			for (let i = 0; i < cart.length; i++) {
				var SellerProducts = cart[i].SellerProducts;
				for (let j = 0; j < SellerProducts.length; j++) {
					if (SellerProducts[j].available) {
						var total = SellerProducts[j].UOM.MRP * SellerProducts[j].Product.qty;
						subtotal = subtotal + total;
					}
				}
			}
		}

		return subtotal.toFixed(2);
	};

	calculateAmountAfterDiscount = () => {
		var cart = this.state.cart;
		var subtotal = 0;
		if (cart) {
			for (let i = 0; i < cart.length; i++) {
				if (cart[i].available) {
					var total = cart[i].SP_customer * cart[i].Product.qty;
					subtotal = subtotal + total;
				}
			}
		}

		return subtotal.toFixed(2);
	};

	calculateDiscount = () => {
		var cart = this.state.cart;
		var subtotal = 0;
		if (cart) {
			for (let i = 0; i < cart.length; i++) {
				var SellerProducts = cart[i].SellerProducts;
				for (let j = 0; j < SellerProducts.length; j++) {
					if (SellerProducts[j].available) {
						var total = SellerProducts[j].UOM.flat_discount * SellerProducts[j].Product.qty;
						subtotal = subtotal + total;
					}
				}
			}
		}

		return subtotal.toFixed(2);
	};

	calculateGrandtotal = () => {
		var cart = this.state.cart;
		var subtotal = 0;
		if (cart) {
			for (let i = 0; i < cart.length; i++) {
				var SellerProducts = cart[i].SellerProducts;
				for (let j = 0; j < SellerProducts.length; j++) {
					if (SellerProducts[j].available) {
						var total = SellerProducts[j].UOM.PTR * SellerProducts[j].Product.qty;
						subtotal = subtotal + total;
					}
				}
			}
		}
		if (subtotal < this.state.deliveryFree) {
			subtotal = subtotal + this.state.deliveryCharge;
		}
		return subtotal.toFixed(2);
	};

	calculateCouponAmount = () => {
		var couponDetails = this.state.couponDetails;
		var calculateGrandtotal = this.calculateAmountAfterDiscount();
		// 1 - normal
		// 2 - product
		// 3 - pincode

		console.log(couponAmount);

		var couponAmount = 0;

		if (couponDetails.type === 1) {
			if (couponDetails.discountType === 'percent') {
				couponAmount = (calculateGrandtotal * couponDetails.discountValue) / 100;
			} else {
				couponAmount = couponDetails.discountValue;
			}
		} else if (couponDetails.type === 2) {
			if (couponDetails.discountType === 'percent') {
			} else {
			}
		} else {
			if (couponDetails.discountType === 'percent') {
				couponAmount = (calculateGrandtotal * couponDetails.discountValue) / 100;
			} else {
				couponAmount = couponDetails.discountValue;
			}
		}

		return couponAmount;
	};

	onPlaceOrder = () => {
		if (this.calculateGrandtotal() < this.state.minimumAmount) {
			Swal.fire({
				title: `Currently we are only processing orders with billing total more than ${this.state.minimumAmount}.00 Rupees`,
				icon: '',
				imageUrl: './images/spinner.png',
				imageHeight: 100,
				text: '',
				type: 'warning',
				confirmButtonColor: '#e84200',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
			});

			return false;
		}

		if (this.state.cart.length < 1) {
			Swal.fire({
				title: 'Please add item to cart!',
				icon: '',
				imageUrl: './images/spinner.png',
				imageHeight: 100,
				text: '',
				type: 'warning',
				confirmButtonColor: '#e84200',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
			});

			return false;
		}

		var cart = this.state.cart;
		if (cart) {
			for (let i = 0; i < cart.length; i++) {
				if (cart[i].available === false) {
					Swal.fire({
						title: 'Please remove unavailable items from cart!',
						icon: '',
						imageUrl: './images/spinner.png',
						imageHeight: 100,
						text: '',
						type: 'warning',
						confirmButtonColor: '#e84200',
						cancelButtonColor: '#d33',
						confirmButtonText: 'Ok',
					});

					return false;
				}
			}
		}

		if (!this.state.addressId) {
			Swal.fire({
				title: 'Please Select Address',
				icon: '',
				imageUrl: './images/spinner.png',
				imageHeight: 100,
				text: '',
				type: 'warning',
				confirmButtonColor: '#e84200',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
			});

			return false;
		}

		if (!this.state.selectedPaymentType) {
			Swal.fire({
				title: 'Please Select Payment Type',
				icon: '',
				imageUrl: './images/spinner.png',
				imageHeight: 100,
				text: '',
				type: 'warning',
				confirmButtonColor: '#e84200',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
			});

			return false;
		}

		var data = {
			addressId: this.state.addressId,
			delivery_notes: this.state.delivery_notes,
			paymentType: this.state.selectedPaymentType,
			subTotal: this.calculateAfterOfferPrice(),
		};

		if (this.state.couponApplied) {
			data.subTotal = this.calculateAfterOfferPrice();
			data.couponCode = this.state.couponCode;
			data.couponAmount = this.calculateCouponAmount();
		}
		this.props.placeOrder(data);
	};

	onInputChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	onRemoveCoupon = () => {
		this.setState({ couponApplied: false, couponCode: '' });
	};

	onSelectAddress = (address) => {
		if (localStorage.getItem('btbpincode') === address.PinCode.code) {
			this.setState({ addressId: address.id });
		} else {
			Swal.fire({
				title: 'Are you sure?',
				text: 'Your selected address is having different pincode that your current cart, Product pricing and availability may change based on new pincode.',
				icon: '',
				imageUrl: './images/spinner.png',
				imageHeight: 100,
				showCancelButton: true,
				confirmButtonColor: '#e84200',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
				confirmButtonText: 'Yes',
			}).then((result) => {
				if (result.isConfirmed) {
					localStorage.setItem('btbpincode', address.PinCode.code);
					localStorage.setItem('btbpincodeId', address.PinCode.id);
					localStorage.setItem('btbcity', address.PinCode.City.name);
					this.setState({ addressId: address.id });

					this.props.getCart();
					this.props.getSettings();
				}
			});
		}
	};

	onVerifyCoupon = () => {
		if (!this.state.addressId) {
			Swal.fire({
				title: 'Please Select Address',
				icon: '',
				imageUrl: './images/spinner.png',
				imageHeight: 100,
				text: '',
				type: 'warning',
				confirmButtonColor: '#e84200',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
			});

			return false;
		} else {
			if (!this.state.couponCode) {
				Swal.fire({
					title: 'Please enter coupon code!',
					icon: '',
					imageUrl: './images/spinner.png',
					imageHeight: 100,
					text: '',
					type: 'error',
					confirmButtonColor: '#e84200',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Ok',
				});
				return false;
			}

			var data = {
				couponCode: this.state.couponCode,
				pinCode: localStorage.getItem('btbpincode'),
			};
			this.props.verifyCoupon(data, this.calculateGrandtotal());
		}
	};

	onSelectPaymentType = (type) => {
		this.setState({ selectedPaymentType: type });
	};
	render() {
		var deliveryCharge = this.state.deliveryCharge;
		var deliveryFree = this.state.deliveryFree;
		return (
			<div>
				{false ? (
					<div>
						<iframe
							src={'http://204.48.26.50:7050/testtxn?query=COADI0182021048333'}
							frameborder="0"
							width="100%"
							height="1000px"
							name="myFrame"
							target="_self"
						></iframe>
					</div>
				) : (
					<div>
						<LoadingOverlay active={this.state.loading} text={<Loading></Loading>}></LoadingOverlay>
						<div>
							<h5 className="bg-primary text-white p-2">
								<Link to="/">
									<i className="fa fa-home"></i> Home{' '}
								</Link>{' '}
								<i className="fa fa-chevron-right mx-3"></i> Checkout
							</h5>
							<div className="container py-5">
								<div className="row d-flex justify-content-between">
									<div className="col-md-8 mb-5">
										<div>
											<div className="d-flex justify-content-between align-items-center mb-3">
												<h3 className="m-0">Select Address</h3>

												<div className="btn btn-primary " onClick={this.checkLogin}>
													ADD ADDRESS
												</div>
											</div>

											{this.state.address !== undefined && this.state.address.length > 0 ? (
												<div>
													<div>
														<Accordion
															allowMultipleExpanded={!this.state.addingAddress}
															allowZeroExpanded={!this.state.addingAddress}
															dangerouslySetExpanding={!this.state.addingAddress}
														>
															{this.state.address !== undefined
																? this.state.address.map((address) => (
																		<AccordionItem className="shadow-sm border-bottom">
																			<AccordionItemHeading>
																				<AccordionItemButton>
																					{address.label}
																				</AccordionItemButton>
																			</AccordionItemHeading>
																			<AccordionItemPanel>
																				<div>
																					<div>
																						<div className="row">
																							<div className="col-6">
																								{' '}
																								<h3 className="float-left m-0">
																									{address.name}
																								</h3>{' '}
																								<div className="badge badge-primary ml-3">
																									<h5 className="m-0 text-white">
																										{address.label}
																									</h5>
																								</div>
																							</div>
																							<div className="col-6">
																								{this.state
																									.addressId ===
																								address.id ? (
																									<span className="btn text-uppercase btn-success text-white float-right">
																										Selected
																									</span>
																								) : (
																									<span
																										className="btn text-uppercase btn-primary float-right"
																										onClick={this.onSelectAddress.bind(
																											this,
																											address
																										)}
																									>
																										Select
																									</span>
																								)}
																							</div>
																						</div>
																					</div>

																					<br />
																					{}
																					<p>
																						{address.address_line_1},{' '}
																						{address.address_line_2},{' '}
																						{address.landmark},{' '}
																						{address.PinCode.area} -{' '}
																						{address.PinCode.City.name},{' '}
																						{
																							address.PinCode.City.State
																								.name
																						}{' '}
																						- {address.PinCode.code}
																					</p>
																					<h5>Mobile No: {address.mobile}</h5>
																					<br />
																					<div>
																						<div class="change-div">
																							<button
																								class="btn btn-primary"
																								onClick={this.setAddress.bind(
																									this,
																									address.id
																								)}
																							>
																								EDIT
																							</button>{' '}
																							<a
																								onClick={this.onDeleteAddress.bind(
																									this,
																									address.id
																								)}
																								class="btn btn-dark"
																							>
																								DELETE
																							</a>
																						</div>
																					</div>
																				</div>
																			</AccordionItemPanel>
																		</AccordionItem>
																  ))
																: ''}
														</Accordion>
													</div>
												</div>
											) : (
												<div className="p-5 text-center">No address available</div>
											)}
										</div>

										<div className="mt-5">
											<div>
												<h3 className="mb-3">Delivery Notes</h3>
												<div>
													<textarea
														className="form-control"
														name="delivery_notes"
														id=""
														cols="10"
														rows="3"
														placeholder="Enter delivery notes"
														value={this.state.delivery_notes}
														onChange={this.onInputChange}
													></textarea>
												</div>
											</div>
										</div>
									</div>

									<div class="col-12 col-sm-12 col-md-3 ">
										<h3 className="mb-3">Order Summary</h3>
										<div className="card border px-2 py-3 shadow-sm rounded">
											<div class="checkOutBlock px-2">
												<p class="d-flex justify-content-between align-items-center mb-2">
													<span class="text-muted">Sub Amount</span>
													<span class="">&#8377; {this.calculateSubtotal()}</span>
												</p>
												<p class="d-flex justify-content-between align-items-center mb-2">
													<span class="text-muted">Savings on MRP</span>
													<span class="text-success">&#8377; {this.calculateDiscount()}</span>
												</p>

												<p class="d-flex justify-content-between align-items-center mb-2">
													<span class="text-muted">Delivery Charges</span>
													<span class="">
														{this.calculateAmountAfterDiscount() < deliveryFree ? (
															<span>&#8377; {deliveryCharge.toFixed(2)}</span>
														) : (
															'FREE'
														)}
													</span>
												</p>
												{this.state.couponApplied ? (
													<p class="d-flex justify-content-between align-items-center mb-2">
														<span class="text-muted">Coupon Discount</span>
														<span class="text-success">
															{' '}
															- &#8377; {this.calculateCouponAmount()}
														</span>
													</p>
												) : (
													''
												)}

												<h5 class="d-flex text-primary justify-content-between align-items-center mb-4">
													<span class="">Payable Amount</span>
													<span class="">&#8377; {this.calculateGrandtotal()}</span>
												</h5>

												<hr />
												<h5>Apply Coupon</h5>

												<div class="form-group">
													<div class="input-group mb-3">
														{this.state.couponApplied ? (
															<div class="input-group-prepend">
																<p
																	class="input-group-text cursor-pointer text-uppercase btn bg-danger text-white"
																	onClick={this.onRemoveCoupon}
																>
																	<i className="fa fa-times"></i>
																</p>
															</div>
														) : (
															''
														)}

														<input
															type="text"
															class="form-control"
															placeholder="Enter Coupon Code"
															value={this.state.couponCode}
															name="couponCode"
															onChange={this.onInputChange}
														/>
														<div class="input-group-append">
															{this.state.couponApplied ? (
																<p class="input-group-text px-3 cursor-pointer text-uppercase btn bg-success text-white">
																	Applied
																</p>
															) : (
																<p
																	class="input-group-text px-3 cursor-pointer text-uppercase btn btn-primary"
																	onClick={this.onVerifyCoupon}
																>
																	Apply
																</p>
															)}
														</div>
													</div>
												</div>
												<hr />
												<div class="pay-through pay-through-cart">
													<h5>Payment Method</h5>
													<p>Choose your preferred payment method</p>
													<ul class="unstyled select-delivery select-payment-option anim5 d-flex justify-content-around pl-0">
														{localStorage.getItem('btbpaytmModeAvailable') == 'true' ? (
															<li class="black d-flex flex-column align-items-center text-uppercase text-primary font-weight-bold">
																<input
																	class="styled-checkbox styled-checkbox-out"
																	id="cash"
																	name="payment"
																	type="radio"
																	onClick={this.onSelectPaymentType.bind(
																		this,
																		'online'
																	)}
																	checked={
																		this.state.selectedPaymentType === 'online'
																			? true
																			: false
																	}
																/>
																<label for="cash">
																	<span>
																		<img
																			src="images/credit-card.png"
																			class="img-paynow"
																			alt="kent"
																		/>
																	</span>
																</label>
																Pay Online
															</li>
														) : (
															''
														)}

														<li class="black d-flex flex-column align-items-center text-uppercase text-primary font-weight-bold">
															<input
																class="styled-checkbox styled-checkbox-out"
																id="p_net"
																name="payment"
																type="radio"
																onClick={this.onSelectPaymentType.bind(this, 'cod')}
																checked={
																	this.state.selectedPaymentType === 'cod'
																		? true
																		: false
																}
															/>
															<label for="p_net">
																<span className="mt-3">
																	<img
																		src="images/money.png"
																		class="img-paynow"
																		alt="kent"
																	/>
																</span>
															</label>
															Cash on delivery
														</li>
													</ul>
												</div>
												<hr />
												<span
													class="btn btn-primary d-block"
													role="button"
													onClick={this.onPlaceOrder}
												>
													{this.state.loading ? 'LOADING' : 'PLACE ORDER'}
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
							<RegisterModal
								open={this.state.isRegisterModalOpen}
								close={this.onCloseRegisterModal}
							></RegisterModal>
							<AddressModal
								open={this.state.isAddressModalOpen}
								close={this.onCloseAddressModal}
								addingAddress={this.state.addingAddress}
								addressDetails={this.state.addressDetails}
							></AddressModal>
							{this.state.selectedPaymentType === 'online' ? (
								<Paytm redirect={this.state.redirect} config={this.state.config}></Paytm>
							) : null}
						</div>
					</div>
				)}
			</div>
		);
	}
}

var mapStateToProps = (state) => ({
	home: state.home,
});

Checkout.propsTypes = {
	addAddress: PropTypes.func.isRequired,
	getAddress: PropTypes.func.isRequired,
	updateAddress: PropTypes.func.isRequired,
	deleteAddress: PropTypes.func.isRequired,
	getCart: PropTypes.func.isRequired,
	home: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, {
	addAddress,
	getAddress,
	updateAddress,
	deleteAddress,
	getCart,
	placeOrder,
	getSettings,
	verifyCoupon,
})(Checkout);
