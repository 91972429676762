import React, { Component } from 'react';
import Constant from '../../../../Constant';
import axios from 'axios';
import './image-upload.css';

class ImageUpload extends Component {
	state = {
		image: 'https://cdn4.iconfinder.com/data/icons/documents-42/512/document_file_paper_page-06-512.png',
	};

	onChange = (e) => {
		const files = Array.from(e.target.files);

		files.forEach((file, i) => {
			var data = {
				id: this.props.id,
				file: file,
			};
			this.setState({ image: URL.createObjectURL(file), uploadedImage: URL.createObjectURL(file) });
			this.props.uploadImage(data);
		});
	};
	getFileName = (filename) => {
		return filename.split('.').pop();
	};

	render() {
		return (
			<div className="card image-upload-wrapper course-wrapper p-1 bg-white">
				<div className="buttons fadein d-flex justify-content-center align-items-center flex-column">
					<div className="button">
						<label className="m-0" htmlFor={this.props.id}>
							{this.getFileName(
								this.state.uploadedImage
									? this.state.uploadedImage
									: this.props.image
									? this.props.image
									: this.state.image
							) == 'pdf' ? (
								<a
									href={
										this.state.uploadedImage
											? this.state.uploadedImage
											: this.props.image
											? this.props.image
											: this.state.image
									}
									target="_blank"
								>
									<img src="./images/pdf.png" alt="" />
								</a>
							) : (
								<img
									src={
										this.state.uploadedImage
											? this.state.uploadedImage
											: this.props.image
											? this.props.image
											: this.state.image
									}
									alt=""
								/>
							)}
						</label>
						<input type="file" id={this.props.id} accept={this.props.accept} onChange={this.onChange} />
					</div>
					<p className="m-0">{this.props.name && this.props.name.slice(14, 25)}</p>
				</div>

				{/* <img src={profileImage} alt="" /> */}
			</div>
		);
	}
}

export default ImageUpload;
