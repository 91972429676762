import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
} from 'react-accessible-accordion';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getProductsList, favourite, unFavourite } from '../../../actions/homeAction';
import { addToCart } from '../../../actions/homeAction';
import LoadingOverlay from 'react-loading-overlay';
import 'react-accessible-accordion/dist/fancy-example.css';
import Loading from '../common/Loading';
import Swal from 'sweetalert2';

class ProductList extends Component {
	state = {
		bannersList: [],
		addWish: false,
		addWish1: false,
		addWish2: false,
		addWish3: false,
		addWish4: false,
		addWish5: false,
		d: 1,
		d1: 1,
		d2: 1,
		d3: 1,
		d4: 1,
		d5: 1,
		categoryList: [],
		bannerList: [],
		products: [],
		loading: true,
	};

	componentDidMount() {
		var data = {
			pinCode: localStorage.getItem('btbpincode'),
		};

		if (this.props.match.params.keyword) {
			data.keyword = this.props.match.params.keyword;
			this.setState({ keyword: this.props.match.params.keyword });
		}
		this.props.getProductsList(data);
	}

	componentWillReceiveProps(nextProps) {
		const products = nextProps.home.productsList;
		if (products) {
			for (let i = 0; i < products.length; i++) {
				products[i].qty = 1;
			}
			if (products.length > 0) {
				this.setState({ loading: false });
			}
		}

		if (nextProps.match.params.keyword !== this.props.match.params.keyword) {
			var data = {
				pinCode: localStorage.getItem('btbpincode'),
			};

			if (nextProps.match.params.keyword) {
				data.keyword = nextProps.match.params.keyword;
			}
			this.props.getProductsList(data);
		}
		this.setState({ products, loading: nextProps.home.loading });
	}

	addQuantity = (id) => {
		const products = this.state.products;
		for (let i = 0; i < products.length; i++) {
			if (id === products[i].id) {
				if (products[i].qty < 10) {
					if (products[i].inventory_stock > parseInt(products[i].qty)) {
						if (products[i].qty) {
							products[i].qty = products[i].qty + 1;
						} else {
							products[i].qty = 1;
						}
					} else {
						Swal.fire({
							title: `Only ${products[i].inventory_stock} quantity available in stock!`,
							icon: '',
							imageUrl: './images/spinner.png',
							imageHeight: 100,
							text: '',
							type: 'error',
							confirmButtonColor: '#e84200',
							cancelButtonColor: '#d33',
							confirmButtonText: 'Ok',
						});
					}
				} else {
					Swal.fire({
						title: `You can only order this product with max qty 10`,
						icon: '',
						imageUrl: './images/spinner.png',
						imageHeight: 100,
						text: '',
						type: 'error',
						confirmButtonColor: '#e84200',
						cancelButtonColor: '#d33',
						confirmButtonText: 'Ok',
					});
				}
			}
		}

		this.setState({ products });
	};
	lessQuantity = (id) => {
		const products = this.state.products;
		for (let i = 0; i < products.length; i++) {
			if (id === products[i].id) {
				if (products[i].qty > 1) {
					if (products[i].qty) {
						products[i].qty = products[i].qty - 1;
					} else {
						products[i].qty = 1;
					}
				}
			}
		}

		this.setState({ products });
	};
	addToCart = (product) => {
		var data = {
			userId: localStorage.getItem('btbuserId'),
			pinCodeId: product.PCodeId,
			productId: product.Product.id,
			sellerId: product.Seller.id,
			qty: product.qty,
			price_when_added: product.SP_customer,
			after_offer_price: product.SP_customer,
		};
		this.props.addToCart(data);
	};

	onFavourite = (id) => {
		var data = {
			productId: id,
		};
		this.props.favourite(data);
	};

	onUnFavourite = (id) => {
		var data = {
			productId: id,
		};
		this.props.unFavourite(data);
	};

	render() {
		return (
			<div>
				{
					this.state.products && this.state.products.length > 0 ? (
						<div>
							<LoadingOverlay active={this.state.loading} text={<Loading></Loading>}></LoadingOverlay>
							<div>
								<h5 className="bg-primary text-white p-2">
									<Link to="/">
										<i className="fa fa-home"></i> Home{' '}
									</Link>{' '}
									<i className="fa fa-chevron-right mx-3"></i> Product List
								</h5>
								<div className="py-5 container">
									<div className="row m-0">
										{/* <div class="col-12 col-sm-12 col-md-3 col-lg-3 bg-white">
                    <div class="filterWrap px-3 pb-5">
                      <Accordion allowMultipleExpanded={true} allowZeroExpanded>
                        <AccordionItem
                          className="shadow-sm border-bottom"
                          style={{ background: "white" }}
                        >
                          <AccordionItemHeading>
                            <AccordionItemButton
                              style={{ background: "white" }}
                            >
                              <span
                                className=""
                                style={{ fontWeight: "bold", fontSize: "16px" }}
                              >
                                Filter{" "}
                              </span>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <div className="list-group">
                              <div className="list-group-item">
                                <i className="fa fa-circle text-primary cursor-pointer"></i>{" "}
                                New Product
                              </div>
                              <div className="list-group-item">
                                <i className="fa fa-circle text-primary cursor-pointer"></i>{" "}
                                Most Popular
                              </div>
                              <div className="list-group-item">
                                <i className="fa fa-circle text-primary cursor-pointer"></i>{" "}
                                Aplhabetical
                              </div>
                              <div className="list-group-item">
                                <i className="fa fa-circle text-primary cursor-pointer"></i>{" "}
                                Price : High to Low
                              </div>
                              <div className="list-group-item">
                                <i className="fa fa-circle text-primary cursor-pointer"></i>{" "}
                                Price : Low to High
                              </div>
                              <div className="list-group-item">
                                <i className="fa fa-circle text-primary cursor-pointer"></i>{" "}
                                Saving ( &#8377; ) : High to Low
                              </div>
                              <div className="list-group-item">
                                <i className="fa fa-circle text-primary cursor-pointer"></i>{" "}
                                Saving ( &#8377; ) : Low to High
                              </div>
                              <div className="list-group-item">
                                <i className="fa fa-circle text-primary cursor-pointer"></i>{" "}
                                Discount ( % ) : High to Low{" "}
                              </div>
                              <div className="list-group-item">
                                <i className="fa fa-circle text-primary cursor-pointer"></i>{" "}
                                Discount ( % ) : Low to High
                              </div>
                            </div>
                            <br />
                            <div class="reset">
                              <input
                                type="reset"
                                class="input-reset btn btn-sm btn-dark"
                                value="Reset"
                              />
                            </div>
                          </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem className="shadow-sm border-bottom">
                          <AccordionItemHeading>
                            <AccordionItemButton
                              style={{ background: "white" }}
                            >
                              <span
                                className=""
                                style={{ fontWeight: "bold", fontSize: "16px" }}
                              >
                                Sorting{" "}
                              </span>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <div className="list-group">
                              <div className="list-group-item">
                                <i className="fa fa-circle text-primary cursor-pointer"></i>{" "}
                                New Product
                              </div>
                              <div className="list-group-item">
                                <i className="fa fa-circle text-primary cursor-pointer"></i>{" "}
                                Most Popular
                              </div>
                              <div className="list-group-item">
                                <i className="fa fa-circle text-primary cursor-pointer"></i>{" "}
                                Aplhabetical
                              </div>
                              <div className="list-group-item">
                                <i className="fa fa-circle text-primary cursor-pointer"></i>{" "}
                                Price : High to Low
                              </div>
                              <div className="list-group-item">
                                <i className="fa fa-circle text-primary cursor-pointer"></i>{" "}
                                Price : Low to High
                              </div>
                              <div className="list-group-item">
                                <i className="fa fa-circle text-primary cursor-pointer"></i>{" "}
                                Saving ( &#8377; ) : High to Low
                              </div>
                              <div className="list-group-item">
                                <i className="fa fa-circle text-primary cursor-pointer"></i>{" "}
                                Saving ( &#8377; ) : Low to High
                              </div>
                              <div className="list-group-item">
                                <i className="fa fa-circle text-primary cursor-pointer"></i>{" "}
                                Discount ( % ) : High to Low{" "}
                              </div>
                              <div className="list-group-item">
                                <i className="fa fa-circle text-primary cursor-pointer"></i>{" "}
                                Discount ( % ) : Low to High
                              </div>
                            </div>
                            <br />
                            <div class="reset">
                              <input
                                type="reset"
                                class="input-reset btn btn-sm btn-dark"
                                value="Reset"
                              />
                            </div>
                          </AccordionItemPanel>
                        </AccordionItem>
                      </Accordion> */}

										{/* <div class="accordion" id="accordionExample">
                  <div class="card border">
                    <div class="card-header" id="headingOne">
                      <a
                        class="collapsed mr-2"
                        data-toggle="collapse"
                        href="#collapseOne"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                      >
                        CATEGORY
                      </a>
                      <i
                        class="fas fa-chevron-down fas-chevron"
                        aria-hidden="true"
                      ></i>
                    </div>
                    <div
                      id="collapseOne"
                      class="collapse show p-2"
                      aria-labelledby="headingOne"
                      data-parent="#accordionExample"
                    >
                      <input
                        id="exampleFormControlInput1"
                        type="email"
                        placeholder="Search By Category"
                        class="form-control form-control-search form-control-underlined"
                      />
                      <div class="p-2">
                        <div class="custom-control custom-control-filter p-0 custom-checkbox">
                          <input
                            type="checkbox"
                            id="Oils"
                            name="tyreFrontWidth"
                            value="Oils"
                          />
                          <label className="ml-1" for="Oils">
                            Oils
                          </label>
                        </div>
                        <div class="custom-control custom-control-filter p-0 custom-checkbox">
                          <input
                            type="checkbox"
                            id=" Foods"
                            name="tyreFrontWidth"
                            value=" Foods"
                          />
                          <label className="ml-1" for=" Foods">
                            Foods
                          </label>
                        </div>
                        <div class="custom-control custom-control-filter p-0 custom-checkbox">
                          <input
                            type="checkbox"
                            id="Ready for cook"
                            name="tyreFrontWidth"
                            value="Ready for cook"
                          />
                          <label className="ml-1" for="Ready for cook">
                            Ready for cook
                          </label>
                        </div>
                        <div class="custom-control custom-control-filter p-0 custom-checkbox">
                          <input
                            type="checkbox"
                            id="Cook with fortune"
                            name="tyreFrontWidth"
                            value="Cook with fortune"
                          />
                          <label className="ml-1" for="Cook with fortune">
                            Cook with fortune
                          </label>
                        </div>
                        <div class="custom-control custom-control-filter p-0 custom-checkbox">
                          <input
                            type="checkbox"
                            id="Changing fortune"
                            name="tyreFrontWidth"
                            value="Changing fortune"
                          />
                          <label className="ml-1" for="Changing fortune">
                            Changing fortune
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card border">
                    <div class="card-header" id="headingSeven">
                      <a
                        class="collapsed mr-2"
                        data-toggle="collapse"
                        href="#collapseEight"
                        aria-expanded="false"
                        aria-controls="collapseEight"
                      >
                        DISCOUNT
                      </a>
                      <i
                        class="fas fa-chevron-down fas-chevron"
                        aria-hidden="true"
                      ></i>
                    </div>
                    <div
                      id="collapseEight"
                      class="collapse p-2"
                      aria-labelledby="headingSeven"
                      data-parent="#accordionExample"
                    >
                      <input
                        id="exampleFormControlInput06"
                        type="email"
                        placeholder="Search By Discount"
                        class="form-control form-control-search form-control-underlined"
                      />
                      <div class="p-2">
                        <div class="custom-control custom-control-filter p-0 custom-checkbox">
                          <input
                            type="checkbox"
                            id="Oils"
                            name="tyreFrontWidth"
                            value="Oils"
                          />
                          <label id="discount_1" className="ml-1" for="Oils">
                            5% - 10%
                          </label>
                        </div>
                        <div class="custom-control custom-control-filter p-0 custom-checkbox">
                          <input
                            type="checkbox"
                            id="Oils"
                            name="tyreFrontWidth"
                            value="Oils"
                          />
                          <label id="discount_2" className="ml-1" for="Oils">
                            10% - 15%
                          </label>
                        </div>
                        <div class="custom-control custom-control-filter p-0 custom-checkbox">
                          <input
                            type="checkbox"
                            id="Oils"
                            name="tyreFrontWidth"
                            value="Oils"
                          />
                          <label id="discount_3" className="ml-1" for="Oils">
                            15% - 20%
                          </label>
                        </div>
                        <div class="custom-control custom-control-filter p-0 custom-checkbox">
                          <input
                            type="checkbox"
                            id="Oils"
                            name="tyreFrontWidth"
                            value="Oils"
                          />
                          <label id="discount_4" className="ml-1" for="Oils">
                            20% - 25%
                          </label>
                        </div>
                        <div class="custom-control custom-control-filter p-0 custom-checkbox">
                          <input
                            type="checkbox"
                            id="Oils"
                            name="tyreFrontWidth"
                            value="Oils"
                          />
                          <label id="discount_5" className="ml-1" for="Oils">
                            25% - 30%
                          </label>
                        </div>
                        <div class="custom-control custom-control-filter p-0 custom-checkbox">
                          <input
                            type="checkbox"
                            id="Oils"
                            name="tyreFrontWidth"
                            value="Oils"
                          />
                          <label id="discount_6" className="ml-1" for="Oils">
                            30% - 35%
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card border">
                    <div class="card-header" id="headingSeven">
                      <a
                        class="collapsed mr-2"
                        data-toggle="collapse"
                        href="#collapseSeven"
                        aria-expanded="false"
                        aria-controls="collapseSeven"
                      >
                        PRICE
                      </a>
                      <i
                        class="fas fa-chevron-down fas-chevron"
                        aria-hidden="true"
                      ></i>
                    </div>
                    <div
                      id="collapseSeven"
                      class="collapse p-2"
                      aria-labelledby="headingSeven"
                      data-parent="#accordionExample"
                    >
                      <input
                        id="exampleFormControlInput06"
                        type="email"
                        placeholder="Search By Price"
                        class="form-control form-control-search form-control-underlined"
                      />
                      <div class="p-2">
                        <div class="custom-control custom-control-filter p-0 custom-checkbox">
                          <input
                            type="checkbox"
                            id="Oils"
                            name="tyreFrontWidth"
                            value="Oils"
                          />
                          <label className="ml-1" id="price_01" for="km_a">
                            50 - 100
                          </label>
                        </div>
                        <div class="custom-control custom-control-filter p-0 custom-checkbox">
                          <input
                            type="checkbox"
                            id="Oils"
                            name="tyreFrontWidth"
                            value="Oils"
                          />
                          <label className="ml-1" id="price_02" for="km_b">
                            100 - 150
                          </label>
                        </div>
                        <div class="custom-control custom-control-filter p-0 custom-checkbox">
                          <input
                            type="checkbox"
                            id="Oils"
                            name="tyreFrontWidth"
                            value="Oils"
                          />
                          <label className="ml-1" id="price_03" for="km_c">
                            150 - 200
                          </label>
                        </div>
                        <div class="custom-control custom-control-filter p-0 custom-checkbox">
                          <input
                            type="checkbox"
                            id="Oils"
                            name="tyreFrontWidth"
                            value="Oils"
                          />
                          <label className="ml-1" id="price_04" for="km_d">
                            200 - 250
                          </label>
                        </div>
                        <div class="custom-control custom-control-filter p-0 custom-checkbox">
                          <input
                            type="checkbox"
                            id="Oils"
                            name="tyreFrontWidth"
                            value="Oils"
                          />
                          <label className="ml-1" id="price_05" for="km_e">
                            250 - 300
                          </label>
                        </div>
                        <div class="custom-control custom-control-filter p-0 custom-checkbox">
                          <input
                            type="checkbox"
                            id="Oils"
                            name="tyreFrontWidth"
                            value="Oils"
                          />
                          <label className="ml-1" id="price_6" for="km_e">
                            300 - 350
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
										{/* </div>
                  </div> */}
										<div className="col-md-12">
											<div className="row m-0">
												{this.state.products !== undefined
													? this.state.products.map((product) => (
															<div class="col-md-3 product-link mb-3">
																<div class="px-2 product-link my-1">
																	<div className="product-card card border h-100 shadow-sm d-flex flex-column">
																		<Link
																			to={`/product-details/${product.Product.id}`}
																		>
																			<img
																				className="mx-5"
																				src={`http://s3-bucket-asia-2020.s3.amazonaws.com/productimages/${product.Product.sku}/${product.Product.sku}.main.png`}
																				alt={product.Product.name}
																				style={{ width: '50%' }}
																				onError={(ev) =>
																					(ev.target.src =
																						'./images/logo1.png')
																				}
																			/>
																			<div className="mt-3 w-100">
																				<h5 className="product-title">
																					{product.Product.name}
																				</h5>
																				<div className="row m-0">
																					<div className="col-md-12">
																						<p className="m-0 text-left display-flex">
																							<h5 className="text-dark float-left ">
																								&#8377;{' '}
																								{product.SP_customer.toFixed(
																									2
																								)}
																							</h5>
																							<h5 className="">
																								<strike class="text-secondary ml-3">
																									&#8377;{' '}
																									{product.MRP.toFixed(
																										2
																									)}
																								</strike>
																							</h5>

																							<span className="text-left text-primary text-success">
																								Save &#8377;{' '}
																								{product.Product_flat_discount.toFixed(
																									2
																								)}
																							</span>
																						</p>
																					</div>
																				</div>
																			</div>
																		</Link>
																		<div className="d-flex justify-content-between mt-3">
																			<div class="form-group m-0">
																				<div class="input-group mb-0">
																					<div class="input-group-prepend">
																						<span
																							class="input-group-text cursor-pointer"
																							onClick={this.lessQuantity.bind(
																								this,
																								product.id
																							)}
																						>
																							-
																						</span>
																					</div>
																					<input
																						class="form-control quantity-text"
																						value={product.qty}
																					/>
																					<div class="input-group-append">
																						<span
																							class="input-group-text cursor-pointer"
																							onClick={this.addQuantity.bind(
																								this,
																								product.id
																							)}
																						>
																							+
																						</span>
																					</div>
																				</div>
																			</div>
																			<button
																				onClick={this.addToCart.bind(
																					this,
																					product
																				)}
																				className="btn btn-outline-primary text-uppercase ml-2"
																			>
																				Add
																			</button>
																		</div>
																		<div className="fav-icon">
																			{product.Product.isFavorite ? (
																				<i
																					className="fa fa-heart text-danger"
																					onClick={this.onUnFavourite.bind(
																						this,
																						product.Product.id
																					)}
																				></i>
																			) : (
																				<i
																					className="fa fa-heart-o text-danger"
																					onClick={this.onFavourite.bind(
																						this,
																						product.Product.id
																					)}
																				></i>
																			)}
																		</div>
																		<div className="discount-icon">
																			<i className="fa fa-certificate text-primary"></i>
																			<p className="text-white">
																				{Math.round(
																					product.Product_percentage_discount
																				)}
																				%
																			</p>
																		</div>
																	</div>
																</div>
															</div>
													  ))
													: ''}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					) : (
						<div className="d-flex justify-content-center align-items-center p-5 flex-column">
							<img
								className="mt-5"
								src="https://www.flaticon.com/svg/static/icons/svg/1376/1376786.svg"
								alt=""
								style={{ width: '100px' }}
							/>
							<h5 className="mt-3 pb-5 text-primary">No product found!</h5>
						</div>
					)

					// ) : (
					//   <LoadingOverlay
					//     active={true}
					//     text={<Loading></Loading>}
					//   ></LoadingOverlay>
					// )
				}
			</div>
		);
	}
}

var mapStateToProps = (state) => ({
	home: state.home,
	product: state.product,
});

ProductList.propsTypes = {
	loginUser: PropTypes.func.isRequired,
	getProductsList: PropTypes.func.isRequired,
	home: PropTypes.object.isRequired,
	product: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, {
	getProductsList,
	addToCart,
	favourite,
	unFavourite,
})(ProductList);
