import React, { Component } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  addAddress,
  updateAddress,
  deleteAddress,
  enterPincode
} from "../../../actions/homeAction";

class AddressModal extends Component {
  state = {
    label:"Home"
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.addressDetails) {
      if(nextProps.addressDetails.addressId !== this.state.addressId)
      this.setState({
        addressId: nextProps.addressDetails.addressId,
        mobile: nextProps.addressDetails.mobile,
        name: nextProps.addressDetails.name,
        road: nextProps.addressDetails.road,
        landmark: nextProps.addressDetails.landmark,
        PinCodeId: nextProps.addressDetails.PinCodeId,
        lat: nextProps.addressDetails.lat,
        long: nextProps.addressDetails.long,
        label: nextProps.addressDetails.label === undefined ? "Home" :  nextProps.addressDetails.label,
        address_line_1: nextProps.addressDetails.address_line_1,
        address_line_2: nextProps.addressDetails.address_line_2,
        alternate_mobile: nextProps.addressDetails.alternate_mobile,
        pincode:nextProps.addressDetails.pincode,
        city: nextProps.addressDetails.city,
        state: nextProps.addressDetails.state
      });
    }

    if(nextProps.home.pincodeDetails){
      var pincodeDetails = nextProps.home.pincodeDetails 
      this.setState({
        PinCodeId:pincodeDetails.id,
        city:  `${pincodeDetails.area} - ${pincodeDetails.City.name}`,
        state : pincodeDetails.City.State.name
      })
    }
  }

  onAddAddress = () => {
    var data = {
      mobile: this.state.mobile,
      name: this.state.name,
      road: this.state.road,
      landmark: this.state.landmark,
      PinCodeId: this.state.PinCodeId,
      lat: this.state.lat,
      long: this.state.long,
      label: this.state.label,
      address_line_1: this.state.address_line_1,
      address_line_2: this.state.address_line_2,
      alternate_mobile: this.state.alternate_mobile,
    };
    this.props.addAddress(data);
  };

  onUpdateAddress = () => {
    var data = {
      addressId: this.state.addressId,
      mobile: this.state.mobile,
      name: this.state.name,
      road: this.state.road,
      landmark: this.state.landmark,
      PinCodeId: this.state.PinCodeId,
      lat: this.state.lat,
      long: this.state.long,
      label: this.state.label,
      address_line_1: this.state.address_line_1,
      address_line_2: this.state.address_line_2,
      alternate_mobile: this.state.alternate_mobile,
    };
    this.props.updateAddress(data);
  };

  onDeleteAddress = () => {
    this.props.deleteAddress(this.state.addressId);
  };

  onSelectLabel = (label) => {
    this.setState({ label: label });
  };

  onInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onCloseModal = () => {
    this.props.close();
  };
  onPincodeChange = (e) => {
    this.setState({pincode:e.target.value})
    var data = {
          code: e.target.value,
        };
    if(e.target.value.length === 6){
      this.props.enterPincode(data)
    }
    
  }
  render() {
    const { open } = this.props;
    return (
      <div>
        <Modal
          classNames={{
            modal: "p-0",
          }}
          open={open}
          onClose={this.onCloseModal}
          center
        >
          <h5 className="box-title border-bottom p-3 text-primary text-uppercase">
            {this.props.addingAddress ? "Add Address" : "Edit Address"}
          </h5>

          <div className="card-body">
            <div>
              <h3>Select Address Type</h3>

              <fieldset class="form-group d-flex">
                <div class="form-check mr-3">
                  <label class="form-check-label">
                    <input
                      type="radio"
                      class="form-check-input"
                      name="Home"
                      id="optionsRadios1"
                      onClick={this.onSelectLabel.bind(this, "Home")}
                      checked={this.state.label === "Home" ? true : false}
                    />
                    Home
                  </label>
                </div>
                <div class="form-check mr-3">
                  <label class="form-check-label">
                    <input
                      type="radio"
                      class="form-check-input"
                      name="Work"
                      id="optionsRadios1"
                      onClick={this.onSelectLabel.bind(this, "Work")}
                      checked={this.state.label === "Work" ? true : false}
                    />
                    Work
                  </label>
                </div>
                <div class="form-check mr-3">
                  <label class="form-check-label">
                    <input
                      type="radio"
                      class="form-check-input"
                      name="Other"
                      id="optionsRadios1"
                      onClick={this.onSelectLabel.bind(this, "Other")}
                      checked={this.state.label === "Other" ? true : false}
                    />
                    Other
                  </label>
                </div>
              </fieldset>

              {/* <fieldset class="form-group">
                <div class="form-check mr-3">
                  <label class="form-check-label">
                    <input class="form-check-input" type="checkbox" />
                    Use as default address
                  </label>
                </div>
              </fieldset> */}

              <div class="form-group">
                <div class="inputbox">
                  <input
                    type="text"
                    placeholder="Name"
                    class="form-control"
                    name="name"
                    
                    value={this.state.name}
                    onChange={this.onInputChange}
                  />
                </div>
              </div>

              <div class="form-group">
                <div class="inputbox">
                  <input
                    type="text"
                    placeholder="Mobile No."
                    class="form-control"
                    name="mobile"
                    
                    value={this.state.mobile}
                    onChange={this.onInputChange}
                  />
                </div>
              </div>

              <div class="form-group">
                <div class="inputbox">
                  <input
                    type="text"
                    placeholder="Alternate Mobile No."
                    class="form-control"
                    name="alternate_mobile"
                    
                    value={this.state.alternate_mobile}
                    onChange={this.onInputChange}
                  />
                </div>
              </div>
              <hr />
              <div class="my-cart-items">
                <div className="row m-0">
                  <div className="col-md-6">
                    <div class="form-group">
                      <div class="inputbox">
                        <input
                          type="text"
                          placeholder="Pincode"
                          class="form-control"
                          name="pincode"
                          
                          value={this.state.pincode}
                          onChange={this.onPincodeChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div class="form-group">
                      <div class="inputbox">
                        <input
                          type="text"
                          placeholder="State"
                          class="form-control"
                          name="state"
                          
                          value={this.state.state}
                       onChange={this.onInputChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="inputbox">
                    <input
                      type="text"
                      placeholder="House No., Building Name*"
                      class="form-control"
                      name="address_line_1"
                      
                      value={this.state.address_line_1}
                      onChange={this.onInputChange}
                    />
                  </div>
                </div>

                <div class="form-group">
                  <div class="inputbox">
                    <input
                      type="text"
                      placeholder="Road Name, Area, Colony*"
                      class="form-control"
                      name="address_line_2"
                      
                      value={this.state.address_line_2}
                      onChange={this.onInputChange}
                    />
                  </div>
                </div>

                <div class="form-group">
                  <div class="inputbox">
                    <input
                      type="text"
                      placeholder="City"
                      class="form-control"
                      name="city"
                      
                      value={this.state.city}
                       onChange={this.onInputChange}
                    />
                  </div>
                </div>

                <div class="form-group">
                  <div class="inputbox">
                    <input
                      type="text"
                      placeholder="Landmark ( Optional )"
                      class="form-control"
                      name="landmark"
                      
                      value={this.state.landmark}
                      onChange={this.onInputChange}
                    />
                  </div>
                </div>

                <div class="change-div">
                  {this.props.addingAddress ? (
                    <button
                      class="btn btn-primary mr-1"
                      onClick={this.onAddAddress}
                    >
                      ADD
                    </button>
                  ) : (
                    <div>
                      <button
                        class="btn btn-primary mr-1"
                        onClick={this.onUpdateAddress}
                      >
                        UPDATE
                      </button>
                      <a onClick={this.onDeleteAddress} class="btn btn-dark">
                        DELETE
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

AddressModal.propsTypes = {
  verifyUser: PropTypes.func.is
  // home: PropTypes.object.is
};

var mapStateToProps = (state) => ({
  user: state.user,
  home:state.home
});

export default connect(mapStateToProps, {
  addAddress,
  updateAddress,
  deleteAddress,
  enterPincode
})(AddressModal);
