import React, { Component } from "react";

export default class CancellationRefund extends Component {
  render() {
    return (
      <div className="container py-5">
        <div class="header heading">CANCELLATION, RETURN AND EXCHANGE</div>
        <div class="bodytext">
          <ol>
            <li>An order placed and confirmed cannot be cancelled.</li>
            <li>
              You are entitled to a 100% replacement of order in case you
              received something else than what you ordered or your order
              reached you in a distorted manner.
            </li>
            <li>
              If purchase of the Product is made under a special promotional
              offer or any special scheme, special rules shall apply for
              replacement.
            </li>
            <li>
              Replacement process must be initiated within 24 hours from the
              time of receipt of order by the User. The Product must be in an
              unused state before seeking a for a replacement and should be
              accompanied by a copy of the original receipt or invoice issued at
              the time of sale of the Product.
            </li>
            <li>
              Any request for replacement will be accepted only after inspection
              of the Product. Once the replacement request is placed with the
              customer service, the logistics partner will arrange for a pickup
              of the Product from the address provided at the time of placing
              the order. Once the Product is received, it takes about 1 working
              day for inspection team to confirm on the replacement. On
              confirmation of the genuine need for replacement of the Product,
              we shall replace the desired Product with the User in 3 working
              days.
            </li>
            <li>
              At every step of the replacement process, we shall keep you
              updated through emails and/or SMS. However, if you have any
              queries before, during or after the replacement is completed, feel
              free to contact us at:{" "}
              <a href="mailto:care@adaniwilmar.in" target="_blank">
                care@adaniwilmar.in.
              </a>
            </li>
            <li>
              Refunds will not be made under any circumstances and only
              replacement in relevant situations will be done.
            </li>
          </ol>
        </div>
      </div>
    );
  }
}
