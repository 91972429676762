import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getTopProductsList } from "../../../actions/productAction";
import { addToCart } from "../../../actions/homeAction";
import LoadingOverlay from "react-loading-overlay";
import Loading from "../common/Loading";

class PrivacyPolicy extends Component {
  state = {};

  // componentDidMount() {
  //   this.props.getTopProductsList();
  // }
  // componentWillReceiveProps(nextProps) {
  //   this.setState({ topProducts, loading: nextProps.home.loading });
  // }

  render() {
    return (
      <div>
        <LoadingOverlay
          active={this.state.loading}
          text={<Loading></Loading>}
        ></LoadingOverlay>
       <div className="container py-5">
        <div class="header heading text-uppercase">Privacy Policy</div>
        <div class="bodytext">

        <br/><br/><h5 className="text-primary">SCOPE</h5><br/> 


This website ("Site") is owned and operated by Adani Wilmar Limited, ("Adani Wilmar"). Adani Wilmar takes your privacy seriously. This Privacy Policy describes: -
<ol><li>the types of information that Adani Wilmar may collect from you when you access or use its websites, applications and other online services (collectively, referred as "Services"); and</li>
<li>its practices for collecting, using, maintaining, protecting and disclosing that information</li></ol>




By using this Site, you consent to the terms of this Privacy Policy as well as the Site Terms and Conditions. Adani Wilmar may change this Privacy Policy from time to time. Please check back periodically for up-to-date information about our privacy practices.
<br/><br/>
Our Privacy Policy practices are consistent with the Information Technology Act, 2000 and amendments as and when notified subject to its applicability.  This Adani Wilmar Company is referred to in this Privacy Policy as “Adani Wilmar,” “we,” “us,” and “our”.

<br/><br/><h5 className="text-primary">TERMS OF USE </h5>
Your use of this portal is also subject to the Terms of Use posted on this web-portal ("Terms of Use").

<br/><br/><h5 className="text-primary">INFORMATION COVERED BY THIS PRIVACY POLICY</h5>

This Privacy Policy covers the “Personal Information” we collect through our Sites.

“Personal Information” is information that identifies, relates to, describes, is reasonably capable of being associated with, or could reasonably be linked, directly or indirectly, with you personally, either alone or in combination with other information available to us. Examples of Personal Information include your name, contact details, credit card information, information you provide when you create an account or a personal profile on one of our Sites and information about the pages you have viewed and the path you have taken through our Sites.

<br/><br/><h5 className="text-primary">INFORMATION WE COLLECT</h5>

We may collect Personal information on this Website voluntarily submitted by the user, including information we collect while performing business transactions and information we collect from other sources (as permitted by law).for the performance of a contract with you or where it is necessary for our legitimate interests of operating, protecting and improving our business.
<br/><br/>
<h6 className="text-primary"> Information collected directly</h6>
We may collect information from you directly when you provide us with personal information, e.g. when you register for prize draws or competitions, sign up to receive information, use applications, buy a product or service from us, fill out a survey, or make a comment or enquiry. The following are examples of information we may collect directly: <br/>
a) Name, email address, postal address <br/>
b) Username and password<br/>
c) Phone number or mobile number<br/>
d) Photo identity proof<br/>
e) Date of birth<br/>
f) Demographic information and service history<br/>
g) Payment instrument details (such as a credit card information, credit history, security codes)<br/>
h) Future communication preferences<br/>
i) Other household information about you and your family such as gender or product use preferences and/or behaviours.<br/>
j) Health information, such as health information related to product usage and medical history.<br/>
k) Any information necessary for perform sales transaction.<br/><br/>

<h6 className="text-primary">Information collected automatically</h6>
We (and third party service providers acting on our behalf) may use cookies and other tools (such as web analytic tools, pixel tags, web beacon, javascript etc) to automatically collect information about you when you use this website or Adani Wilmar Sites, subject to the terms of this Privacy Notice and applicable data laws and regulations. The types of information collected automatically may include but not limited to such as below: <br/>
a) details of the web pages you have viewed <br/>
b) information about the type of browser you use <br/>
c) the hyperlinks you have clicked <br/>
d) your IP address <br/>
e) your username, profile picture, gender, networks and any other information you choose to share when using Third Party Sites (such as when you use the “Like” functionality on Facebook or the +1 functionality on Google+) <br/>
f) the websites you visited before arriving at Adani Wilmar Website or this site. <br/>

<br/><br/><h5 className="text-primary">HOW WE USE THIS INFORMATION</h5>
Personal information collected from data subjects listed in this portal will only be kept in order to conduct the required business, and for as long as the requirement was deemed at the time of data collection.
The information collected may be used for the following activities:
<ul>
<li> to provide the products, information and services you request;</li>
<li> to provide you with effective customer service;</li>
<li> Create and manage information for master maintenance </li>
<li> Process payment for purchases or other services</li>
<li> Protect against or identify possible fraudulent transactions</li>
<li> Enforce the terms and conditions of agreements, and otherwise lawfully manage our business.</li>
<li> suggest products or services (including those of relevant third parties) which we think may be of interest to you</li>
<li> Provide you with customer support.</li>
<li> Offer you the opportunity to take part in competitions or promotions</li>
<li> For the purposes of competitions or promotions that you have entered</li>
<li> To send you information, products or samples that you have requested</li>
<li> To respond to your queries or comments</li>
<li> Evaluate the use of the Adani Wilmar Web sites, products and services</li>
<li> Analyse the effectiveness of our advertisements, competitions and promotions</li>
<li> Personalise your website experience, as well as to evaluate (anonymously and in the aggregate) statistics on website activity, such as what time you visited it, whether you’ve visited it before and what site referred you to it</li>
<li> Help speed up your future activities and experience on the Adani Wilmar Website. For example, a site can recognise that you have provided your personal information and will not request the same information a second time</li>
<li> Generate and review reports and data about, and to conduct research on, our user base and Service usage patterns.</li>
<li> Administer our Services and diagnose technical problems.</li>
<li> to improve our products and services;</li>
<li> to improve our marketing and promotional efforts; and</li>
<li> For any other purpose related to transaction between You and Us.</li>
</ul>

<br/><br/><h5 className="text-primary">ACCESS TO INFORMATION BY RELEVANT PERSONS</h5>
All personal information must be accurate, complete, and relevant for the business purposes of collection of such information, and necessary only to facilitate those purposes. Your access to and use of this web-portal and its contents (the "Portal") is subject to the terms and conditions of this Privacy Policy, and all applicable laws. By accessing and using this Portal, you (the "User" or "you") accept and agree to these without any limitation or qualification.

<br/><br/><h5 className="text-primary">INFORMATION SHARING </h5>
 We share personal information within Adani Wilmar, and externally only as described below: <br/>
 a)Third-party service providers – We may share your information with outside vendors that we use for a variety of purposes, such as to send you communications via emails, messages or tele-call to inform you about our products that may be of interest to you, push notifications to your mobile device on our behalf, provide voice recognition services to process your spoken queries and questions, help us analyze use of our Services, and process and collect payments. Some of our products, services and databases are hosted by third party hosting services providers. We also may use vendors for other projects, such as conducting surveys or organizing sweepstakes for us. We may share information about you with these vendors only to enable them to perform their services.<br/>
b) Legal action and obligations - We may disclose Personal information:  <br/>
<ul> <li>Where permitted by law, to protect and defend the rights and property of Adani Wilmar (including enforcement of valid agreements).</li>

<li>When required by law or public authorities.</li>

</ul>
c) Social Networks. If you interact with social media features on our Services, such as the Facebook Like button, or use your social media credentials to log-in or post content, these features may collect information about your use of the Services, as well as post information about your activities on the social media service. Your interactions with social media companies are governed by their privacy policies. <br/>
d) To our subsidiaries and affiliates, which are entities under common ownership or control of our ultimate parent company Adani. <br/>
e) To contractors, advertisers/service providers and other third-parties whom we use to support our business (e.g. Distributors, food delivery providers, e-commerce companies) and who are bound by contractual obligations to keep personal information confidential and use it only for the purposes for which we disclose it to them. <br/>
 
<br/><br/><h5 className="text-primary">INFORMATION SECURITY</h5>

We have implemented appropriate physical, electronic, and managerial procedures to safeguard and help prevent unauthorized access to your information and to maintain data security. These safeguards take into account the sensitivity of the information that we collect, process and store and the current state of technology. We follow generally accepted industry standards to protect the Personal information submitted to us, both during transmission and once we receive it. The third-party service providers with respect to payment gateway and payment processing are all validated as compliant with the payment card industry standard (generally referred to as PCI compliant service providers). <br/><br/>
 
We assume no liability or responsibility for disclosure of your information due to errors in transmission, unauthorized third-party access, or other causes beyond our control. You play an important role in keeping your personal information secure. You should not share your username, password, or other security information for your account with anyone. If we receive instructions using your username and password, we will consider that you have authorized the instructions. <br/><br/>

The Services may contain links to third-party websites. Your use of these features may result in the collection, processing or sharing of information about you, depending on the feature. Please be aware that we are not responsible for the content or privacy practices of other websites or services which may be linked on our services. We do not endorse or make any representations about third-party websites or services. Our Privacy Policy does not cover the information you choose to provide to or that is collected by these third parties. We strongly encourage you to read such third parties' privacy policies. <br/><br/>

<br/><br/><h5 className="text-primary">CHANGES TO OUR PRIVACY NOTICE</h5>
We will post changes to this privacy notice and update the effective date when this Privacy Policy is updated. We may modify this Privacy Policy at any time, in our sole discretion and all modifications will be effective immediately upon our posting of the modifications. Continued use of website, following notice of such changes shall indicate your acknowledgement of such changes and agreement to be bound by the terms and conditions of such changes.

<br/><br/><h5 className="text-primary">CONTACT US</h5>
If you have questions, concerns or grievance regarding Adani Wilmar’s privacy practices, please contact the Grievance Officer. The Grievance Officer shall respond to valid information requests and complaints within the statutory timeframe.<br/><br/>

In accordance with Information Technology Act 2000 and rules made there under, the name and contact details of the Grievance Officer are provided below:<br/><br/>
 
Grievance Officer Name	:            Mr. Ashish Bhardwaj<br/>
 
Company Address	Adani Wilmar Limited, Fortune House, Nr Navrangpura Rly Crossing, Ahmedabad 380 009, Gujarat, India<br/>
Email address: pp.grievance@adaniwilmar.in<br/>
Contact telephone number: 079-25558919<br/>
 
<br/><br/>
[from: 9:30 AM to 5:30 PM from Monday to Friday except Public, National & Festival Holidays]<br/>
 

<br/><br/><h5 className="text-primary">CONSENT </h5>
I acknowledge and agree to the terms contained in this data privacy notice, which shall be effective for the entire duration of my relationship with Adani Wilmar as a user of this website and until Adani Wilmar retains records of the captured Personal information as specified above.
<br/><br/>
By using Adani Wilmar owned site, you are consenting to the relevant Adani Wilmar group companies collecting, using and disclosing your Personal information in accordance with this Privacy Notice. If you do not agree to the collection, use and disclosure of your Personal information in this way, you are free not to use this website or otherwise provide Adani Wilmar with your Personal information.









        </div>
      </div>
      </div>
    );
  }
}

PrivacyPolicy.propsTypes = {
  loginUser: PropTypes.func.isRequired,
  home: PropTypes.object.isRequired,
};

var mapStateToProps = (state) => ({
  home: state.home,
});

export default connect(mapStateToProps, { getTopProductsList, addToCart })(
  PrivacyPolicy
);
