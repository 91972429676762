import React, { Component } from 'react';
import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

import LoadingOverlay from 'react-loading-overlay';
import Loading from '../common/Loading';

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { wishlist, favourite, unFavourite, addToCart, getProductsList } from '../../../actions/homeAction';

class Favourite extends Component {
	state = { quantity: 1, type: 'CAR', show: true };

	componentDidMount() {
		this.props.wishlist();

		var data;
		if (this.props.category && this.props.category.id) {
			data = {
				CategoryId: this.props.category.id,
				pinCode: localStorage.getItem('btbpincode'),
			};
		} else {
			data = {
				pinCode: localStorage.getItem('btbpincode'),
			};
		}

		this.props.getProductsList(data);
	}
	componentWillReceiveProps(nextProps) {
		const wishlist = nextProps.home.wishlist;
		if (wishlist) {
			for (let i = 0; i < wishlist.length; i++) {
				wishlist[i].qty = 1;
			}
		}
		this.setState({ wishlist, loading: nextProps.home.loading });

		const products = nextProps.home.productsList;
		if (products) {
			for (let i = 0; i < products.length; i++) {
				products[i].qty = 1;
			}
			if (products.length > 0) {
				this.setState({ loading: false });
			}
		}
		this.setState({
			products,
		});
	}

	addQuantity = (id) => {
		const wishlist = this.state.wishlist;
		for (let i = 0; i < wishlist.length; i++) {
			if (id === wishlist[i].id) {
				if (wishlist[i].qty) {
					wishlist[i].qty = wishlist[i].qty + 1;
				} else {
					wishlist[i].qty = 1;
				}
			}
		}

		this.setState({ wishlist });
	};
	lessQuantity = (id) => {
		const wishlist = this.state.wishlist;
		for (let i = 0; i < wishlist.length; i++) {
			if (id === wishlist[i].id) {
				if (wishlist[i].qty > 1) {
					if (wishlist[i].qty) {
						wishlist[i].qty = wishlist[i].qty - 1;
					} else {
						wishlist[i].qty = 1;
					}
				}
			}
		}

		this.setState({ wishlist });
	};
	addToCart = (product) => {
		var data = {
			userId: localStorage.getItem('btbuserId'),
			pinCodeId: product.PCodeId,
			productId: product.Product.id,
			sellerId: product.Seller.id,
			qty: product.qty,
			price_when_added: product.SP_customer,
			after_offer_price: product.SP_customer,
		};
		this.props.addToCart(data);
	};

	onFavourite = (id) => {
		var data = {
			productId: id,
		};
		this.props.favourite(data);
	};

	onUnFavourite = (id) => {
		var data = {
			productId: id,
		};
		this.props.unFavourite(data);
	};
	onSelectType = (product, e) => {
		this.setState({ type: e.target.value, selectedProductId: product.ProductId });
		this.props.onSelectType(e.target.value);
	};

	render() {
		return (
			<div>
				<LoadingOverlay active={this.state.loading} text={<Loading></Loading>}></LoadingOverlay>
				<h5 className="bg-primary text-white p-2">
					<Link to="/">
						<i className="fa fa-home"></i> Home{' '}
					</Link>{' '}
					<i className="fa fa-chevron-right mx-3"></i> Favourites
				</h5>
				<div className="py-5">
					<div className="container">
						<div class="row d-flex justify-content-between">
							<div class="col-md-3 dashboard-left mb-5">
								<div class="checkout-sub">
									<div className="list-group shadow-sm">
										<Link to="/account" className="list-group-item product-link">
											My Profile
										</Link>
										<Link to="/order" className="list-group-item product-link">
											My Orders
										</Link>
										<Link to="/address" className="list-group-item product-link">
											Address Book
										</Link>

										<Link
											to="/favourite"
											className="list-group-item product-link text-white bg-primary"
										>
											Favourites
										</Link>

										<Link
											className="list-group-item product-link cursor-pointer"
											onClick={() => {
												localStorage.clear();
												window.location.href = '#/';
												window.location.reload();
											}}
										>
											Logout
										</Link>
									</div>
								</div>
							</div>
							<div class="col-md-8 checkout-right">
								<div className="row m-0">
									{this.state.products !== undefined && this.state.products.length > 0 ? (
										this.state.products.map(
											(product) =>
												product.Product.isFavorite && (
													<div class="col-md-9 product-link mb-3">
														<div className="product-card card border h-100 shadow-sm d-flex flex-column">
															<div className="row m-0 ">
																<div className="col-md-4 d-flex justify-content-center align-items-center product-card">
																	<img
																		src={`http://s3-bucket-asia-2020.s3.amazonaws.com/productimages/${product.Product.sku}/${product.Product.sku}.main.png`}
																		alt={product.Product.name}
																		style={{ width: '50%' }}
																		onError={(ev) =>
																			(ev.target.src = 'images/logo1.png')
																		}
																	/>

																	<div className="fav-icon">
																		{product.Product.isFavorite ? (
																			<i
																				className="fa fa-heart text-danger"
																				onClick={this.onUnFavourite.bind(
																					this,
																					product.Product.id
																				)}
																			></i>
																		) : (
																			<i
																				className="fa fa-heart-o text-danger"
																				onClick={this.onFavourite.bind(
																					this,
																					product.Product.id
																				)}
																			></i>
																		)}
																	</div>
																	<div className="discount-icon font-weight-bold">
																		<i className="fa fa-certificate text-primary"></i>
																		<p className="text-white">
																			{product.SellerProduct_UOM.map((type) =>
																				type.name === this.state.type
																					? Math.round(
																							type.percentage_discount
																					  )
																					: null
																			)}
																			%
																		</p>
																	</div>
																</div>
																<div className="col-md-8">
																	<div className="mt-2">
																		<Link
																			to={`/product-details/${product.Product.id}`}
																			className="product-link"
																		>
																			<h5 className="product-title w-100">
																				{product.Product.name}
																			</h5>
																		</Link>
																		<div className="div">
																			<p>
																				Sold By:{' '}
																				<span className="text-primary">
																					{product.Seller.name}
																				</span>
																			</p>
																		</div>
																		<div className="row m-0">
																			<div className="col-md-12">
																				{product.SellerProduct_UOM.map((type) =>
																					product.ProductId ===
																					this.state.selectedProductId
																						? type.name ===
																								this.state.type && (
																								<p
																									className={`m-0 text-left display-flex product-price ${
																										this.state
																											.show ==
																										false
																											? 'blur'
																											: ''
																									}`}
																								>
																									<span className="primary float-left font-weight-bold">
																										&#8377;{' '}
																										{type.PTR.toFixed(
																											2
																										)}
																									</span>
																									<span className="secondary float-left ml-2">
																										<strike class="ml-1">
																											&#8377;{' '}
																											{type.MRP.toFixed(
																												2
																											)}
																										</strike>
																									</span>

																									<span className="success float-right">
																										Save &#8377;
																										{type.flat_discount.toFixed(
																											2
																										)}
																									</span>
																								</p>
																						  )
																						: type.name === 'CAR' && (
																								<p
																									className={`m-0 text-left display-flex product-price ${
																										this.state
																											.show ==
																										false
																											? 'blur'
																											: ''
																									}`}
																								>
																									<span className="primary float-left font-weight-bold">
																										&#8377;{' '}
																										{type.PTR.toFixed(
																											2
																										)}
																									</span>
																									<span className="secondary float-left ml-2">
																										<strike class="ml-1">
																											&#8377;{' '}
																											{type.MRP.toFixed(
																												2
																											)}
																										</strike>
																									</span>

																									<span className="success float-right">
																										Save &#8377;
																										{type.flat_discount.toFixed(
																											2
																										)}
																									</span>
																								</p>
																						  )
																				)}
																			</div>
																		</div>
																	</div>
																	<div className="d-flex justify-content-between mt-3">
																		<select
																			className="form-control"
																			style={{ width: '100px' }}
																			onChange={this.onSelectType.bind(
																				this,
																				product
																			)}
																		>
																			{product.ProductId ===
																			this.state.selectedProductId ? (
																				this.state.type === 'CAR' ? (
																					<>
																						<option value="CAR" selected>
																							Cartoon
																						</option>
																						<option value="EA">
																							Single
																						</option>
																					</>
																				) : (
																					<>
																						<option value="CAR">
																							Cartoon
																						</option>
																						<option value="EA" selected>
																							Single
																						</option>
																					</>
																				)
																			) : (
																				<>
																					<option value="CAR">Cartoon</option>
																					<option value="EA">Single</option>
																				</>
																			)}
																		</select>
																		<div class="form-group m-0">
																			<div class="input-group mb-0">
																				<div class="input-group-prepend">
																					<span
																						class="input-group-text cursor-pointer"
																						onClick={this.lessQuantity.bind(
																							this,
																							product.id
																						)}
																					>
																						-
																					</span>
																				</div>
																				<input
																					class="form-control quantity-text"
																					value={product.qty}
																				/>
																				<div class="input-group-append">
																					<span
																						class="input-group-text cursor-pointer"
																						onClick={this.addQuantity.bind(
																							this,
																							product.id
																						)}
																					>
																						+
																					</span>
																				</div>
																			</div>
																		</div>
																		<button
																			onClick={this.addToCart.bind(this, product)}
																			className="btn btn-outline-primary text-uppercase ml-2"
																		>
																			Add
																		</button>
																	</div>
																</div>
															</div>
														</div>
													</div>
												)
										)
									) : (
										<div className="p-5 text-center mx-auto">No product available</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
var mapStateToProps = (state) => ({
	home: state.home,
	product: state.product,
});

Favourite.propsTypes = {
	loginUser: PropTypes.func.isRequired,
	getProductsList: PropTypes.func.isRequired,
	home: PropTypes.object.isRequired,
	product: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, {
	wishlist,
	favourite,
	unFavourite,
	addToCart,
	getProductsList,
})(Favourite);
