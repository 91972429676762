import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getCategoryList } from '../../../actions/homeAction';

class Footer extends Component {
	state = {};
	componentDidMount() {
		this.props.getCategoryList();
	}
	componentWillReceiveProps(nextProps) {
		this.setState({ categoryList: nextProps.home.categoryList });
	}
	goToCategory = (id) => {
		window.location.href = `#/category/${id}`;
		window.location.reload();
	};
	render() {
		return (
			<div>
				<footer class="site-footer bg-light py-4">
					<div class="container">
						<div class="row">
							<div class="col-sm-6 col-md-5 footer-logo">
								<div className="navbar-brand">
									<img style={{ width: '165px' }} src="./images/logo.png" />
									{/* <div className="beta text-primary">BETA</div> */}
								</div>
								{/* <p className="mt-2 mr-5 pr-5">
									Please note that you are accessing the BETA Version of{' '}
									<a href="https://www.fortuneonline.in">www.fortuneonline.in</a> Should you encounter
									any bugs, glitches, lack of functionality, delayed deliveries, billing errors or
									other problems on the beta website, please email us on
									<div className="btn-link"> care@adaniwilmar.in</div>
								</p> */}
							</div>

							<div class="col-xs-6 col-md-2">
								<h5>Menu</h5>
								<ul class="footer-links">
									{this.state.categoryList !== undefined
										? this.state.categoryList.map((category, map) =>
												map < 8 ? (
													<li>
														<Link onClick={this.goToCategory.bind(this, category.id)}>
															{category.name}{' '}
														</Link>
													</li>
												) : (
													''
												)
										  )
										: ''}
								</ul>
							</div>

							<div class="col-xs-6 col-md-3">
								<h5>Quick Links</h5>
								<ul class="footer-links">
									<li>
										<Link to={'/aboutus'}>About Us</Link>
									</li>
									<li>
										<Link to={'/contactus'}>Contact Us</Link>
									</li>
									<li>
										<Link to={'/terms'}>Terms and Conditions</Link>
									</li>
									<li>
										<Link to={'/privacy'}>Privacy Policy</Link>
									</li>
									<li>
										<Link to={'/seller-terms'}>Seller Terms and Conditions</Link>
									</li>
									<li>
										<Link to={'/billing-terms'}>Billing Terms and Conditions</Link>
									</li>
									<li>
										<Link to={'/cancellation-refund'}>Cancellation, Return and Exchange</Link>
									</li>
								</ul>
							</div>
							<div class="col-xs-6 col-md-2">
								<h5>Download App</h5>
								<div className="d-flex align-items-center">
									<a
										href="https://play.google.com/store/apps/details?id=com.fortuneonline.app"
										target="_blank"
										className="float-left mr-2"
									>
										<img
											src="images/qr-code.png"
											alt="google play store"
											style={{ width: '120px' }}
										/>
									</a>
								</div>
								<div className="d-flex justify-content-between flex-column">
									<a
										href="https://play.google.com/store/apps/details?id=com.fortuneonline.app"
										target="_blank"
									>
										<img
											className="py-1  pt-2"
											src="images/google-play-badge.png"
											alt=""
											style={{ width: '120px' }}
										/>
									</a>
									<a href="https://apps.apple.com/in/app/fortune-online/id1545825367" target="_blank">
										<img
											className="py-1"
											src="images/app-store-badge.png"
											alt=""
											style={{ width: '120px' }}
										/>
									</a>
								</div>
							</div>
						</div>
					</div>
					<hr />
					<div class="container">
						<div class="row">
							<div class="col-md-8 col-sm-6 col-xs-12">
								<p class="copyright-text m-0">Copyright &copy; 2020 All Rights Reserved</p>
							</div>
							<div class="col-md-4 col-sm-6 col-xs-12"></div>
						</div>
					</div>
				</footer>
			</div>
		);
	}
}

Footer.propsTypes = {
	loginUser: PropTypes.func.isRequired,
	home: PropTypes.object.isRequired,
};

var mapStateToProps = (state) => ({
	home: state.home,
});

export default connect(mapStateToProps, { getCategoryList })(Footer);
