import React, { Component } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { searchPincode, getCityList } from "../../../actions/homeAction";

class LocationModal extends Component {
  state = {
    open: false,
  };

  componentDidMount() {
    this.props.getCityList();
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.home.cityList) {
      this.setState({ cityList: nextProps.home.cityList });
    }
  }
  onCloseModal = () => {
    this.setState({ open: false });
    this.props.close();
  };
  inputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  changePincode = () => {
    if (this.state.pincode) {
      if (this.state.pincode.length === 6) {
        var data = {
          code: this.state.pincode,
        };

        this.props.searchPincode(data);
      } else {
        Swal.fire({
          title: "Please enter valid pincode!",
          icon: "",
          imageUrl: "./images/spinner.png",
          imageHeight: 100,
          text: "",
          type: "error",

          confirmButtonColor: "#e84200",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });
      }
    } else {
      Swal.fire({
        title: "Please enter valid pincode!",
        icon: "",
        imageUrl: "./images/spinner.png",
        imageHeight: 100,
        text: "",
        type: "error",

        confirmButtonColor: "#e84200",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
    }
  };
  render() {
    const { open } = this.props;
    return (
      <div>
        <Modal
          classNames={{
            modal: "p-0",
          }}
          open={open}
          onClose={this.onCloseModal}
          center
        >
          <h5 className="box-title border-bottom p-3 text-primary">
            <i className="fa fa-map-marker"></i>{" "}
            {localStorage.getItem("btbcity")} -{" "}
            {localStorage.getItem("btbpincode")}
          </h5>

          <div className="box-body">
            <div class="">
              <div class="row m-0">
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <div class="row m-0">
                    <div class="col-sm-12">
                      <label>Default Location</label>
                      <small class="req"> *</small>
                      <div class="form-group">
                        <input
                          id="topicid"
                          name="topic_id"
                          class="form-control"
                          placeholder={localStorage.getItem("btbpincode")}
                          name="pincode"
                          disabled
                        />
                        <span class="section_id_error text-danger"></span>
                      </div>
                    </div>
                    <div class="col-sm-12">
                      <label>Select your city to start shopping</label>
                      <small class="req"> *</small>
                      <div class="form-group">
                        <input
                          id="topicid"
                          name="topic_id"
                          class="form-control"
                          placeholder="Enter your area / pincode"
                          name="pincode"
                          value={this.state.pincode}
                          onChange={this.inputChange}
                        />
                        <span class="section_id_error text-danger"></span>
                      </div>
                    </div>
                    <div class="col-sm-12">
                      <div class="form-group">
                        <select
                          id="lessonid"
                          name="lesson_id"
                          class="form-control"
                        >
                          <option>Select City</option>
                          {this.state.cityList && this.state.cityList.length > 0
                            ? this.state.cityList.map((city) => (
                                <option value={city.id}>{city.name}</option>
                              ))
                            : ""}
                        </select>
                        <input
                          type="hidden"
                          id="class_id"
                          name="class_id"
                          class="form-control"
                          value=""
                        />

                        <span class="section_id_error text-danger"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <hr></hr>

              <button
                class="btn btn-primary float-right mb-3 mr-3 text-uppercase"
                data-loading-text="<i class='fa fa-spinner fa-spin'>
                        </i> Please wait"
                onClick={this.changePincode}
              >
                Update Location
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

LocationModal.propsTypes = {
  verifyUser: PropTypes.func.isRequired,
  // home: PropTypes.object.isRequired,
};

var mapStateToProps = (state) => ({
  user: state.user,
  home: state.home,
});

export default connect(mapStateToProps, { searchPincode, getCityList })(
  LocationModal
);
