import React, { Component } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { sendOtp, verifyOtp, guestUser } from '../../../actions/homeAction';
import OtpModal from './OtpModal';
import Swal from 'sweetalert2';
import ReCAPTCHA from 'react-google-recaptcha';

class RegisterModal extends Component {
	state = {
		open: false,
		isOtpModalOpen: false,
	};

	onCloseModal = () => {
		this.setState({ open: false });
		this.props.close();
	};

	onOpenOtpModal = () => {
		this.setState({ isOtpModalOpen: true });
	};
	onCloseOtpModal = () => {
		this.setState({ isOtpModalOpen: false });
	};
	handleChanges = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	};

	guestuserlogin = (e) => {
		e.preventDefault();
		console.log(navigator.platform);
		this.props.guestUser(navigator.platform);
	};

	onSendOtp = () => {
		var val = this.state.phone;
		if (/^\d{10}$/.test(val)) {
			var data = {
				phone: this.state.phone,
				registeredWith: 'phone',
				refreshToken: '<some random token from fcm>',
				devicePlatform: 'android',
				deviceId: 'SM596',
			};

			localStorage.setItem('btbmobile', this.state.phone);
			this.props.sendOtp(data);

			this.onOpenOtpModal();
		} else {
			Swal.fire({
				title: 'Invalid number; must be ten digits',
				icon: '',
				imageUrl: './images/spinner.png',
				imageHeight: 100,
				text: '',
				type: 'success',
				confirmButtonColor: '#e84200',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
			});

			return false;
		}
	};

	onChange = (value) => {
		console.log('Captcha value:', value);
	};

	render() {
		const { open } = this.props;
		return (
			<div>
				<Modal
					classNames={{
						modal: 'p-0',
					}}
					open={open}
					onClose={this.onCloseModal}
					center
				>
					<div className="row m-0">
						<div className="col-md-12 ">
							<h4 className="box-title border-bottom p-3 text-primary text-uppercase">Login</h4>

							<div className="row mx-3">
								<h5 className="text-primary py-2">Welcome to Fortune Online</h5>
							</div>

							<div className="box-body">
								<div class="">
									<div class="row m-0">
										<div class="col-lg-12 col-md-12 col-sm-12">
											<div class="row m-0">
												<div class="col-sm-12">
													<div class="form-group">
														<input
															name="phone"
															class="form-control"
															placeholder="Mobile No."
															onChange={this.handleChanges}
														/>
														<span class="section_id_error text-danger"></span>
													</div>

													{/* <fieldset class="form-group">
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                />
                                I am a Business Customer
                              </label>
                            </div>
                          </fieldset> */}
												</div>
											</div>
										</div>
									</div>
									<ReCAPTCHA
										sitekey="6LeY8DcbAAAAABeg-VD8a6Df_3qm-BVX521JLHZY"
										onChange={this.onChange}
									/>
									<hr className="mb-0"></hr>

									<div className="p-2 d-flex justify-content-between align-items-center">
										<button
											class="btn btn-primary text-uppercase float-right"
											onClick={this.onSendOtp}
										>
											Sign in using otp
										</button>
									</div>
									{/* <div className="p-2 d-flex justify-content-between align-items-center">
                      <button
                        onClick={this.guestuserlogin}
                        class="btn btn-primary text-uppercase float-right"
                      >
                        Guest User
                      </button>
                    </div> */}
								</div>
							</div>
						</div>
						{/* <div className="col-md-6 border">
              <h5 className="box-title border-bottom p-3 text-primary text-uppercase">
                Register
              </h5>

              <div className="box-body">
                <form>
                  <div class="">
                    <div class="row m-0">
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="row m-0">
                          <div class="col-sm-12">
                            <div class="form-group">
                              <input
                                id="topicid"
                                name="topic_id"
                                class="form-control"
                                placeholder="Username"
                              />
                              <span class="section_id_error text-danger"></span>
                            </div>
                          </div>

                          <div class="col-sm-12">
                            <div class="form-group">
                              <input
                                id="topicid"
                                name="topic_id"
                                class="form-control"
                                placeholder="email"
                              />
                              <span class="section_id_error text-danger"></span>
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <input
                                id="topicid"
                                name="topic_id"
                                class="form-control"
                                placeholder="Phone"
                              />
                              <span class="section_id_error text-danger"></span>
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <input
                                id="topicid"
                                name="topic_id"
                                class="form-control"
                                placeholder="Password"
                              />
                              <span class="section_id_error text-danger"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <hr className="mb-0"></hr>
                    <div className="p-3 d-flex justify-content-between align-items-center">
                      <div
                        className="text-primary cursor-pointer"
                        onClick={this.onOpenOtpModal}
                      >
                        Already Registered!
                      </div>
                      <button
                        type="submit"
                        class="btn btn-primary float-right text-uppercase"
                        id="submit"
                      >
                        Register
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div> */}
					</div>
				</Modal>
				<OtpModal open={this.state.isOtpModalOpen} close={this.onCloseOtpModal}></OtpModal>
			</div>
		);
	}
}

RegisterModal.propsTypes = {
	verifyUser: PropTypes.func.isRequired,
	// home: PropTypes.object.isRequired,
};

var mapStateToProps = (state) => ({
	user: state.user,
});

export default connect(mapStateToProps, { sendOtp, verifyOtp })(RegisterModal);
