import React, { Component } from "react";

export default class Loading extends Component {
  render() {
    return (
      <div>
        <div
          id="preloader"
          className="d-flex justify-content-center align-items-center fade-in bg-white"
          style={{
            width: "100%",
            height: "100vh",
            position: "fixed",
            top: "0px",
            left: "0",
          }}
        >
          <div className="spinner rotating">
            <img
              src="./images/spinner.png"
              style={{ width: "20%", height: "auto" }}
            ></img>
          </div>
        </div>
      </div>
    );
  }
}
