import {
  GET_TOP_PRODUCT_LIST,
  GET_PRODUCTS_LIST,
  GET_PRODUCT_DETAILS,
  SET_LOADING,
} from "../actions/types";

var initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case GET_TOP_PRODUCT_LIST:
      return {
        ...state,
        topProducts: action.payload,
      };
    case GET_PRODUCTS_LIST:
      return {
        ...state,
        products: action.payload,
      };

    case GET_PRODUCT_DETAILS:
      return {
        ...state,
        productDetails: action.payload,
      };
    default:
      return state;
  }
}
