import React, { Component } from 'react';
import { HashRouter as Router, Route, Redirect } from 'react-router-dom';
import Site from '../components/site/Site';
import Home from '../components/site/home/Home';
import ProductList from '../components/site/product/ProductList';
import ProductDetails from '../components/site/product/ProductDetails';
import Cart from '../components/site/product/Cart';
import Checkout from '../components/site/product/Checkout';
import Account from '../components/site/myaccount/Account';
import Address from '../components/site/myaccount/Address';
import Order from '../components/site/myaccount/Order';
import Favourite from '../components/site/myaccount/Favourite';
import ChangePassword from '../components/site/myaccount/ChangePassword';
import CategoryWiseProductList from '../components/site/product/CategoryWiseProductList';
import OfferWiseProductList from '../components/site/product/OfferWiseProductList';
import TermsConditions from '../components/site/home/TermsConditions';
import SellerTermConditions from '../components/site/home/SellerTermConditions';
import BillingTermConditions from '../components/site/home/BillingTermConditions';
import CancellationRefund from '../components/site/home/CancellationRefund';
import AboutUs from '../components/site/home/AboutUs';
import ContactUs from '../components/site/home/ContactUs';
import PrivacyPolicy from '../components/site/home/PrivacyPolicy';
import Kyc from '../components/site/kyc/Kyc';
import BusinessProfile from '../components/site/myaccount/BusinessProfile';
import AccountSettings from '../components/site/myaccount/AccountSettings';

export default class SiteRoutes extends Component {
	render() {
		return (
			<React.Fragment>
				<Site>
					<Route exact path={'/'} component={Home} />
					<Route exact path={'/product-list'} component={ProductList} />
					<Route exact path={'/product-list/:keyword'} component={ProductList} />
					<Route exact path={'/product-details/:productId'} component={ProductDetails} />
					<Route exact path={'/category/:categoryId'} component={CategoryWiseProductList} />
					<Route exact path={'/offers'} component={OfferWiseProductList} />
					<Route exact path={'/cart'} component={Cart} />
					<Route exact path={'/checkout'} component={Checkout} />

					<Route exact path={'/account'} component={Account} />
					<Route exact path={'/business-profile'} component={BusinessProfile} />
					<Route exact path={'/account-settings'} component={AccountSettings} />

					<Route exact path={'/address'} component={Address} />
					<Route exact path={'/order'} component={Order} />
					<Route exact path={'/favourite'} component={Favourite} />
					<Route exact path={'/change-password'} component={ChangePassword} />

					<Route exact path={'/terms'} component={TermsConditions} />
					<Route exact path={'/privacy'} component={PrivacyPolicy} />
					<Route exact path={'/seller-terms'} component={SellerTermConditions} />
					<Route exact path={'/billing-terms'} component={BillingTermConditions} />
					<Route exact path={'/cancellation-refund'} component={CancellationRefund} />
					<Route exact path={'/aboutus'} component={AboutUs} />
					<Route exact path={'/contactus'} component={ContactUs} />
					<Route exact path={'/kyc'} component={Kyc} />
				</Site>
			</React.Fragment>
		);
	}
}
