import React, { Component } from 'react';
import RegisterModal from '../modals/RegisterModal';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getBusinessDetails } from '../../../actions/homeAction';

class KycNotification extends Component {
	state = {
		businessDetails: {},
	};
	onStartKyc = () => {
		if (localStorage.getItem('btbloggedin')) {
			window.location.href = '#/kyc';
		} else {
			this.onOpenRegisterModal();
		}
	};

	componentDidMount() {
		const data = {
			userId: localStorage.getItem('btbuserId'),
		};
		if (localStorage.getItem('btbloggedin')) {
			this.props.getBusinessDetails(data);
		}
	}
	componentWillReceiveProps(nextProps) {
		if (nextProps.home.businessDetails && nextProps.home.businessDetails.length > 0) {
			this.setState({
				businessDetails: nextProps.home.businessDetails[0],
			});
		}
	}

	onOpenRegisterModal = () => {
		this.setState({ isRegisterModalOpen: true });
	};
	onCloseRegisterModal = () => {
		this.setState({ isRegisterModalOpen: false });
	};
	render() {
		return (
			<div>
				<div className="container pt-3">
					<div className="alert alert-warning">
						<div className="d-flex justify-content-between align-items-center">
							<div className="d-flex align-items-center text-primary text-uppercase">
								Complete your kyc
								{this.state.businessDetails.kycStatus === 'KYC approved' && (
									<div className="badge badge-success text-white ml-3">
										{this.state.businessDetails.kycStatus}
									</div>
								)}
								{this.state.businessDetails.kycStatus === 'in progress' && (
									<div className="badge badge-info text-white ml-3">
										{this.state.businessDetails.kycStatus}
									</div>
								)}
								{this.state.businessDetails.kycStatus === 'pending' && (
									<div className="badge badge-danger text-white ml-3">
										{this.state.businessDetails.kycStatus}
									</div>
								)}
							</div>

							{this.state.businessDetails.kycStatus === 'KYC approved' ? (
								<div className="btn btn-success text-uppercase text-white">Completed</div>
							) : this.state.businessDetails.kycStatus === 'in progress' ? (
								<div onClick={this.onStartKyc} className="btn btn-primary text-uppercase">
									Check Status
								</div>
							) : (
								<div onClick={this.onStartKyc} className="btn btn-primary text-uppercase">
									start kyc
								</div>
							)}
						</div>
					</div>
				</div>
				<RegisterModal open={this.state.isRegisterModalOpen} close={this.onCloseRegisterModal}></RegisterModal>
			</div>
		);
	}
}

KycNotification.propsTypes = {
	getBusinessDetails: PropTypes.func.isRequired,
	home: PropTypes.object.isRequired,
};

var mapStateToProps = (state) => ({
	home: state.home,
});

export default connect(mapStateToProps, { getBusinessDetails })(KycNotification);
