export const GET_GUEST_USER = 'GET_GUEST_USER';
export const LOGOUT = 'LOGOUT';

export const GET_PROFILE = 'GET_PROFILE';
export const SET_PROFILE = 'SET_PROFILE';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';

export const SET_LOADING = 'SET_LOADING';
export const GET_LANGUAGE_LIST = 'GET_LANGUAGE_LIST';
export const GET_BANNERS_LIST = 'GET_BANNERS_LIST';

export const GET_CATEGORY_LIST = 'GET_CATEGORY_LIST';
export const GET_MY_CATEGORY_LIST = 'GET_MY_CATEGORY_LIST';

export const GET_ATTRIBUTE = 'GET_ATTRIBUTE';

export const ADD_TO_CART = 'ADD_TO_CART';
export const GET_CART = 'GET_CART';

export const GET_ADDRESS = 'GET_ADDRESS';

export const GET_ORDERLIST = 'GET_ORDERLIST';
export const GET_WISHLIST = 'GET_WISHLIST';

export const GET_CONTACT = 'GET_CONTACT';

export const GET_TOP_PRODUCT_LIST = 'GET_TOP_PRODUCT_LIST';
export const GET_PRODUCTS_LIST = 'GET_PRODUCTS_LIST';
export const GET_PRODUCT_DETAILS = 'GET_PRODUCT_DETAILS';

export const GET_PINCODE_DETAILS = 'GET_PINCODE_DETAILS';
export const GET_CITY_LIST = 'GET_CITY_LIST';

export const GET_SETTINGS = 'GET_SETTINGS';
export const GET_CANCELLATION_LIST = 'GET_CANCELLATION_LIST';

export const GET_COUPON_DETAILS = 'GET_COUPON_DETAILS';
export const GET_REDIRECT = 'GET_REDIRECT';

//BTB
export const GET_BUSINESS_CATEGORY = 'GET_BUSINESS_CATEGORY';
export const GET_BUSINESS_TYPE = 'GET_BUSINESS_TYPE';
export const GET_DOC_TYPE = 'GET_DOC_TYPE';
export const GET_BUSINESS_DETAILS = 'GET_BUSINESS_DETAILS';
