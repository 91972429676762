import React, { Component } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import $ from 'jquery';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { sendOtp, verifyOtp } from '../../../actions/homeAction';
import Countdown from 'react-countdown';
import { Link } from '@material-ui/core';
import Swal from 'sweetalert2';

$('form').submit(function (e) {
	e.preventDefault();
	$('#otp').modal('show');
});

function goToNextInput(e) {
	var key = e.which,
		t = $(e.target),
		sib = t.next('input');
	sib.select().focus();
}

function onFocus(e) {
	$(e.target).select();
}

$('body').on('keyup', 'input', goToNextInput);
$('body').on('click', 'input', onFocus);

class OtpModal extends Component {
	state = {
		timer: 0,
		countdown: Date.now() + 120000,
		open: false,
		isRegisterModalOpen: false,
		checkedTerms: true,
	};

	componentWillReceiveProps(nextProps) {
		if (nextProps.open !== this.props.open) {
			this.setState({ countdown: Date.now() + 120000 });
		}

		this.setState({ currentTime: Date.now() });

		setTimeout(() => {
			this.setState({ showButton: true });
		}, 120000);
	}

	onCloseModal = () => {
		this.setState({ open: false });
		this.props.close();
		this.setState({ countdown: 0 });
	};

	onInputChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	onVerifyOtp = () => {
		if (!this.state.checkedTerms) {
			Swal.fire({
				title: 'Please accept terms and conditions',
				icon: '',
				imageUrl: './images/spinner.png',
				imageHeight: 100,
				text: '',
				type: 'warning',
				confirmButtonColor: '#e84200',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
			});

			return false;
		}
		var otp = `${this.state.num1}${this.state.num2}${this.state.num3}${this.state.num4}${this.state.num5}${this.state.num6}`;

		var data = {
			otp: otp,
			phone: localStorage.getItem('btbmobile'),
			refreshToken: '<some random token from fcm>',
			devicePlatform: 'web',
			deviceId: localStorage.getItem('btbdevice-id'),
		};

		this.props.verifyOtp(data);
	};

	onSendOtp = () => {
		var val = localStorage.getItem('btbmobile');

		var data = {
			phone: val,
			registeredWith: 'phone',
			refreshToken: '<some random token from fcm>',
			devicePlatform: 'web',
			deviceId: localStorage.getItem('btbdevice-id'),
		};

		this.props.sendOtp(data);

		this.setState({ countdown: Date.now() + 120000 });
	};

	onCheckedTerms = () => {
		this.setState({ checkedTerms: !this.state.checkedTerms });
	};

	onCurrentTime = () => {
		return Date.now();
	};
	render() {
		const renderer = ({ minutes, seconds }) => (
			<span>
				{minutes}:{seconds}
			</span>
		);
		const { open } = this.props;
		return (
			<div>
				<Modal
					classNames={{
						modal: 'p-0',
					}}
					open={open}
					onClose={this.onCloseModal}
					center
				>
					<div className="row m-0">
						<div className="col-md-12 border">
							<h5 className="box-title border-bottom p-3 text-primary text-uppercase">VERFICATION</h5>

							<div className="box-body">
								<h5 className="text-center p-4">
									Please enter verification code sent to
									<br />
									{localStorage.getItem('btbmobile')}
									<br />
									By SMS
								</h5>
								<div class="">
									<div class="col-12 mb-5 d-flex justify-content-center verification">
										<input
											type="text"
											maxLength="1"
											size="1"
											min="0"
											max="9"
											pattern="[0-9]{1}"
											name="num1"
											value={this.state.num1}
											onChange={this.onInputChange}
										/>
										<input
											type="text"
											maxLength="1"
											size="1"
											min="0"
											max="9"
											pattern="[0-9]{1}"
											name="num2"
											value={this.state.num2}
											onChange={this.onInputChange}
										/>
										<input
											type="text"
											maxLength="1"
											size="1"
											min="0"
											max="9"
											pattern="[0-9]{1}"
											name="num3"
											value={this.state.num3}
											onChange={this.onInputChange}
										/>
										<input
											type="text"
											maxLength="1"
											size="1"
											min="0"
											max="9"
											pattern="[0-9]{1}"
											name="num4"
											value={this.state.num4}
											onChange={this.onInputChange}
										/>
										<input
											type="text"
											maxLength="1"
											size="1"
											min="0"
											max="9"
											pattern="[0-9]{1}"
											name="num5"
											value={this.state.num5}
											onChange={this.onInputChange}
										/>
										<input
											type="text"
											maxLength="1"
											size="1"
											min="0"
											max="9"
											pattern="[0-9]{1}"
											name="num6"
											value={this.state.num6}
											onChange={this.onInputChange}
										/>
									</div>

									<div className="col-md-12 text-center">
										<fieldset class="form-group">
											<div class="form-check">
												<label class="form-check-label">
													<input
														class="form-check-input"
														type="checkbox"
														value=""
														checked={this.state.checkedTerms ? true : false}
														onClick={this.onCheckedTerms}
													/>
													By continuing you agree to our
												</label>{' '}
												<a href="https://www.fortuneonline.in/#/terms" target="_blank">
													Terms and Condition
												</a>
											</div>
										</fieldset>
									</div>
									<div className="d-flex align-items-center justify-content-center mb-4 mt-2">
										<button onClick={this.onVerifyOtp} class="btn btn-primary text-uppercase">
											Continue
										</button>
									</div>

									{}
									<div class="col-12 mb-3 d-flex justify-content-between align-items-center">
										<div class="timer">
											<Countdown date={this.state.countdown} renderer={renderer} />{' '}
										</div>

										{this.state.showButton ? (
											<div class="reset btn btn-dark text-uppercase" onClick={this.onSendOtp}>
												Resend SMS
											</div>
										) : (
											''
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</Modal>
			</div>
		);
	}
}

OtpModal.propsTypes = {
	verifyUser: PropTypes.func.isRequired,
	// home: PropTypes.object.isRequired,
};

var mapStateToProps = (state) => ({
	user: state.user,
});

export default connect(mapStateToProps, { sendOtp, verifyOtp })(OtpModal);
