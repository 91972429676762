import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getDocTypeList, getBusinessDetails } from '../../../actions/homeAction';
import DocumentUploadModal from '../modals/DocumentUploadModal';
import { Link } from 'react-router-dom';

class BusinessDocuments extends Component {
	state = {
		isDocumentUploadModalOpen: false,
	};
	componentDidMount() {
		const data = {
			userId: localStorage.getItem('btbuserId'),
		};
		this.props.getBusinessDetails(data);
		this.props.getDocTypeList();
	}
	componentWillReceiveProps(nextProps) {
		this.setState({ docType: nextProps.home.docType });

		if (nextProps.home.businessDetails && nextProps.home.businessDetails.length > 0) {
			this.setState({
				businessDetails: nextProps.home.businessDetails[0],
				documetProof: nextProps.home.businessDetails[0].documetProof,
			});
		}

		if (nextProps.home.docType && nextProps.home.businessDetails) {
			var docType = nextProps.home.docType;
			var documetProof =
				nextProps.home.businessDetails &&
				nextProps.home.businessDetails.length > 0 &&
				nextProps.home.businessDetails[0].documetProof;

			for (let i = 0; i < docType.length; i++) {
				for (let j = 0; j < documetProof.length; j++) {
					if (documetProof[j].docType === docType[i].name) {
						docType[i].exist = true;
						docType[i].status = documetProof[j].status;
					}
				}
			}
			this.setState({ docType });
		}
	}
	onOpenDocumentUploadModal = (document) => {
		console.log(document);
		var documentProof = this.state.documetProof;
		var uploadedDocuments = {};
		if (documentProof) {
			for (let i = 0; i < documentProof.length; i++) {
				if (documentProof[i].docType === document.name) {
					uploadedDocuments = documentProof[i];
				}
			}
		}

		this.setState({ documentDetails: document, uploadedDocuments: uploadedDocuments });
		this.setState({ isDocumentUploadModalOpen: true });
	};
	onCloseDocumentUploadModal = () => {
		this.setState({ isDocumentUploadModalOpen: false });
		const data = {
			userId: localStorage.getItem('btbuserId'),
		};
		this.props.getBusinessDetails(data);
		this.props.getDocTypeList();
	};
	render() {
		return (
			<div>
				<h5>Upload Documents (Any three)</h5>
				<div className="list-group">
					{this.state.docType &&
						this.state.docType.length > 0 &&
						this.state.docType.map((doc) => (
							<div className="list-group-item">
								<div
									className="row cursor-pointer"
									onClick={this.onOpenDocumentUploadModal.bind(this, doc)}
								>
									<div className="col">
										<p className="m-0 d-flex align-items-center">
											{doc.name}{' '}
											{doc.exist ? (
												doc.status === 'pending' ? (
													<div className="badge badge-info ml-3">In-review</div>
												) : doc.status === 'rejected' ? (
													<div className="badge badge-danger ml-3">Rejected</div>
												) : doc.status === 'accepted' ? (
													<div className="badge badge-success text-white ml-3">Approved</div>
												) : (
													''
												)
											) : (
												<div className="badge badge-danger ml-3">Pending</div>
											)}
										</p>
									</div>

									{doc.exist ? (
										<div className="col text-right cursor-pointer text-success">
											<i className="fa fa-check"></i> Uploaded
										</div>
									) : (
										<div className="col text-right cursor-pointer">
											<i className="fa fa-upload "></i> Upload
										</div>
									)}
								</div>
							</div>
						))}
				</div>
				<hr></hr>
				<Link to="/" className="btn btn-primary float-right">
					Submit
				</Link>
				<DocumentUploadModal
					documentDetails={this.state.documentDetails}
					uploadedDocuments={this.state.uploadedDocuments}
					open={this.state.isDocumentUploadModalOpen}
					close={this.onCloseDocumentUploadModal}
				></DocumentUploadModal>
			</div>
		);
	}
}

BusinessDocuments.propsTypes = {
	getBusinessDetails: PropTypes.func.isRequired,
	home: PropTypes.object.isRequired,
};

var mapStateToProps = (state) => ({
	home: state.home,
});

export default connect(mapStateToProps, { getDocTypeList, getBusinessDetails })(BusinessDocuments);
