import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getBannerList, getCategoryList } from '../../../actions/homeAction';

class MainBanner extends Component {
	state = {
		bannersList: [],
	};
	componentDidMount() {
		this.props.getBannerList();

		setTimeout(() => {
			this.setState({ isLoading: false });
		}, 1000);
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			categoryList: nextProps.home.categoryList,
			// bannerList: nextProps.home.bannersList,
		});

		var bannerList = [
			{
				bannerUrlWeb: 'https://s3-bucket-asia-2020.s3.amazonaws.com/banners/seq01.png',
			},
			{
				bannerUrlWeb: 'https://s3-bucket-asia-2020.s3.amazonaws.com/banners/seq02.png',
			},
			{
				bannerUrlWeb: 'https://s3-bucket-asia-2020.s3.amazonaws.com/banners/seq03.png',
			},
			{
				bannerUrlWeb: 'https://s3-bucket-asia-2020.s3.amazonaws.com/banners/seq04.png',
			},
			{
				bannerUrlWeb: 'https://s3-bucket-asia-2020.s3.amazonaws.com/banners/seq05.png',
			},
			{
				bannerUrlWeb: 'https://s3-bucket-asia-2020.s3.ap-south-1.amazonaws.com/banners/seq06.png',
			},
		];

		this.setState({
			categoryList: nextProps.home.categoryList,
			bannerList,
		});
	}

	next = () => {
		this.slider.slickNext();
	};
	previous = () => {
		this.slider.slickPrev();
	};

	render() {
		const setting = {
			infinite: false,
			slidesToShow: 1,
			slidesPerRow: 1,
			speed: 500,
			dots: false,
		};
		return (
			<div>
				<Slider {...setting}>
					{this.state.bannerList !== '' &&
					this.state.bannerList !== null &&
					this.state.bannerList !== undefined &&
					this.state.bannerList !== [] ? (
						this.state.bannerList.map((banner, i) => {
							return (
								<div style={{ marginTop: '70px' }} key={i}>
									<Link to="/product-list">
										{/* <div
												className="w-100 home-banner"
												style={{
													height: '450px',
													backgroundImage: `url(${banner.Medium.url})`,
												}}
												// src={banner.bannerUrlWeb}
												alt=""
											/> */}

										{/* <img className="w-100" src={banner.Medium.url} alt="" /> */}
										<img className="w-100" src={banner.bannerUrlWeb} alt="" />
									</Link>
								</div>
							);
						})
					) : (
						<div style={{ marginTop: '70px' }}>
							<Link to="/product-list">
								<img
									className="w-100"
									style={{ height: '450px' }}
									src="./images/Banners/banner1.jpg"
									alt=""
								/>
							</Link>
						</div>
					)}
				</Slider>
			</div>
		);
	}
}

MainBanner.propsTypes = {
	getBannersList: PropTypes.func.isRequired,
	loginUser: PropTypes.func.isRequired,
	home: PropTypes.object.isRequired,
};

var mapStateToProps = (state) => ({
	home: state.home,
	banners: state.home.bannersList,
});

export default connect(mapStateToProps, { getBannerList })(MainBanner);
