import React, { Component } from 'react';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getCategoryList } from '../../../actions/homeAction';

class Category extends Component {
	state = {
		categoryList: [],
	};
	componentDidMount() {
		if (localStorage.getItem("btbloggedin") == true) {
			this.props.myCategoryList();
		} else {
			this.props.getCategoryList();
		}
	}
	componentWillReceiveProps(nextProps) {
		this.setState({
			categoryList: nextProps.home.categoryList,
		});
	}

	next = () => {
		this.slider.slickNext();
	};
	previous = () => {
		this.slider.slickPrev();
	};
	render() {
		const responsive = {
			superLargeDesktop: {
				// the naming can be any, depends on you.
				breakpoint: { max: 4000, min: 3000 },
				items: 7,
			},
			desktop: {
				breakpoint: { max: 3000, min: 1024 },
				items: 6,
			},
			tablet: {
				breakpoint: { max: 1024, min: 464 },
				items: 2,
			},
			mobile: {
				breakpoint: { max: 464, min: 0 },
				items: 1,
			},
		};
		return (
			<div className="py-5 category-list">
				<div className="container">
					<div className="d-flex justify-content-center px-3 ">
						<h2 className="pb-5 text-uppercase">Top Deals Category</h2>
					</div>
					<div className="row slider-row">
						<div className="col-12 mx-auto">
							<Carousel responsive={responsive}>
								{this.state.categoryList !== '' &&
								this.state.categoryList !== null &&
								this.state.categoryList !== undefined &&
								this.state.categoryList !== []
									? this.state.categoryList.map((category, i) => (
											<div className="px-2 category-item" key={i}>
												<Link
													to={`/category/${category.id}`}
													className="d-flex align-items-center justify-content-center flex-column"
												>
													<div className="d-flex align-items-center justify-content-center category-div">
														<img
															src={
																category.Medium
																	? category.Medium.url
																	: 'https://s3-bucket-asia-2020.s3.amazonaws.com/categoryimages/1%20Oil.png'
															}
															alt={category.name}
														/>
													</div>

													<div className="text-center mt-3">
														<h4 className="text-primary text-uppercase category-title">
															{category.name}
														</h4>
														<p className="text-dark"> {category.productCount} Products</p>
													</div>
												</Link>
											</div>
									  ))
									: ''}
							</Carousel>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

Category.propsTypes = {
	loginUser: PropTypes.func.isRequired,
	home: PropTypes.object.isRequired,
};

var mapStateToProps = (state) => ({
	home: state.home,
});

export default connect(mapStateToProps, { getCategoryList })(Category);
