import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getBannerList, getCategoryList, getBusinessDetails } from '../../../actions/homeAction';
import Loading from '../common/Loading';
import NewArrivals from './NewArrivals';
import LoadingOverlay from 'react-loading-overlay';
import BTBOrdering from './BTBOrdering';
import MainBanner from './MainBanner';
import Category from './Category';
import KycNotification from '../kyc/KycNotification';

class Home extends React.Component {
	state = {
		categoryList: [],
		bannerList: [],
		top_product: [],
		isLoading: true,
		show: false,
		preRegister: false,
	};

	componentDidMount() {
		setTimeout(() => {
			this.setState({ isLoading: false });
		}, 1000);
	}

	componentWillReceiveProps(nextProps) {
		console.log(nextProps.home.businessDetails);
		if (nextProps.home.businessDetails && nextProps.home.businessDetails.length === 0) {
			this.setState({ show: false });
		} else if (nextProps.home.businessDetails === undefined) {
			this.setState({ show: false });
		} else {
			this.setState({ show: true });

			if (
				nextProps.home.businessDetails &&
				nextProps.home.businessDetails[0] &&
				nextProps.home.businessDetails[0].User.registerType === 'preRegister'
			) {
				this.setState({ preRegister: true });
			}
		}
	}

	onOpenRegisterModal = () => {
		if (localStorage.getItem('auth')) {
			this.setState({ isRegisterModalOpen: true });
		} else {
			// this.addTocart()
		}
	};
	onCloseRegisterModal = () => {
		this.setState({ isRegisterModalOpen: false });
	};

	render() {
		return (
			<div>
				<LoadingOverlay active={this.state.isLoading} text={<Loading></Loading>}></LoadingOverlay>
				{this.state.preRegister === false && <KycNotification></KycNotification>}
				<MainBanner></MainBanner>
				{this.state.preRegister === true || this.state.show ? (
					<>
						<Category></Category>
						<BTBOrdering></BTBOrdering>
					</>
				) : null}
				{/* <NewArrivals></NewArrivals> */}
				<img
					src="https://d212y4fjk9i0rg.cloudfront.net/sites/all/themes/adaptivetheme/ff_subtheme/images/bot-banner.jpg"
					alt=""
					style={{ width: '100%' }}
				/>{' '}
			</div>
		);
	}
}

var mapStateToProps = (state) => ({
	home: state.home,
	bannersList: state.home.bannersList,
	topProducts: state.topProducts,
});

Home.propsTypes = {
	loginUser: PropTypes.func.isRequired,
	getCategoryList: PropTypes.func.isRequired,
	home: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, {
	getBannerList,
	getCategoryList,
	getBusinessDetails,
})(Home);
