import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { addBusinessDetails, addAddress } from '../../../actions/homeAction';
import BusinessDetails from './BusinessDetails';
import BusinessDocuments from './BusinessDocuments';

class Kyc extends Component {
	state = {
		tab: 1,
	};

	onSelectTab = (tab) => {
		this.setState({ tab: tab });
	};
	onNextTab = () => {
		this.setState({ tab: this.state.tab + 1 });
	};
	onPreviousTab = () => {
		this.setState({ tab: this.state.tab - 1 });
	};

	onAddBusinessDetails = () => {
		this.onNextTab();
	};
	render() {
		const { tab } = this.state;
		return (
			<div className="container py-5">
				<div className="row">
					<div className="col-md-6 mx-auto text-center">
						<h4 className="text-primary">Welcome to BTB fortune online</h4>
						<p>You need your business & personal details to sign up.</p>

						<div className="tab-list">
							{tab === 1 ? (
								<div onClick={this.onSelectTab.bind(this, 1)} className="tab-item selected">
									Business Details <br />
									<div className="badge badge-danger text-white">Pending</div>
								</div>
							) : (
								<div onClick={this.onSelectTab.bind(this, 1)} className="tab-item">
									Business Details <br />
									<div className="badge badge-danger text-white">Pending</div>
								</div>
							)}

							{tab === 2 ? (
								<div onClick={this.onSelectTab.bind(this, 2)} className="tab-item selected">
									Business Documents <br />
									<div className="badge badge-danger text-white">Pending</div>
								</div>
							) : (
								<div onClick={this.onSelectTab.bind(this, 2)} className="tab-item">
									Business Documents <br />
									<div className="badge badge-danger text-white">Pending</div>
								</div>
							)}
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-6 mx-auto">
						{tab === 1 ? (
							<BusinessDetails onAddBusinessDetails={this.onAddBusinessDetails}></BusinessDetails>
						) : tab === 2 ? (
							<BusinessDocuments></BusinessDocuments>
						) : (
							''
						)}
						{/* <hr />
						{this.state.tab > 1 ? (
							<div className="btn btn-primary text-uppercase" onClick={this.onPreviousTab}>
								Previous
							</div>
						) : null}

						<div className="btn btn-primary text-uppercase float-right" onClick={this.onNextTab}>
							Continue
						</div> */}
					</div>
				</div>
			</div>
		);
	}
}

Kyc.propsTypes = {
	loginUser: PropTypes.func.isRequired,
	home: PropTypes.object.isRequired,
};

var mapStateToProps = (state) => ({
	home: state.home,
});

export default connect(mapStateToProps, { addBusinessDetails, addAddress })(Kyc);
