import axios from "axios";
import Constant from "../Constant";
import {
  GET_TOP_PRODUCT_LIST,
  GET_PRODUCTS_LIST,
  GET_PRODUCT_DETAILS,
  SET_LOADING,
} from "./types";

var config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("btbauth")}`,
  },
};

var paramConfig = {
  Authorization: `Bearer ${localStorage.getItem("btbauth")}`,
};

// SET LOADING
export const setLoading = (data) => (dispatch) => {
  dispatch({
    type: SET_LOADING,
    payload: data,
  });
};

// TOP PRODUCTS LIST
export const getTopProductsList = () => (dispatch) => {
  var data = {
    pincode: localStorage.getItem("btbpincode"),
    // CategoryId(optional)
    // startRange(optional)
    // recordLimit(optional)"
  };
  axios
    .post(Constant.getAPI() + "/homepage/top-product", data, config)
    .then((res) => {
      var products = res.data;

      console.log(res.data);
      dispatch({
        type: GET_TOP_PRODUCT_LIST,
        payload: products,
      });
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};

// PRODUCTS LIST
export const getProductsList = () => (dispatch) => {
  var data = {
    pinCode: localStorage.getItem("btbpincode"),
    // CategoryId(optional)
    // startRange(optional)
    // recordLimit(optional)"
  };
  dispatch(setLoading(true));
  axios
    .post(Constant.getAPI() + "/pincodesellerproduct/list", data, config)
    .then((res) => {
      if (localStorage.getItem("btbauth")) {
        dispatch({
          type: GET_PRODUCTS_LIST,
          payload: res.data.data,
        });
      } else {
        dispatch({
          type: GET_PRODUCTS_LIST,
          payload: [],
        });
      }
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};

// PRODUCT DETAILS
export const getProductDetails = (id) => (dispatch) => {
  var data = {
    pinCode: localStorage.getItem("btbpincode"),
    productId: id,
  };
  dispatch(setLoading(true));
  axios
    .post(Constant.getAPI() + "/product/describe", data, config)
    .then((res) => {
      if (localStorage.getItem("btbauth")) {
        dispatch({
          type: GET_PRODUCT_DETAILS,
          payload: res.data.data,
        });
      } else {
        dispatch({
          type: GET_PRODUCT_DETAILS,
          payload: [],
        });
      }
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};
