import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
	getBusinessDetails,
	getCategoryList,
	getBusinessType,
	addBusinessDetails,
	getAddress,
} from '../../../actions/homeAction';
import AddressModal from '../modals/AddressModal';
import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
} from 'react-accessible-accordion';

// 4579e2a3-1e98-4d65-b11e-db7a06aecc13

class BusinessDetails extends Component {
	state = {
		categoryArray: [],
		businessTypeArray: [],
	};
	componentDidMount() {
		const data = {
			userId: localStorage.getItem('btbuserId'),
		};
		this.props.getBusinessDetails(data);
		this.props.getAddress();
		this.props.getCategoryList();
		this.props.getBusinessType();
	}
	componentWillReceiveProps(nextProps) {
		this.setState({
			address: nextProps.home.address,
			categoryList: nextProps.home.categoryList,
			businessType: nextProps.home.businessType,
		});

		if (nextProps.home.businessDetails && nextProps.home.businessDetails.length > 0) {
			this.setState({
				businessDetails: nextProps.home.businessDetails[0],
				businessName: nextProps.home.businessDetails[0].name,
			});

			var categoryArray = [];
			var BusinessCategory = nextProps.home.businessDetails[0].BusinessCategory;
			for (let i = 0; i < BusinessCategory.length; i++) {
				categoryArray.push(BusinessCategory[i].id);
			}
			this.setState({ categoryArray });

			var businessTypeArray = [];
			var BusinessType = nextProps.home.businessDetails[0].BusinessType;
			for (let i = 0; i < BusinessType.length; i++) {
				businessTypeArray.push(BusinessType[i].id);
			}
			this.setState({ businessTypeArray });
		}
	}
	selectCategory = (id) => {
		const categoryArray = this.state.categoryArray;
		let exist = false;
		for (let i = 0; i < categoryArray.length; i++) {
			if (id === categoryArray[i]) {
				exist = true;
				categoryArray.splice(i, 1);
			}
		}

		if (exist === false) {
			categoryArray.push(id);
		}

		this.setState({ categoryArray });
	};
	selectBusinessType = (id) => {
		const businessTypeArray = this.state.businessTypeArray;
		let exist = false;
		for (let i = 0; i < businessTypeArray.length; i++) {
			if (id === businessTypeArray[i]) {
				exist = true;
				businessTypeArray.splice(i, 1);
			}
		}

		if (exist === false) {
			businessTypeArray.push(id);
		}

		this.setState({ businessTypeArray });
	};
	onAddBusinessDetails = () => {
		let businessDetails = {
			name: this.state.businessName,
			CategoryIds: this.state.categoryArray,
			BusinessTypeIds: this.state.businessTypeArray,
		};

		this.props.addBusinessDetails(businessDetails);
		this.props.onAddBusinessDetails();
	};
	onSelectLabel = (label) => {
		this.setState({ label: label });
	};
	onInputChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	checkLogin = () => {
		this.clearState();
		if (!localStorage.getItem("btbloggedin")) {
			this.onOpenRegisterModal();
		} else {
			this.setState({
				verified: true,
				addingAddress: true,
				addressDetails: {},
			});
			this.onOpenAddressModal();
		}
	};
	onOpenAddressModal = () => {
		this.setState({ isAddressModalOpen: true });
	};
	onCloseAddressModal = () => {
		this.setState({ isAddressModalOpen: false });
	};
	clearState = () => {
		this.setState({
			mobile: '',
			name: '',
			road: '',
			landmark: '',
			PinCodeId: '',
			lat: '',
			long: '',
			label: '',
			address_line_1: '',
			address_line_2: '',
			alternate_mobile: '',
		});
	};
	render() {
		return (
			<div>
				<form onSubmit={this.onAddBusinessDetails}>
					<div className="form-group pb-3">
						<label htmlFor="">Business Name*</label>
						<input
							name="businessName"
							id=""
							className="form-control"
							placeholder="Enter Business Name"
							value={this.state.businessName}
							onChange={this.onInputChange}
							required
						/>
					</div>

					<div className="form-group pb-3">
						<div className="d-flex justify-content-between align-items-center">
							<label htmlFor="">Address Details*</label>

							{this.state.address && this.state.address.length === 0 && (
								<div className="btn btn-primary " onClick={this.checkLogin}>
									ADD ADDRESS
								</div>
							)}
						</div>

						{this.state.address !== undefined && this.state.address.length > 0 ? (
							<div>
								<div>
									<Accordion
										allowMultipleExpanded={!this.state.addingAddress}
										allowZeroExpanded={!this.state.addingAddress}
										dangerouslySetExpanding={!this.state.addingAddress}
									>
										{this.state.address !== undefined
											? this.state.address.map(
													(address, key) =>
														key === 0 && (
															<AccordionItem className="shadow-sm border-bottom">
																<AccordionItemHeading>
																	<AccordionItemButton>
																		{address.label}
																	</AccordionItemButton>
																</AccordionItemHeading>
																<AccordionItemPanel>
																	<div>
																		<div>
																			<div className="row">
																				<div className="col-6">
																					{' '}
																					<h3 className="float-left m-0">
																						{address.name}
																					</h3>{' '}
																					<div className="badge badge-primary ml-3">
																						<h5 className="m-0 text-white">
																							{address.label}
																						</h5>
																					</div>
																				</div>
																				{/* <div className="col-6">
																			{this.state.addressId === address.id ? (
																				<span className="btn text-uppercase btn-success text-white float-right">
																					Selected
																				</span>
																			) : (
																				<span
																					className="btn text-uppercase btn-primary float-right"
																					onClick={this.onSelectAddress.bind(
																						this,
																						address
																					)}
																				>
																					Select
																				</span>
																			)}
																		</div> */}
																			</div>
																		</div>

																		<br />
																		{}
																		<p>
																			{address.address_line_1},{' '}
																			{address.address_line_2}, {address.landmark}
																			, {address.PinCode.area} -{' '}
																			{address.PinCode.City.name},{' '}
																			{address.PinCode.City.State.name} -{' '}
																			{address.PinCode.code}
																		</p>
																		<h5>Mobile No: {address.mobile}</h5>
																		<br />
																		{/* <div>
																	<div class="change-div">
																		<button
																			class="btn btn-primary"
																			onClick={this.setAddress.bind(
																				this,
																				address.id
																			)}
																		>
																			EDIT
																		</button>{' '}
																		<a
																			onClick={this.onDeleteAddress.bind(
																				this,
																				address.id
																			)}
																			class="btn btn-dark"
																		>
																			DELETE
																		</a>
																	</div>
																</div> */}
																	</div>
																</AccordionItemPanel>
															</AccordionItem>
														)
											  )
											: ''}
									</Accordion>
								</div>
							</div>
						) : (
							<div className="p-5 text-center">No address available</div>
						)}
					</div>

					<div className="form-group pb-3">
						<label htmlFor="">Select Business Category*</label>

						<div className="form-control d-flex flex-wrap h-100">
							{this.state.categoryList !== undefined
								? this.state.categoryList.map((category) => (
										<div
											className={`btn btn-outline-primary d-flex align-items-center m-1 ${this.state.categoryArray.map(
												(categoryId) => categoryId == category.id && ' btn-primary text-white '
											)}`}
											onClick={this.selectCategory.bind(this, category.id)}
										>
											{category.name}
										</div>
								  ))
								: ''}
						</div>
					</div>

					<div className="form-group pb-3">
						<label htmlFor="">Business Type*</label>
						<div className="form-control d-flex flex-wrap h-100">
							{this.state.businessType !== undefined
								? this.state.businessType.map((category) => (
										<div
											className={`btn btn-outline-primary d-flex align-items-center m-1 ${this.state.businessTypeArray.map(
												(categoryId) => categoryId == category.id && ' btn-primary text-white '
											)}`}
											onClick={this.selectBusinessType.bind(this, category.id)}
										>
											{category.name}
										</div>
								  ))
								: ''}
						</div>
					</div>

					<hr />
					<button type="submit" className="btn btn-primary text-uppercase float-right">
						Continue
					</button>
				</form>

				<AddressModal
					open={this.state.isAddressModalOpen}
					close={this.onCloseAddressModal}
					addingAddress={this.state.addingAddress}
					addressDetails={this.state.addressDetails}
				></AddressModal>
			</div>
		);
	}
}

BusinessDetails.propsTypes = {
	loginUser: PropTypes.func.isRequired,
	home: PropTypes.object.isRequired,
	getAddress: PropTypes.func.isRequired,
};

var mapStateToProps = (state) => ({
	home: state.home,
});

export default connect(mapStateToProps, {
	getBusinessDetails,
	getCategoryList,
	getBusinessType,
	addBusinessDetails,
	getAddress,
})(BusinessDetails);
