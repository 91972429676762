import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { orderList, reorder, cancelOrder } from '../../../actions/homeAction';
import moment from 'moment';
import LoadingOverlay from 'react-loading-overlay';
import Loading from '../common/Loading';
import CancelOrderModal from '../modals/CancelOrderModal';
import FeedbackModal from '../modals/FeedbackModal';

class Order extends Component {
	state = {};

	componentDidMount() {
		this.props.orderList();
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			orderList: nextProps.home.orderList,
			loading: nextProps.home.loading,
		});
	}

	onCancelOrder = (id) => {
		var data = {
			orderId: id,
		};
		this.props.cancelOrder(data);
	};

	onReorder = (id) => {
		var data = {
			orderId: id,
		};
		this.props.reorder(data);
	};

	onOpenCancelOrderModal = (id) => {
		this.setState({ isCancelOrderModalOpen: true, cancelOrderId: id });
	};

	onCloseCancelOrderModal = () => {
		this.setState({ isCancelOrderModalOpen: false });
	};

	onOpenFeedbackModal = (id, feedbackGiven) => {
		this.setState({
			isFeedbackModalOpen: true,
			feedbackOrderId: id,
			feedbackGiven: feedbackGiven,
		});
	};

	onCloseFeedbackModal = () => {
		this.setState({ isFeedbackModalOpen: false });
	};

	render() {
		return (
			<div>
				<LoadingOverlay active={this.state.loading} text={<Loading></Loading>}></LoadingOverlay>
				<h5 className="bg-primary text-white p-2">
					<Link to="/">
						<i className="fa fa-home"></i> Home{' '}
					</Link>{' '}
					<i className="fa fa-chevron-right mx-3"></i> Orders
				</h5>
				<div className="py-5">
					<div className="container">
						<div class="row d-flex justify-content-between">
							<div class="col-md-3 dashboard-left mb-5">
								<div class="checkout-sub">
									<div className="list-group shadow-sm">
										<Link to="/account" className="list-group-item product-link">
											My Profile
										</Link>
										<Link
											to="/order"
											className="list-group-item product-link text-white bg-primary"
										>
											My Orders
										</Link>
										<Link to="/address" className="list-group-item product-link">
											Address Book
										</Link>

										<Link to="/favourite" className="list-group-item product-link">
											Favourites
										</Link>

										<Link
											className="list-group-item product-link cursor-pointer"
											onClick={() => {
												localStorage.clear();
												window.location.href = '#/';
												window.location.reload();
											}}
										>
											Logout
										</Link>
									</div>
								</div>
							</div>
							<div class="col-md-8">
								{this.state.orderList !== undefined && this.state.orderList.length > 0 ? (
									this.state.orderList.map((order) => (
										<div className="row m-0 card border p-3 shadow-sm d-flex justify-content-between mb-5">
											<div className="row m-0 d-flex justify-content-between align-items-center">
												<h4 className="col-md-6">
													<b className="mb-1"> Invoice No. : </b>
													{order.invoice_number}
												</h4>

												<h5 className="col-md-6 text-right">
													{moment(order.createdAt).format('DD-MM-YYYY h:mm A')}
												</h5>
											</div>

											<div className="row m-0">
												<div className="col-md-7 d-flex justify-content-between align-items-center">
													{order.orderStatus === 'Cancelled' ||
													order.orderStatus === 'Denied' ||
													order.orderStatus === 'Returned' ? (
														<div className="badge badge-danger text-white">
															<h6 className="m-0 text-white">Cancelled</h6>
														</div>
													) : (
														<div className="badge badge-success text-white">
															<h6 className="m-0 text-white">{order.orderStatus}</h6>
														</div>
													)}
												</div>

												<div className="col-md-5 d-flex justify-content-end align-items-center">
													{order.orderStatus !== 'Cancelled' &&
													order.orderStatus !== 'Delivered' ? (
														<div
															className="btn text-uppercase btn-danger  mr-2"
															onClick={this.onOpenCancelOrderModal.bind(this, order.id)}
														>
															Cancel order
														</div>
													) : (
														''
													)}

													{order.orderStatus === 'Delivered' ? (
														<div
															className="btn text-uppercase btn-primary text-white  mr-2"
															onClick={this.onOpenFeedbackModal.bind(
																this,
																order.id,
																order.feedbackGiven
															)}
														>
															<i className="fa fa-thumbs-up"></i> Feedback
														</div>
													) : (
														''
													)}

													<div
														className="btn text-uppercase btn-success text-white"
														onClick={this.onReorder.bind(this, order.id)}
													>
														Re-order
													</div>
												</div>
											</div>

											{order.paymentDetails &&
											order.paymentDetails.paymentType == 'online' &&
											order.orderStatus == 'Cancelled' ? (
												<div className="mt-3 alert alert-warning">
													<p>Refund Status : {order.refundStatus}</p>
													<p className="m-0">
														The refund payment will be released back to the original mode
														you used to make payment. This process will take 7-14 working
														days depending on the payment mode being used
													</p>
												</div>
											) : (
												''
											)}

											<hr />
											<div className="row px-3 d-flex justify-content-between">
												<div className="col-md-4">
													<h5 className="text-primary">Seller Details</h5>
													<div>
														<b>Name</b> :{' '}
														{order && order.Seller && order.Seller.name
															? order.Seller.name
															: ''}{' '}
														<br />
														<b>Location</b> :{' '}
														{order && order.Seller && order.Seller.name
															? order.Seller.address
															: ''}
													</div>
													<br />
													<br />
													<h5 className="text-primary">Address Details</h5>
													<div>
														<p>
															{' '}
															<b>Name</b> : {order.Address.name} <br />
															<b>Address</b> :{' '}
															<span>
																{order.Address.address_line_1},{' '}
																{order.Address.address_line_2}, {order.Address.landmark}
																, {order.Address.city}, {order.Address.state} -{' '}
																{order.Address.pinCode}
															</span>
														</p>
													</div>
													<br />
													<h5 className="text-primary">Payment Details</h5>
													<div>
														<div className="d-flex justify-content-between">
															<b className="mb-1"> Payment Mode : </b>
															<p className="m-0">
																{order.paymentDetails.paymentType === 'cod'
																	? 'Cash'
																	: order.paymentDetails.paymentType}

																{order.paymentDetails.paymentType === 'online'
																	? order.transaction
																		? ` (${order.transaction.GATEWAYNAME})`
																		: ''
																	: ''}
															</p>
														</div>
														<div className="d-flex justify-content-between">
															<b className="mb-1"> Sub Amount :</b>
															<p className="m-0">
																&#8377;{' '}
																{(
																	order.paymentDetails.grandTotal +
																	order.paymentDetails.discountAmount
																).toFixed(2)}
															</p>
														</div>
														<div className="d-flex justify-content-between">
															<b className="mb-1"> Savings on MRP :</b>
															<p className="m-0 text-success">
																&#8377; {order.paymentDetails.discountAmount.toFixed(2)}
															</p>
														</div>
														<div className="d-flex justify-content-between">
															<b className="mb-1"> Delivery Charges :</b>
															<p className="m-0 ">
																{order.paymentDetails.deliveryCharge === 0 ? (
																	'FREE'
																) : (
																	<span>
																		{' '}
																		&#8377;{' '}
																		{order.paymentDetails.deliveryCharge.toFixed(2)}
																	</span>
																)}
															</p>
														</div>
														{/* <div className="d-flex justify-content-between">
                                <b className="mb-1"> GST Charges :</b>
                                <p className="m-0 ">
                                  &#8377; {order.paymentDetails.gst}
                                </p>
                              </div> */}
														<h5 className="d-flex justify-content-between border-top">
															<b className="mt-1"> Grand Amount :</b>
															<p className="m-0 mt-1 text-primary font-weight-bold">
																&#8377; {order.paymentDetails.grandTotal.toFixed(2)}
															</p>
														</h5>
													</div>
												</div>

												<div className="col-md-7">
													<div className="row m-0">
														{order.OrderProducts.map((product) => (
															<div className="col-12 mb-3 p-0">
																<div className="card p-3 border shadow-sm">
																	<div className="row m-0">
																		<div className="col-md-3 d-flex justify-content-center align-items-center">
																			<img
																				src={`http://s3-bucket-asia-2020.s3.amazonaws.com/productimages/${product.Product.sku}/${product.Product.sku}.main.png`}
																				alt={product.Product.name}
																				style={{ width: '100%' }}
																				onError={(ev) =>
																					(ev.target.src =
																						'./images/logo1.png')
																				}
																			/>
																		</div>
																		<div className="col-md-9">
																			<div className="product-link">
																				<Link
																					to={`/product-details/${product.Product.id}`}
																				>
																					<h5 className="product-title">
																						{product.Product.name}
																					</h5>
																				</Link>
																			</div>

																			<p className="m-0 text-left display-flex">
																				<h5 className="text-dark float-left ">
																					&#8377;{' '}
																					{product !== undefined
																						? parseInt(
																								product.UOM.PTR *
																									product.qty
																						  ).toFixed(2)
																						: ''}
																				</h5>
																				<h5>
																					<strike class="text-secondary ml-3">
																						&#8377;{' '}
																						{product !== undefined
																							? parseInt(
																									product.UOM.MRP *
																										product.qty
																							  ).toFixed(2)
																							: ''}
																					</strike>
																				</h5>

																				<span className="text-primary text-success">
																					Save &#8377;{' '}
																					{parseInt(
																						product.UOM.flat_discount *
																							product.qty
																					).toFixed(2)}
																				</span>
																			</p>
																		</div>{' '}
																		<div className="fav-icon">
																			<div className="badge badge-success text-white">
																				x{product.qty}
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														))}
													</div>
												</div>
											</div>
										</div>
									))
								) : (
									<div className="p-5 text-center">No order available</div>
								)}
							</div>
						</div>
					</div>
				</div>
				<CancelOrderModal
					open={this.state.isCancelOrderModalOpen}
					close={this.onCloseCancelOrderModal}
					cancelOrderId={this.state.cancelOrderId}
				></CancelOrderModal>
				<FeedbackModal
					open={this.state.isFeedbackModalOpen}
					close={this.onCloseFeedbackModal}
					feedbackOrderId={this.state.feedbackOrderId}
					feedbackGiven={this.state.feedbackGiven}
				></FeedbackModal>
			</div>
		);
	}
}

var mapStateToProps = (state) => ({
	home: state.home,
	bannersList: state.home.bannersList,
	topProducts: state.topProducts,
});

Order.propsTypes = {
	loginUser: PropTypes.func.isRequired,
	getCategoryList: PropTypes.func.isRequired,
	home: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { orderList, reorder, cancelOrder })(Order);
