import React, { Component } from 'react';
import 'react-multi-carousel/lib/styles.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
	addToCart,
	favourite,
	unFavourite,
	getProductsList,
	getCart,
	updateCart,
	deleteCart,
	getSettings,
	getCategoryList,
	getBusinessDetails,
} from '../../../actions/homeAction';

import Swal from 'sweetalert2';
import LoadingOverlay from 'react-loading-overlay';
import Loading from '../common/Loading';

class BTBProducts extends Component {
	state = {
		quantity: 1,
		type: 'CAR',
		show: true,
	};

	componentDidMount() {
		var data;
		if (this.props.category.id) {
			data = {
				CategoryId: this.props.category.id,
				pinCode: localStorage.getItem('btbpincode'),
			};
		} else {
			data = {
				pinCode: localStorage.getItem('btbpincode'),
			};
		}

		this.props.getProductsList(data);
	}
	componentWillReceiveProps(nextProps) {
		if (nextProps.category.id !== this.props.category.id) {
			var data;
			if (nextProps.category.id) {
				data = {
					CategoryId: nextProps.category.id,
					pinCode: localStorage.getItem('btbpincode'),
				};
			} else {
				data = {
					pinCode: localStorage.getItem('btbpincode'),
				};
			}

			this.props.getProductsList(data);
		}

		const products = nextProps.home.productsList;

		if (products) {
			for (let i = 0; i < products.length; i++) {
				products[i].qty = 1;
				products[i].type = 'CAR';
			}
			if (products.length > 0) {
				this.setState({ loading: false });
			}
		}

		const categoryList = nextProps.home.categoryList;
		if (categoryList) {
			for (let i = 0; i < categoryList.length; i++) {
				if (this.props.category.id === categoryList[i].id) {
					this.setState({ categoryName: categoryList[i].name });
				}
			}
		}

		this.setState({
			products,
			loading: nextProps.home.loading,
			categoryList,
		});

		if (
			nextProps.home.businessDetails &&
			nextProps.home.businessDetails.length > 0 &&
			nextProps.home.businessDetails[0].kycVerified
		) {
			this.setState({ show: true });
		} else {
			this.setState({ show: false });
		}
	}

	onOpenRegisterModal = () => {
		if (localStorage.getItem('auth')) {
			this.setState({ isRegisterModalOpen: true });
		}
	};
	onCloseRegisterModal = () => {
		this.setState({ isRegisterModalOpen: false });
	};
	addQuantity = (id) => {
		const products = this.state.products;
		console.log(products);
		for (let i = 0; i < products.length; i++) {
			if (id === products[i].id) {
				if (products[i].qty < 10) {
					if (products[i].inventory_stock > parseInt(products[i].qty)) {
						if (products[i].qty) {
							products[i].qty = products[i].qty + 1;
						} else {
							products[i].qty = 1;
						}
					} else {
						Swal.fire({
							title: `Only ${products[i].inventory_stock} quantity available in stock!`,
							icon: '',
							imageUrl: './images/spinner.png',
							imageHeight: 100,
							text: '',
							type: 'error',
							confirmButtonColor: '#e84200',
							cancelButtonColor: '#d33',
							confirmButtonText: 'Ok',
						});
					}
				} else {
					Swal.fire({
						title: `You can only order this product with max qty 10`,
						icon: '',
						imageUrl: './images/spinner.png',
						imageHeight: 100,
						text: '',
						type: 'error',
						confirmButtonColor: '#e84200',
						cancelButtonColor: '#d33',
						confirmButtonText: 'Ok',
					});
				}
			}
		}

		this.setState({ products });
	};
	lessQuantity = (id) => {
		const products = this.state.products;

		for (let i = 0; i < products.length; i++) {
			if (id === products[i].id) {
				if (products[i].qty > 1) {
					if (products[i].qty) {
						products[i].qty = products[i].qty - 1;
					} else {
						products[i].qty = 1;
					}
				}
			}
		}

		this.setState({ products });
	};
	addToCart = (product) => {
		if (this.state.show === false) {
			Swal.fire({
				title: `Complete KYC Process`,
				icon: '',
				imageUrl: './images/spinner.png',
				imageHeight: 100,
				text: 'Complete kyc approval process to get access to product pricess, ordering and schemes',
				type: 'error',
				confirmButtonColor: '#e84200',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
			});
			return false;
		}
		var SellerProduct_UOM = product.SellerProduct_UOM;
		for (let i = 0; i < SellerProduct_UOM.length; i++) {
			if (this.state.type === SellerProduct_UOM[i].name) {
				var UOMId = SellerProduct_UOM[i].id;
			}
		}

		var data = {
			UOMId: UOMId,
			productId: product.Product.id,
			sellerId: product.Seller.id,
			qty: product.qty,
		};
		console.log(data);

		this.props.addToCart(data);
	};

	onFavourite = (id) => {
		var data = {
			productId: id,
		};

		var productData = {};

		if (this.props.category.id) {
			productData = {
				CategoryId: this.props.category.id,
				pinCode: localStorage.getItem('btbpincode'),
			};
		} else {
			productData = {
				pinCode: localStorage.getItem('btbpincode'),
			};
		}

		this.props.favourite(data, productData);
	};

	onUnFavourite = (id) => {
		var data = {
			productId: id,
		};

		var productData = {};

		if (this.props.category.id) {
			productData = {
				CategoryId: this.props.category.id,
				pinCode: localStorage.getItem('btbpincode'),
			};
		} else {
			productData = {
				pinCode: localStorage.getItem('btbpincode'),
			};
		}

		this.props.unFavourite(data, productData);
	};
	onSelectType = (product, e) => {
		var products = this.state.products;
		for (let i = 0; i < products.length; i++) {
			if (product.ProductId === products[i].ProductId) {
				products[i].type = e.target.value;
			}
		}
		this.setState({ type: e.target.value, selectedProductId: product.ProductId, products });
		this.props.onSelectType(e.target.value);
	};
	next = () => {
		this.slider.slickNext();
	};
	previous = () => {
		this.slider.slickPrev();
	};
	render() {
		const responsive = {
			superLargeDesktop: {
				// the naming can be any, depends on you.
				breakpoint: { max: 4000, min: 3000 },
				items: 5,
			},
			desktop: {
				breakpoint: { max: 3000, min: 1024 },
				items: 5,
			},
			tablet: {
				breakpoint: { max: 1024, min: 464 },
				items: 2,
			},
			mobile: {
				breakpoint: { max: 464, min: 0 },
				items: 1,
			},
		};
		return (
			<>
				<h3 className="text-primary m-0">
					{this.props.category.name ? this.props.category.name : 'All Products'}
				</h3>
				<p className="text-lead"> {this.state.products && this.state.products.length} Items</p>
				<div className="btb-product-wrapper">
					<LoadingOverlay active={this.state.loading} text={<Loading></Loading>}></LoadingOverlay>

					{this.state.products !== undefined && this.state.products
						? this.state.products.map((product) => (
								<div class="product-link mb-3">
									<div className="product-card card border h-100 shadow-sm d-flex flex-column">
										<div className="row m-0 ">
											<div className="col-md-4 d-flex justify-content-center align-items-center product-card">
												<img
													src={`http://s3-bucket-asia-2020.s3.amazonaws.com/productimages/${product.Product.sku}/${product.Product.sku}.main.png`}
													alt={product.Product.name}
													style={{ width: '50%' }}
													onError={(ev) => (ev.target.src = 'images/logo1.png')}
												/>

												<div className="fav-icon">
													{product.Product.isFavorite ? (
														<i
															className="fa fa-heart text-danger"
															onClick={this.onUnFavourite.bind(this, product.Product.id)}
														></i>
													) : (
														<i
															className="fa fa-heart-o text-danger"
															onClick={this.onFavourite.bind(this, product.Product.id)}
														></i>
													)}
												</div>
												<div className="discount-icon font-weight-bold">
													<i className="fa fa-certificate text-primary"></i>
													<p className="text-white">
														{product.SellerProduct_UOM.map((type) =>
															type.name === this.state.type
																? Math.round(type.percentage_discount)
																: null
														)}
														%
													</p>
												</div>
											</div>
											<div className="col-md-8">
												<div className="mt-2">
													<Link
														to={`/product-details/${product.Product.id}`}
														className="product-link"
													>
														<h5 className="product-title w-100">{product.Product.name}</h5>
													</Link>
													<div className="div">
														<p>
															Sold By:{' '}
															<span className="text-primary">{product.Seller.name}</span>
														</p>
													</div>
													<div className="row m-0">
														<div className="col-md-12">
															{product.SellerProduct_UOM.map(
																(type) =>
																	type.name === product.type && (
																		<p
																			className={`m-0 text-left display-flex product-price ${
																				this.state.show == false ? 'blur' : ''
																			}`}
																		>
																			<span className="primary float-left font-weight-bold">
																				&#8377; {type.PTR.toFixed(2)}
																			</span>
																			<span className="secondary float-left ml-2">
																				<strike class="ml-1">
																					&#8377; {type.MRP.toFixed(2)}
																				</strike>
																			</span>

																			<span className="success float-right">
																				Save &#8377;
																				{type.flat_discount.toFixed(2)}
																			</span>
																		</p>
																	)
															)}
														</div>
													</div>
												</div>
												<div className="d-flex justify-content-between mt-3">
													<select
														className="form-control"
														style={{ width: '100px' }}
														onChange={this.onSelectType.bind(this, product)}
													>
														{product.type === 'CAR' ? (
															<>
																<option value="CAR" selected>
																	Cartoon
																</option>
																<option value="EA">Single</option>
															</>
														) : (
															<>
																<option value="CAR">Cartoon</option>
																<option value="EA" selected>
																	Single
																</option>
															</>
														)}
													</select>
													<div class="form-group m-0">
														<div class="input-group mb-0">
															<div class="input-group-prepend">
																<span
																	class="input-group-text cursor-pointer"
																	onClick={this.lessQuantity.bind(this, product.id)}
																>
																	-
																</span>
															</div>
															<input
																class="form-control quantity-text"
																value={product.qty}
															/>
															<div class="input-group-append">
																<span
																	class="input-group-text cursor-pointer"
																	onClick={this.addQuantity.bind(this, product.id)}
																>
																	+
																</span>
															</div>
														</div>
													</div>
													<button
														onClick={this.addToCart.bind(this, product)}
														className="btn btn-outline-primary text-uppercase ml-2"
													>
														Add
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
						  ))
						: ''}
				</div>
			</>
		);
	}
}

BTBProducts.propsTypes = {
	loginUser: PropTypes.func.isRequired,
	guestUser: PropTypes.func.isRequired,
	home: PropTypes.object.isRequired,
};

var mapStateToProps = (state) => ({
	home: state.home,
	product: state.product,
	guest: state.guest,
});

export default connect(mapStateToProps, {
	getProductsList,
	addToCart,
	favourite,
	unFavourite,
	getCart,
	updateCart,
	deleteCart,
	getSettings,
	getCategoryList,
	getBusinessDetails,
})(BTBProducts);
